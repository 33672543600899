async function svg2image({ data, width = 675, height = 675, format = 'png' }: any) {
    return new Promise((resolve) => {
        var canvas = document.createElement('canvas');
        var context: any = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        var image = new Image();
        image.onload = function () {
            context.clearRect(0, 0, width, height);
            context.drawImage(image, 0, 0, width, height);

            var pngData = canvas.toDataURL('image/' + format);
            return resolve(pngData);
        };

        image.src = data;
    });
}

export default async function formatImage(base64: any) {
    return new Promise((resolve) => {
        var image = new Image();

        image.onload = async function () {
            let data: any = { width: Number(image.width), height: Number(image.height) };
            const type = base64.substring('data:image/'.length, base64.indexOf(';base64')) || '';

            if (type.includes('svg')) {
                if (data.width < 435) {
                    const resize = parseInt(`${670 / data.width}`) - 1;
                    data.width *= resize;
                    data.height *= resize;
                }

                base64 = await svg2image({
                    data: base64,
                    width: data.width,
                    height: data.height
                });
            }

            return resolve(base64);
        };

        image.src = base64;
    });
}
