import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { signInProps, getOrgsProps, validateUserProps } from './interfaces/auth.interface';

const authServices = createApi({
    reducerPath: 'auth-service',
    baseQuery: baseQuery,
    tagTypes: ['Auth', 'AuthUser'],
    endpoints: (builder) => ({
        validateUser: builder.mutation({
            query: ({ email, password }: validateUserProps) => ({
                method: 'POST',
                url: `/users/validate`,
                body: { email, password }
            }),
            invalidatesTags: [{ type: 'AuthUser' }]
        }),
        signin: builder.mutation({
            query: ({ token, org_id }: signInProps) => ({
                method: 'POST',
                url: `/users/${org_id}/signin`,
                body: { token }
            }),
            invalidatesTags: [{ type: 'Auth' }]
        })
    })
});

export default authServices;
