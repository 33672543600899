import React from 'react';
import Button from '../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { modalTypes } from '../..';
import { notifyActions } from '../../../redux/reducers/notify';
import rewardServices from '../../../services/reward.services';

export default function DeleteWallet() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.DELETE_WALLET];

    /* mutation */
    const [
        deleteWallet,
        { error: deleteWalletError, isError: isDeleteWalletError, isLoading: isDeleting }
    ]: any = rewardServices.useDeleteWalletMutation();

    /* error handling */
    React.useEffect(() => {
        if (isDeleteWalletError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: deleteWalletError.data?.message
                })
            );
        }
    }, [deleteWalletError, isDeleteWalletError]);

    const onSubmit = async () => {
        const result = await deleteWallet({
            delete_id: props.id,
            org_id: authUser.organization_id
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container">
            <div className="modal-content">
                <p>
                    This action is <b>Not Reversible</b> and needs to send a new email invitation to
                    the user to create a new wallet account.
                </p>

                <p>Are you sure you want to permanently delete this wallet account?</p>
            </div>

            <DialogActions className="modal-buttons">
                <Button disabled={isDeleting} onClick={() => dispatch(modalActions.hide())}>
                    Cancel
                </Button>
                <Button
                    onClick={onSubmit}
                    loading={isDeleting}
                    className="bg-delete"
                    variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </div>
    );
}
