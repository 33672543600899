import * as React from 'react';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { v4 as uuid } from 'uuid';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface Props {
    id?: string;
    tabs: any;
    size?: 'small' | 'medium' | 'large';
    tab?: number;
    setTab?: any;
    className?: string;
    [props: string]: any;
}

interface TabPanelProps {
    id?: string;
    className?: string;
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { id, children, value, index, className, ...other } = props;

    return (
        <div
            className={className}
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}>
            {value === index && children}
        </div>
    );
}

function a11yProps(id: string, index: number) {
    return {
        id: `${id}-tab-${index}`,
        'aria-controls': `tabpanel-${index}`
    };
}

export default React.memo(function Tabs({
    id,
    tabs = [],
    content = [],
    size = 'medium',
    tab = 0,
    setTab,
    className,
    ...props
}: Props) {
    const [Id] = React.useState(id || uuid());
    const [value, setValue] = React.useState(0);
    const navigator = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { state: routerState } = useLocation();

    React.useEffect(() => {
        if (tab !== null || tab !== undefined) setValue(Number(tab));
    }, [tab]);

    React.useEffect(() => {
        const searchTab = searchParams.get('tab');

        if (searchTab) {
            if (parseInt(searchTab) > 0 && parseInt(searchTab) < tabs.length) {
                setTab(searchTab);
            }
        }
    }, [tabs, searchParams]);

    const handleChange = (event: React.SyntheticEvent, value: number) => {
        if (setTab) return setTab(value);

        setValue(value);
    };

    const onTabSelect = (value: number) => {
        navigator(
            {
                pathname: window.location.pathname,
                search: `?${createSearchParams({ tab: `${value}` })}`
            },
            { state: routerState }
        );
    };

    return (
        <div className={`tabs-wrap ${className ? className : ''}`} {...props}>
            <div className="tabs">
                <MuiTabs
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    value={value}
                    onChange={handleChange}>
                    {tabs.map(({ label }: any, i: number) => {
                        return (
                            <Tab
                                className={`tab ${size}`}
                                key={`${Id}-${i}`}
                                label={label}
                                onClick={() => onTabSelect(i)}
                                {...a11yProps(Id, i)}
                            />
                        );
                    })}
                </MuiTabs>
            </div>

            {tabs.map(({ children: Container, props = {} }: any, i: number) => {
                return (
                    <TabPanel
                        className="tab-panel"
                        id={Id}
                        key={`${Id}-container-${i}`}
                        value={value}
                        index={i}>
                        <Container {...props} />
                    </TabPanel>
                );
            })}
        </div>
    );
});
