import { createSlice } from '@reduxjs/toolkit';

interface actionsProps {
    payload: {
        selected?: string;
        settings?: any;
        options?: {
            selected_obj?: string;
            delete_selected?: boolean;
            keysMap?: any;
            [key: string]: any;
        };
    };
}

const initState: any = {
    selected: 'general',
    settings: {},
    options: {
        selected_obj: '',
        delete_selected: false,
        keysMap: { 17: false, 67: false, 46: false, 86: false }
    }
};

const editorOptionsSlice = createSlice({
    name: 'editor-options-modal',
    initialState: initState,
    reducers: {
        set(state, { payload }: actionsProps) {
            if (payload?.selected) state.selected = payload.selected;
            if (payload?.options) state.options = { ...state.options, ...payload.options };
            if (payload?.settings) state.settings = { ...state.settings, ...payload.settings };
        },
        reset(state) {
            const init: any = {
                selected: 'general',
                settings: {},
                options: {
                    selected_obj: '',
                    delete_selected: false,
                    keysMap: { 17: false, 67: false, 46: false, 86: false }
                }
            };

            for (const key in init) {
                state[key] = init[key];
            }
        }
    }
});

export const editorOptionsActions = editorOptionsSlice.actions;

export default editorOptionsSlice.reducer;
