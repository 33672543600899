import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, searchProps } from './interfaces/common.interface';
import {
    createActivityProps,
    getActivityProps,
    updateActivityProps
} from './interfaces/activity.interface';

const activityServices = createApi({
    reducerPath: 'activities-service',
    baseQuery: baseQuery,
    tagTypes: ['Activity', 'Activities', 'Analytics'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ org_id, ...props }: createActivityProps) => ({
                method: 'POST',
                url: `/activities/${org_id}/create`,
                body: props
            }),
            invalidatesTags: [{ type: 'Activities' }]
        }),
        get: builder.query({
            query: ({ org_id, id }: getActivityProps) => ({
                method: 'GET',
                url: `/activities/${org_id}/${id}`
            }),
            providesTags: [{ type: 'Activity' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, limit, find = {} }: searchProps) => ({
                method: 'GET',
                url: `/activities/${org_id}/all/${page}`,
                params: {
                    limit,
                    ...find
                }
            }),
            providesTags: [{ type: 'Activities' }]
        }),
        analytics: builder.query({
            query: ({ org_id, page, limit, find }: searchProps) => ({
                method: 'GET',
                url: `/activities/${org_id}/analytics/${page}`,
                params: {
                    limit,
                    ...find
                }
            }),
            providesTags: [{ type: 'Analytics' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateActivityProps) => ({
                method: 'PATCH',
                url: `/activities/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'Activities' }]
        }),
        delete: builder.mutation({
            query: ({ org_id, user_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/activities/${org_id}/delete/${user_id}`,
                body: { user_id, ...props }
            }),
            invalidatesTags: [{ type: 'Activities' }]
        })
    })
});

export default activityServices;
