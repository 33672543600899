interface Types {
    BADGE: string;
    CERTIFICATE: string;
    TRANSCRIPT: string;
    OTHER: string;
    MODEL: string;
    [key: string]: any;
}

const rewardElementUtils = {
    types: <Types>{
        BADGE: 'Badge',
        CERTIFICATE: 'Certificate',
        TRANSCRIPT: 'Transcript',
        MODEL: 'Model',
        OTHER: 'Other'
    },
    achievements: <Types>{
        BADGE: 'EducationalAchievementBadge',
        CERTIFICATE: 'EducationalAchievementCertificate',
        TRANSCRIPT: 'EducationalAchievementTranscript',
        OTHER: 'VerifiableContent'
    }
};

export default rewardElementUtils;
