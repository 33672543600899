import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../../../components/custom/icon-button';
import Icon from '../../../components/icon';
import { editorOptionsActions } from '../../../redux/reducers/editor/options';
import { elementActions } from '../../../redux/reducers/element';
import Settings from '../model/settings';

interface Props {
    model?: any;
}

const Titles = {
    general: 'General',
    attributes: 'Custom Attributes'
};

export default function RightBar({ model }: Props) {
    const dispatch = useDispatch();
    const { attributes } = useSelector((state: any) => state.element);
    const { selected, options } = useSelector((state: any) => state.editor_options);

    React.useEffect(() => {
        if (!options?.selected_obj && selected !== 'general' && selected !== 'attributes') {
            setSelected('general');
        }
    }, [selected, options]);

    /* functions */
    const onAddAttribute = () => {
        dispatch(elementActions.set({ attributes: [...attributes, { name: '', value: '' }] }));
    };

    const setSelected = (value: string) => {
        dispatch(editorOptionsActions.set({ selected: value }));
    };

    return (
        <div className="right-bar">
            <div className="menu">
                <IconButton
                    tooltip={{ title: 'General settings', placement: 'left' }}
                    onClick={() => setSelected('general')}
                    data-active={selected === 'general'}>
                    <Icon className="icon w-600">tune</Icon>
                </IconButton>
                <IconButton
                    tooltip={{ title: 'Custom attributes', placement: 'left' }}
                    onClick={() => setSelected('attributes')}
                    data-active={selected === 'attributes'}>
                    <Icon variant="outlined" className="icon w-600">
                        sell
                    </Icon>
                </IconButton>
            </div>

            <div className="content">
                <div className="title-wrap">
                    <p className="title">{Titles[selected]}</p>

                    {selected === 'attributes' && (
                        <IconButton
                            tooltip={{ title: 'Add attribute', placement: 'bottom' }}
                            onClick={onAddAttribute}>
                            <Icon className="icon w-600">add</Icon>
                        </IconButton>
                    )}
                </div>

                <Settings model={model} selected={selected} />
            </div>
        </div>
    );
}
