import React from 'react';
import Workspace from './workspace';
import LeftBar from './left-bar';
import RightBar from './right-bar';
import TopBar from './top-bar';

export default function CanvasLayout() {
    const [canvas, setCanvas]: any = React.useState(null);

    return (
        <div className="tools">
            <TopBar canvas={canvas} />

            <div className="content">
                <LeftBar canvas={canvas} />
                <Workspace canvas={canvas} setCanvas={setCanvas} />
                <RightBar canvas={canvas} />
            </div>
        </div>
    );
}
