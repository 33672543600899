import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import { DialogActions, Collapse } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { notifyActions } from '../../../redux/reducers/notify';
import List from '../../../components/custom/list';
import { FieldArray, useFormikContext } from 'formik';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '../../../components/form/text-field';
import Icon from '../../../components/icon';
import { modalTypes } from '../..';
import activityTagServices from '../../../services/activity-tags.services';
import { v4 as uuid } from 'uuid';
import Skeleton from '../../../components/custom/skeleton';
import utils from '../../../utils';
import IconButton from '../../../components/custom/icon-button';
import { useLocation } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    tags: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Name is required'),
            tag: Yup.string().required('Value is required')
        })
    )
});

export default function ManageTags() {
    const dispatch = useDispatch();
    const { state: routerState } = useLocation();

    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const { data: props } = modalProps[modalTypes.MANAGE_ACTIVITY_TAGS];
    const helper: any = React.useRef(null);

    /* query */
    const {
        data: activityTags,
        error: activityTagsError,
        isError: isActivityTagsError,
        isLoading: isLoading
    }: any = activityTagServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            page: 0,
            all: true,
            find: [{ column: 'activity_id', value: props?.id, like: false }]
        },
        { skip: !authUser?.organization_id || !props?.id }
    );

    /* mutation */
    const [
        update,
        { error: activityTagUpdateError, isError: isActivityTagsUpdateError, isLoading: isUpdating }
    ]: any = activityTagServices.useUpdateMutation();

    const [
        deleteTag,
        { error: activityTagDeleteError, isError: isActivityTagsDeleteError, isLoading: isDeleting }
    ]: any = activityTagServices.useDeleteMutation();

    /* error handling */
    React.useEffect(() => {
        if (isActivityTagsUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityTagUpdateError?.data?.message
                })
            );
        }
        if (isActivityTagsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityTagsError?.data?.message
                })
            );
        }
        if (isActivityTagsDeleteError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityTagDeleteError?.data?.message
                })
            );
        }
    }, [
        activityTagUpdateError,
        isActivityTagsUpdateError,
        activityTagsError,
        isActivityTagsError,
        activityTagDeleteError,
        isActivityTagsDeleteError
    ]);

    /* data */
    const data = React.useMemo(() => {
        const data = {
            tags: [{ name: '', tag: '' }]
        };

        if (Array.isArray(activityTags?.data)) {
            data.tags = activityTags?.data;
        }

        return data;
    }, [activityTags]);

    /* functions */
    const onAddTag = () => {
        if (helper.current) helper.current.push({ id: uuid(), name: '', tag: '', new: true });
    };

    const onDeleteTag = async (data: any, index: number) => {
        if (!data?.new) {
            await deleteTag({
                org_id: authUser?.organization_id,
                delete_id: data?.id
            });
        }

        if (helper.current) helper.current.remove(index);
    };

    const onSubmit = async (values: any) => {
        const parsed = values?.tags?.map((item: any) => {
            return {
                id: !item.new ? item.id : '',
                name: item.name,
                tag: item.tag
            };
        });

        const result = await update({
            data: parsed,
            activity_id: props?.id,
            org_id: authUser.organization_id
        });

        if (result?.data?.status) {
            dispatch(
                notifyActions.open({
                    type: result?.data?.status,
                    message: result.data?.message
                })
            );
        }

        if (result?.data?.status === 'success') {
            dispatch(modalActions.hide());
        }
    };

    const TextFields = ({ index, item }: any) => {
        const { setFieldValue } = useFormikContext();

        const onNameChange = (e: any) => {
            setFieldValue(`tags.${index}.name`, e.target.value);
            setFieldValue(`tags.${index}.tag`, utils.snakeCase(e.target.value));
        };

        return (
            <div className="double-wrap">
                <TextField
                    variant="standard"
                    name={`tags.${index}.name`}
                    placeholder="Tag Name"
                    label="Tag Name"
                    onChange={onNameChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                <TextField
                    variant="standard"
                    name={`tags.${index}.tag`}
                    placeholder="Tag Value"
                    label="Tag Value"
                    value={utils.snakeCase(item.tag)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </div>
        );
    };

    return (
        <div className="modal-container activity-tags-modal">
            {isLoading ? (
                <div className="modal-content">
                    <List className="list-wrap">
                        <Collapse>
                            <Accordion className="accordion">
                                <AccordionSummary
                                    className="accordion-summary"
                                    aria-controls="panel1a-content">
                                    <span className="label">
                                        <Skeleton width={45} height={10}></Skeleton>
                                    </span>
                                    <p className="value view">
                                        <Skeleton height={20}></Skeleton>
                                    </p>
                                </AccordionSummary>
                            </Accordion>
                        </Collapse>
                    </List>
                </div>
            ) : (
                <Form
                    enableReinitialize
                    initialValues={data}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    className="form">
                    <div className="form-content auto-padding overflow-content">
                        <FieldArray
                            name="tags"
                            render={(arrayHelpers) => {
                                helper.current = arrayHelpers;

                                const {
                                    form: { values, errors }
                                } = arrayHelpers;

                                const tag_errors: any = errors?.tags || [];

                                return values?.tags?.length === 0 ? (
                                    'No activity tags available'
                                ) : (
                                    <List className="list-wrap">
                                        {values?.tags?.map((item: any, index: number) => {
                                            return (
                                                <Collapse key={item?.id || uuid()}>
                                                    <Accordion
                                                        className={`accordion ${
                                                            tag_errors[index] ? 'error' : ''
                                                        }`}>
                                                        <AccordionSummary
                                                            className="accordion-summary"
                                                            expandIcon={
                                                                <ExpandMoreIcon className="expand-icon" />
                                                            }
                                                            aria-controls="panel1a-content">
                                                            <span className="label">
                                                                {item?.name}
                                                            </span>
                                                            <p className="value">{item?.tag}</p>
                                                        </AccordionSummary>
                                                        <AccordionDetails className="accordion-details">
                                                            <TextFields index={index} item={item} />
                                                            <div className="buttons">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        onDeleteTag(item, index)
                                                                    }>
                                                                    <Icon className="icon">
                                                                        delete
                                                                    </Icon>
                                                                </IconButton>
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Collapse>
                                            );
                                        })}
                                    </List>
                                );
                            }}
                        />
                    </div>
                    <DialogActions className="modal-buttons">
                        <div className="section">
                            <Button
                                onClick={onAddTag}
                                variant="contained"
                                disabled={isUpdating || !!routerState?.read_only}
                                endIcon={<Icon>add_circle</Icon>}>
                                Add New Tag
                            </Button>
                        </div>
                        <div className="section">
                            <Button
                                disabled={isUpdating}
                                onClick={() => dispatch(modalActions.hide())}>
                                Cancel
                            </Button>
                            <SubmitButton
                                disabled={!!routerState?.read_only}
                                loading={isUpdating}
                                variant="contained">
                                Save Details
                            </SubmitButton>
                        </div>
                    </DialogActions>
                </Form>
            )}
        </div>
    );
}
