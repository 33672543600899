import React from 'react';
import Button from '../../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../../redux/reducers/modal';
import { modalTypes } from '../../..';
import { notifyActions } from '../../../../redux/reducers/notify';
import activityServices from '../../../../services/activity.services';
import activityTagServices from '../../../../services/activity-tags.services';
import SearchSelect from '../../../../components/custom/search-select';
import { activityActions } from '../../../../redux/reducers/activity';

export default function DeleteOtherInfoTag() {
    const dispatch = useDispatch();

    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const [selectedTag, setSelectedTag] = React.useState('');
    const props = modalProps[modalTypes.DELETE_OTHER_INFO_TAG];

    /* mutation */
    const [
        update,
        {
            error: activityUpdateError,
            isError: isActivityUpdateError,
            isLoading: isActivityUpdating
        }
    ]: any = activityServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isActivityUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityUpdateError.data?.message
                })
            );
        }
    }, [activityUpdateError, isActivityUpdateError]);

    const onSubmit = async () => {
        const result: any = await update({
            id: props?.activity,
            org_id: authUser.organization_id,
            other_info_tag: ''
        });

        if (result?.data?.status === 'success') {
            dispatch(
                activityActions.update({
                    other_info_tag: ''
                })
            );

            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container select-other-info-tag-modal">
            <div className="modal-content">Are you sure you want to remove this activity tag?</div>

            <DialogActions className="modal-buttons">
                <Button disabled={isActivityUpdating} onClick={() => dispatch(modalActions.hide())}>
                    Cancel
                </Button>
                <Button
                    onClick={onSubmit}
                    loading={isActivityUpdating}
                    className="bg-delete"
                    variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </div>
    );
}
