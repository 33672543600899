import React from 'react';
import Button from '../../../../components/custom/button';
import * as Yup from 'yup';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../../redux/reducers/modal';
import { modalTypes } from '../../..';
import { notifyActions } from '../../../../redux/reducers/notify';
import Form from '../../../../components/form';
import TextField from '../../../../components/form/text-field';
import SubmitButton from '../../../../components/form/submit-button';
import activityServices from '../../../../services/activity.services';

const initialValues = {
    password: ''
};

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required')
});

export default function DeleteActivity() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.DELETE_ACTIVITY];

    /* mutation */
    const [
        deleteActivity,
        { error: deleteActivityError, isError: isDeleteActivityError, isLoading: isDeleting }
    ]: any = activityServices.useDeleteMutation();

    /* error handling */
    React.useEffect(() => {
        if (isDeleteActivityError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: deleteActivityError.data?.message
                })
            );
        }
    }, [deleteActivityError, isDeleteActivityError]);

    const onSubmit = async (values: any) => {
        const result = await deleteActivity({
            password: values.password,
            delete_id: props.id,
            user_id: authUser.id,
            org_id: authUser.organization_id
        });

        if (result?.data?.status === 'success') {
            dispatch(activityServices.util.invalidateTags(['Activities']));

            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    Are you sure you want to delete this activity?
                    <TextField
                        name="password"
                        type="password"
                        placeholder="Enter Password"
                        label="Password"
                        required
                    />
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isDeleting} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isDeleting} className="bg-delete" variant="contained">
                        Confirm
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
