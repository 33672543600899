import { createSlice } from '@reduxjs/toolkit';

interface actionsProps {
    payload: {
        name?: string;
        type?: string;
        description?: string;
        element?: string | number;
        attributes?: any[];
        other_attributes?: any;
        [key: string]: any;
    };
}

const initState: any = {
    name: '',
    type: '',
    description: '',
    element: '',
    attributes: [],
    other_attributes: {}
};

const elementSlice = createSlice({
    name: 'editor-modal',
    initialState: initState,
    reducers: {
        set(state, { payload }: actionsProps) {
            if (payload) {
                for (const key in payload) {
                    state[key] = payload[key];
                }
            }
        },
        reset(state) {
            const init: any = {
                name: '',
                description: '',
                element: '',
                attributes: [],
                other_attributes: {}
            };

            state = { ...init };
        }
    }
});

export const elementActions = elementSlice.actions;

export default elementSlice.reducer;
