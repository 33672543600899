import React from 'react';
import TextField from '../../../../components/custom/text-field';

interface Props {
    canvas: any;
}

export default function Common({ canvas }: Props) {
    const [data, setData] = React.useState({
        width: 0,
        height: 0,
        skewX: 0,
        skewY: 0,
        angle: 0
    });

    React.useEffect(() => {
        if (canvas) {
            const objects = canvas.getActiveObjects();
            let temp: any = {};

            /* get common data from all objects */
            for (const obj of objects) {
                obj['image'] = obj.src ? obj.getSrc() : '';

                for (const key in obj) {
                    if (Object.hasOwnProperty.call(obj, key)) {
                        const element = obj[key];

                        if (temp[key] && temp[key] !== element) {
                            delete temp[key];
                        }

                        if (!temp[key]) {
                            temp[key] = element;
                        }
                    }
                }
            }

            setData(temp);
        }
    }, [canvas]);

    const update = ({ type, value }: { type: string; value: any }) => {
        const objects = canvas.getActiveObjects();

        for (const obj of objects) {
            obj.set({ [type]: value });
            setData({ ...data, [type]: value });
            canvas.requestRenderAll();
        }
    };

    return (
        <div className="common-settings">
            <div className="double-wrap">
                <TextField
                    label="Width (px)"
                    placeholder="px"
                    type="number"
                    value={data?.width}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) => update({ type: 'width', value: e.target.value })}
                />
                <TextField
                    label="Height (px)"
                    placeholder="px"
                    type="number"
                    value={data?.height}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) => update({ type: 'height', value: e.target.value })}
                />
            </div>
            <div className="double-wrap">
                <TextField
                    label="Skew X (px)"
                    placeholder="px"
                    type="number"
                    value={data?.skewX}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) => update({ type: 'skewX', value: e.target.value })}
                />
                <TextField
                    label="Skew Y (px)"
                    placeholder="px"
                    type="number"
                    value={data?.skewY}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) => update({ type: 'skewY', value: e.target.value })}
                />
            </div>

            <TextField
                label="Angle (deg)"
                placeholder="deg"
                type="number"
                value={data?.angle}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => update({ type: 'angle', value: e.target.value })}
            />
        </div>
    );
}
