import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalTypes } from '../..';
import IconButton from '../../../components/custom/icon-button';
import MuiTextField from '../../../components/custom/text-field';
import Icon from '../../../components/icon';
import PageTable from '../../../components/page-table';
import { notifyActions } from '../../../redux/reducers/notify';
import activityTagServices from '../../../services/activity-tags.services';
import transcriptSubjectServices from '../../../services/transcript-subject.services';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Paper,
    DialogActions
} from '@mui/material';
import TablePagination from '../../../components/page-table/pagination';
import Skeleton from '../../../components/custom/skeleton';
import { v4 as uuid } from 'uuid';
import Form from '../../../components/form';
import * as Yup from 'yup';
import { FieldArray } from 'formik';
import TableHead from '../../../components/page-table/head';
import TextField from '../../../components/form/text-field';
import SearchSelect from '../../../components/form/search-select';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import { modalActions } from '../../../redux/reducers/modal';
import { useLocation } from 'react-router-dom';

const initialPageSize = 10;

const validationSchema = Yup.object().shape({
    subjects: Yup.array().of(
        Yup.object().shape({
            id: Yup.string(),
            name: Yup.string().required('Name is required'),
            code: Yup.string(),
            credit: Yup.string(),
            marks: Yup.string(),
            grade: Yup.string()
        })
    )
});

export default function Subjects() {
    const dispatch = useDispatch();
    const { state: routerState } = useLocation();

    const { user: authUser } = useSelector((state: any) => state.user);
    const { modalProps } = useSelector((state: any) => state.modal);
    const { activity } = useSelector((state: any) => state.activity);
    const { element: element_id } = useSelector((state: any) => state.editor);
    const props = modalProps[modalTypes.TRANSCRIPT_SUBJECTS];

    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState('');

    const helper: any = React.useRef(null);

    const searchOptions: any = React.useMemo(() => {
        const template: any = [
            { column: 'element_id', value: element_id, like: false, operator: 'and' },
            { column: 'name', value: search, like: true },
            { column: 'code', value: search, like: true }
        ];

        return template;
    }, [element_id, search]);

    /* mutations */
    const [
        update,
        { error: subjectsUpdateError, isError: isSubjectsUpdateError, isLoading: isUpdating }
    ]: any = transcriptSubjectServices.useUpdateMutation();

    const [
        deleteSubject,
        {
            error: transcriptSubjectDeleteError,
            isError: isActivitySubjectsDeleteError,
            isLoading: isDeleting
        }
    ]: any = transcriptSubjectServices.useDeleteMutation();

    /* query */
    const {
        data: activityTags,
        error: activityTagsError,
        isError: isActivityTagsError,
        isLoading: isLoading
    }: any = activityTagServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            page: 0,
            all: true,
            find: [{ column: 'activity_id', value: activity?.id, like: false }]
        },
        { skip: !authUser?.organization_id || !activity?.id }
    );

    const {
        data: subjects,
        refetch: refetchSubjects,
        isLoading: isSubjectsLoading,
        error: subjectError,
        isError: isSubjectsError
    }: any = transcriptSubjectServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            page: page,
            limit: pageSize,
            find: searchOptions
        },
        { skip: !authUser?.organization_id }
    );

    const {
        data: options,
        refetch: refetchSubjectsOptions,
        isLoading: isSubjectsOptionsLoading,
        error: subjectOptionsError,
        isError: isSubjectsOptionsError
    }: any = transcriptSubjectServices.useOptionsQuery(
        {
            org_id: authUser.organization_id,
            limit: pageSize,
            find: searchOptions
        },
        { skip: !authUser?.organization_id }
    );

    /* error handling */
    React.useEffect(() => {
        if (isSubjectsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: subjectError.data?.message
                })
            );
        }
        if (isSubjectsOptionsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: subjectOptionsError.data?.message
                })
            );
        }
        if (isActivityTagsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityTagsError.data?.message
                })
            );
        }
        if (isActivitySubjectsDeleteError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: transcriptSubjectDeleteError.data?.message
                })
            );
        }
        if (isSubjectsUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: subjectsUpdateError.data?.message
                })
            );
        }
    }, [
        subjectError,
        isSubjectsError,
        subjectOptionsError,
        isSubjectsOptionsError,
        activityTagsError,
        isActivityTagsError,
        transcriptSubjectDeleteError,
        isActivitySubjectsDeleteError,
        subjectsUpdateError,
        isSubjectsUpdateError
    ]);

    /* data handling */
    const data = React.useMemo(() => {
        const template: any = {
            subjects: []
        };

        if (Array.isArray(subjects?.data)) {
            template.subjects = subjects?.data?.map((item: any) => {
                return {
                    id: item.id,
                    name: item.name,
                    code: item.code,
                    credit: item.credit,
                    marks: item.marks,
                    grade: item.grade
                };
            });
        }

        return template;
    }, [subjects]);

    const tagsData: any = React.useMemo(() => {
        let data: any = [];

        if (Array.isArray(activityTags?.data)) {
            data = activityTags?.data.map((i) => {
                return {
                    label: i.name,
                    value: i.tag
                };
            });
        }

        return data;
    }, [activityTags]);

    /* cells */
    const renderNameCell = ({ data, index }: any) => {
        return (
            <TextField
                InputProps={{ readOnly: !!routerState?.read_only }}
                name={`subjects.${index}.name`}
                placeholder="Subject Name"
                required
            />
        );
    };

    const renderCodeCell = ({ data, index }: any) => {
        return (
            <TextField
                InputProps={{ readOnly: !!routerState?.read_only }}
                name={`subjects.${index}.code`}
                placeholder="Subject Code"
            />
        );
    };

    const renderCreditsCell = ({ data, index }: any) => {
        return (
            <SearchSelect
                readOnly={!!routerState?.read_only}
                className="header"
                name={`subjects.${index}.credit`}
                data={tagsData}
            />
        );
    };

    const renderMarksCell = ({ data, index }: any) => {
        return (
            <SearchSelect
                readOnly={!!routerState?.read_only}
                className="header"
                name={`subjects.${index}.marks`}
                data={tagsData}
            />
        );
    };

    const renderGradeCell = ({ data, index }: any) => {
        return (
            <SearchSelect
                readOnly={!!routerState?.read_only}
                className="header"
                name={`subjects.${index}.grade`}
                data={tagsData}
            />
        );
    };

    const renderDeleteBtn = ({ data, index }: any) => {
        return (
            <IconButton
                disabled={!!routerState?.read_only}
                onClick={() => onDeleteSubject(data, index)}
                tooltip={{ title: 'Delete', placement: 'bottom' }}>
                <Icon className="icon">delete</Icon>
            </IconButton>
        );
    };

    const tableOptions = {
        headers: [
            { render: 'Name' },
            { render: 'Code' },
            { render: 'Credit' },
            { render: 'Marks' },
            { render: 'Grade' },
            { render: '', props: { className: 'icon-button-cell' } }
        ],
        cells: [
            { render: renderNameCell },
            { render: renderCodeCell },
            { render: renderCreditsCell },
            { render: renderMarksCell },
            { render: renderGradeCell },
            { render: renderDeleteBtn, props: { className: 'icon-button-cell', align: 'center' } }
        ]
    };

    /* functions */
    const onPageChange = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const onRowsPerPageChange = (event: any) => {
        if (setPageSize) setPageSize(parseInt(event.target.value, 10));
    };

    const typeCheck = (value: any) => {
        return typeof value === 'string' || typeof value === 'number';
    };

    const onAddSubject = () => {
        if (helper.current)
            helper.current.push({
                id: uuid(),
                name: '',
                code: '',
                credit: '',
                marks: '',
                grade: '',
                new: true
            });
    };

    const onDeleteSubject = async (data: any, index: number) => {
        if (!data?.new) {
            await deleteSubject({
                org_id: authUser?.organization_id,
                delete_id: data?.id
            });
        }

        if (helper.current) helper.current.remove(index);
    };

    const onSubmit = async (values: any) => {
        const parsed = values?.subjects?.map((item: any) => {
            return {
                id: !item.new ? item.id : '',
                name: item.name,
                code: item.code,
                credit: item.credit,
                marks: item.marks,
                grade: item.grade
            };
        });

        const result = await update({
            data: parsed,
            activity_id: activity?.id,
            element_id: element_id,
            org_id: authUser.organization_id
        });

        if (result?.data?.status) {
            dispatch(
                notifyActions.open({
                    type: result?.data?.status,
                    message: result.data?.message
                })
            );
        }

        if (result?.data?.status === 'success') {
            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container subjects-modal">
            <div className="modal-content">
                <MuiTextField
                    size="small"
                    placeholder="Search"
                    className="input search"
                    InputProps={{
                        startAdornment: <Icon className="icon text-search">search</Icon>
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            <Form
                enableReinitialize
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="page-table">
                    <div className="body">
                        <div className="table">
                            <TableContainer component={Paper}>
                                <Table className="table" aria-label="table">
                                    <TableHead headers={tableOptions.headers} />

                                    <TableBody>
                                        {isSubjectsLoading ? (
                                            <TableRow>
                                                {tableOptions.headers.map(
                                                    ({ props }: any, i: number) => {
                                                        return (
                                                            <TableCell key={i}>
                                                                {props?.className?.includes(
                                                                    'icon-button-cell'
                                                                ) ? (
                                                                    <Skeleton
                                                                        variant="circular"
                                                                        width={20}
                                                                        height={20}></Skeleton>
                                                                ) : (
                                                                    <Skeleton
                                                                        height={20}></Skeleton>
                                                                )}
                                                            </TableCell>
                                                        );
                                                    }
                                                )}
                                            </TableRow>
                                        ) : (
                                            <FieldArray
                                                name="subjects"
                                                render={(arrayHelpers) => {
                                                    helper.current = arrayHelpers;

                                                    const {
                                                        form: { values, errors }
                                                    } = arrayHelpers;

                                                    const tag_errors: any = errors?.tags || [];

                                                    return values?.subjects?.length === 0 ? (
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={
                                                                    tableOptions?.headers?.length
                                                                }>
                                                                No records available
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        values?.subjects?.map(
                                                            (item: any, idx: number) => {
                                                                return (
                                                                    <TableRow
                                                                        key={item?.id ?? uuid()}>
                                                                        {tableOptions?.cells.map(
                                                                            (
                                                                                {
                                                                                    render: Render,
                                                                                    props = {}
                                                                                }: any,
                                                                                i: number
                                                                            ) => {
                                                                                if (!props.align)
                                                                                    props.align =
                                                                                        'left';

                                                                                return (
                                                                                    <TableCell
                                                                                        key={`${idx}-${i}`}
                                                                                        {...props}>
                                                                                        {typeCheck(
                                                                                            Render
                                                                                        ) ? (
                                                                                            <span className="text">
                                                                                                {item[
                                                                                                    Render
                                                                                                ] ||
                                                                                                    '-'}
                                                                                            </span>
                                                                                        ) : (
                                                                                            <Render
                                                                                                index={
                                                                                                    idx
                                                                                                }
                                                                                                data={
                                                                                                    item
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </TableCell>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </TableRow>
                                                                );
                                                            }
                                                        )
                                                    );
                                                }}
                                            />
                                        )}
                                    </TableBody>
                                </Table>
                                <div className="pagination">
                                    <TablePagination
                                        count={options?.count || 0}
                                        rowsPerPage={pageSize}
                                        page={page}
                                        onPageChange={onPageChange}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                    />
                                </div>
                            </TableContainer>
                        </div>
                    </div>
                </div>
                <DialogActions className="modal-buttons">
                    <div className="section">
                        <Button
                            disabled={!!routerState?.read_only}
                            onClick={onAddSubject}
                            variant="contained">
                            Add Subject
                        </Button>
                    </div>
                    <div className="section">
                        <Button onClick={() => dispatch(modalActions.hide())}>Cancel</Button>
                        <SubmitButton disabled={!!routerState?.read_only} variant="contained">
                            Save
                        </SubmitButton>
                    </div>
                </DialogActions>
            </Form>
        </div>
    );
}
