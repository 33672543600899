import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalTypes } from '../..';
import { notifyActions } from '../../../redux/reducers/notify';
import rewardElementServices from '../../../services/reward-element.services';
import Skeleton from '../../../components/custom/skeleton';
import List from '../../../components/custom/list';
import { Collapse } from '@mui/material';
import IconButton from '../../../components/custom/icon-button';
import Icon from '../../../components/icon';
import { modalActions } from '../../../redux/reducers/modal';

export default function RewardElements() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.REWARD_ELEMENTS];

    /* query */
    const {
        data: elements,
        error: elementsError,
        isError: isElementsError,
        isLoading: isElementsLoading
    }: any = rewardElementServices.useGetAllQuery(
        {
            org_id: authUser?.organization_id,
            page: 0,
            all: true,
            find: [{ column: 'activity_id', value: props.activity_id, like: false }]
        },
        { skip: !props?.activity_id || !authUser?.organization_id }
    );

    /* error handling */
    React.useEffect(() => {
        if (isElementsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementsError.data?.message
                })
            );
        }
    }, [elementsError, isElementsError]);

    /* functions */
    const onPreview = (id: number) => {
        dispatch(modalActions.hide());

        dispatch(
            modalActions.show({
                type: modalTypes.PREVIEW_REWARD_ELEMENT,
                props: {
                    id: id,
                    reward_id: props?.reward_id,
                    back_modal: {
                        type: modalTypes.REWARD_ELEMENTS,
                        props: props
                    }
                }
            })
        );
    };

    return (
        <div className="modal-container reward-elements-modal">
            <div className="modal-content">
                {isElementsLoading ? (
                    <List className="list-wrap">
                        {[...new Array(3)].map((v: any, i: number) => {
                            return (
                                <Collapse key={i}>
                                    <div className="item">
                                        <Skeleton width={240} height={20} />

                                        <IconButton>
                                            <Skeleton variant="circular" width={20} height={20} />
                                        </IconButton>
                                    </div>
                                </Collapse>
                            );
                        })}
                    </List>
                ) : elements?.data?.length === 0 ? (
                    <p>No reward elements available</p>
                ) : (
                    <List className="list-wrap">
                        {elements?.data?.map((item: any) => {
                            return (
                                <Collapse>
                                    <div className="item" onClick={() => onPreview(item.id)}>
                                        <span>{item?.name}</span>
                                        <IconButton>
                                            <Icon>chevron_right</Icon>
                                        </IconButton>
                                    </div>
                                </Collapse>
                            );
                        })}
                    </List>
                )}
            </div>
        </div>
    );
}
