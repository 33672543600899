import React from 'react';
import editorUtils from '../../utils/editor';

interface Props {
    json: any;
}

export default function CanvasPreview({ json }: Props) {
    const [loading, setLoading] = React.useState(true);
    const [image, setImage] = React.useState('');

    React.useEffect(() => {
        if (json) {
            (async () => {
                const img: any = await editorUtils.toImage(json);

                if (img) {
                    setImage(img);

                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }
            })();
        }
    }, [json]);

    return (
        <div className="canvas-preview">
            {loading ? (
                'creating image...'
            ) : (
                <img className="image" src={image} alt="Canvas Preview" />
            )}
        </div>
    );
}
