import React from 'react';
import Button from '../../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../../redux/reducers/modal';
import { modalTypes } from '../../..';
import { notifyActions } from '../../../../redux/reducers/notify';
import activityBatchServices from '../../../../services/activity-batch.services';

export default function AssignActivityBatch() {
    const dispatch = useDispatch();

    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.ASSIGN_BATCH];

    /* mutation */
    const [
        update,
        {
            error: activityBatchUpdateError,
            isError: isActivityBatchUpdateError,
            isLoading: isActivityBatchUpdating
        }
    ]: any = activityBatchServices.useUpdateMutation();

    const [
        removeBatchUser,
        {
            error: activityBatchRemoveUserError,
            isError: isActivityBatchRemoveUserError,
            isLoading: isActivityBatchRemovingUser
        }
    ]: any = activityBatchServices.useRemoveUserMutation();

    /* error handling */
    React.useEffect(() => {
        if (isActivityBatchUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityBatchUpdateError.data?.message
                })
            );
        }
        if (isActivityBatchRemoveUserError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityBatchRemoveUserError.data?.message
                })
            );
        }
    }, [
        activityBatchUpdateError,
        isActivityBatchUpdateError,
        activityBatchRemoveUserError,
        isActivityBatchRemoveUserError
    ]);

    const onSubmit = async () => {
        if (props?.current) {
            const remove_batch: any = await removeBatchUser({
                id: props?.current,
                org_id: authUser.organization_id
            });
        }

        const result: any = await update({
            id: props?.id,
            batch: props.batch,
            activity_id: props.activity,
            user_id: authUser.id,
            org_id: authUser.organization_id
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container">
            <div className="modal-content">
                This batch has been already added to the activity. Do you wish to continue?
            </div>

            <DialogActions className="modal-buttons">
                <Button
                    disabled={isActivityBatchUpdating}
                    onClick={() => dispatch(modalActions.hide())}>
                    Cancel
                </Button>
                <Button
                    onClick={onSubmit}
                    loading={isActivityBatchUpdating}
                    className="bg-delete"
                    variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </div>
    );
}
