import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps,
    searchProps
} from './interfaces/common.interface';
import { updateReceiverDetailProps } from './interfaces/receiver-detail.interface';

const receiverDetailServices = createApi({
    reducerPath: 'receiver-details-service',
    baseQuery: baseQuery,
    tagTypes: ['ReceiverDetails', 'ReceiverDetailsOptions'],
    endpoints: (builder) => ({
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/receiver-details/${org_id}/get/${type}/${value}`
            })
        }),
        getAll: builder.query({
            query: ({ org_id, page, limit, find = {} }: searchProps) => ({
                method: 'GET',
                url: `/receiver-details/${org_id}/all/${page}`,
                params: {
                    limit,
                    ...find
                }
            }),
            providesTags: [{ type: 'ReceiverDetails' }, { type: 'ReceiverDetailsOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/receiver-details/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'ReceiverDetailsOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateReceiverDetailProps) => ({
                method: 'PUT',
                url: `/receiver-details/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'ReceiverDetails' }, { type: 'ReceiverDetailsOptions' }]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/receiver-details/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'ReceiverDetails' }, { type: 'ReceiverDetailsOptions' }]
        })
    })
});

export default receiverDetailServices;
