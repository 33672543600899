import React from 'react';
import Button from '../../../components/custom/button';
import * as Yup from 'yup';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { modalTypes } from '../..';
import Form from '../../../components/form';
import TextField from '../../../components/form/text-field';
import SubmitButton from '../../../components/form/submit-button';
import { notifyActions } from '../../../redux/reducers/notify';
import rewardServices from '../../../services/reward.services';

const initialValues = {
    password: ''
};

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required')
});

export default function GenerateBackEndRewards() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.GENERATE_BACKEND_REWARDS];

    /* mutation */
    const [
        generate,
        { error: generateRewardsError, isError: isGenerateRewardsError, isLoading: isGenerating }
    ]: any = rewardServices.useGenerateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isGenerateRewardsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: generateRewardsError.data?.message
                })
            );
        }
    }, [generateRewardsError, isGenerateRewardsError]);

    const onSubmit = async (values: any) => {
        const result = await generate({
            password: values.password,
            reward_ids: props.reward_ids,
            linkedin_profile_id: props.linkedin_profile_id,
            activity_id: props.activity_id,
            org_id: authUser.organization_id
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    Enter the password used to save the organization private key.
                    <TextField
                        name="password"
                        type="password"
                        placeholder="Enter Password"
                        label="Password"
                        required
                    />
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isGenerating} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isGenerating} variant="contained">
                        Confirm
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
