import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';

const fontServices = createApi({
    reducerPath: 'fonts-service',
    baseQuery: baseQuery,
    tagTypes: ['Fonts'],
    endpoints: (builder) => ({
        getAll: builder.query({
            query: ({}: any) => ({
                method: 'GET',
                url: `/fonts/all`
            }),
            providesTags: [{ type: 'Fonts' }]
        })
    })
});

export default fontServices;
