import React from 'react';
import _ from 'lodash';
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/custom/button';
import TextField from '../../../../components/custom/text-field';
import formatImage from '../../../../functions/format-image';
import ColorPicker from '../../../components/color-picker';
import { elementActions } from '../../../../redux/reducers/element';
import { editorOptionsActions } from '../../../../redux/reducers/editor/options';
import moment from 'moment';
import { modalActions } from '../../../../redux/reducers/modal';
import { modalTypes } from '../../../../modals';

interface Props {
    canvas?: any;
}

export default function General({ canvas }: Props) {
    const dispatch = useDispatch();
    const element = useSelector((state: any) => state.element);
    const { settings } = useSelector((state: any) => state.editor_options);
    const [worksheetData, setWorksheetData] = React.useState({
        fill: '#ffffff',
        opacity: 100
    });

    const data = settings?.general;

    React.useEffect(() => {
        if (canvas) {
            const worksheet = _.find(canvas._objects, function (o) {
                return o.name === 'worksheet';
            });

            if (worksheet)
                setWorksheetData({
                    fill: worksheet?.fill,
                    opacity: Number(worksheet?.opacity)
                });
        }
    }, [canvas]);

    const onImage = (e: any) => {
        let files = e.target.files;
        const reader = new FileReader();

        if (files[0]) {
            reader.readAsDataURL(files[0]);

            reader.onload = async () => {
                const base64: any = await formatImage(reader.result);
                const worksheet = _.find(canvas._objects, function (o) {
                    return o.name === 'worksheet';
                });

                let img = new Image();

                img.onload = function () {
                    let obj: any = new fabric.Image(img, {
                        fill: '#ffffff',
                        selectable: true,
                        top: worksheet.top,
                        left: worksheet.left,
                        scaleX: worksheet.width / img.width,
                        scaleY: worksheet.height / img.height,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true,
                        lockScalingFlip: true
                    });

                    canvas.add(obj);
                    canvas.moveTo(obj, 1);
                    canvas.requestRenderAll();
                };
                img.src = base64;
            };
        }
    };

    const update = ({ type, value }: { type: string; value: any }) => {
        const objects = [
            _.find(canvas._objects, function (o) {
                return o.name === 'worksheet';
            })
        ];

        for (const obj of objects) {
            if (obj) {
                obj.set({ [type]: value });
                canvas.requestRenderAll();
            }
        }

        if (type === 'fill' || type === 'opacity') {
            setWorksheetData({ ...worksheetData, [type]: value });
        }

        dispatch(
            editorOptionsActions.set({
                settings: {
                    ...settings,
                    general: {
                        ...data,
                        [type]: value
                    }
                }
            })
        );
    };

    const updateElement = ({ type, value }: { type: string; value: any }) => {
        dispatch(elementActions.set({ [type]: value }));
    };

    const updateElementAttributes = ({ type, value }: { type: string; value: any }) => {
        dispatch(
            elementActions.set({
                other_attributes: { ...element?.other_attributes, [type]: value }
            })
        );
    };

    const getTxtLabel = (field: string) => {
        if (element?.type === 'CERTIFICATE' || element?.type === 'OTHER') {
            if (field === 'name') return 'Title';
        }

        return 'Name';
    };

    return (
        <div className="editor-settings">
            <TextField
                label={getTxtLabel('name')}
                placeholder={getTxtLabel('name')}
                value={element?.name}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => updateElement({ type: 'name', value: e.target.value })}
                required
            />

            <TextField
                label="Description"
                placeholder="Description"
                value={element?.description}
                multiline
                minRows={4}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => updateElement({ type: 'description', value: e.target.value })}
                required
            />

            {element.type === 'TRANSCRIPT' && (
                <>
                    <TextField
                        label="University"
                        placeholder="University"
                        value={element?.other_attributes?.university}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            updateElementAttributes({ type: 'university', value: e.target.value })
                        }
                        required
                    />

                    <TextField
                        label="Qualification"
                        placeholder="Qualification"
                        value={element?.other_attributes?.qualification}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            updateElementAttributes({
                                type: 'qualification',
                                value: e.target.value
                            })
                        }
                        required
                    />

                    <Button
                        onClick={() =>
                            dispatch(
                                modalActions.show({
                                    type: modalTypes.TRANSCRIPT_SUBJECTS
                                })
                            )
                        }
                        size="medium">
                        Transcript Details
                    </Button>
                </>
            )}

            {element.type === 'CERTIFICATE' && (
                <TextField
                    label="Conducted By"
                    placeholder="Conducted By"
                    value={element?.other_attributes?.conducted_by}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                        updateElementAttributes({ type: 'conducted_by', value: e.target.value })
                    }
                    required
                />
            )}

            {(element.type === 'BADGE' ||
                element.type === 'CERTIFICATE' ||
                element.type === 'TRANSCRIPT') && (
                <TextField
                    label="Issued Date"
                    placeholder="Issued Date"
                    type="date"
                    value={
                        element?.other_attributes?.issued_date
                            ? moment(element?.other_attributes?.issued_date).format('YYYY-MM-DD')
                            : ''
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                        updateElementAttributes({ type: 'issued_date', value: e.target.value })
                    }
                    required
                />
            )}

            <p className="title">Canvas Size</p>
            <TextField
                label="Width (px)"
                placeholder="px"
                type="number"
                value={data?.width}
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => update({ type: 'width', value: Number(e.target.value) })}
            />

            <TextField
                label="Height (px)"
                placeholder="px"
                type="number"
                value={data?.height}
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => update({ type: 'height', value: Number(e.target.value) })}
            />

            <ColorPicker
                opacity={worksheetData?.opacity}
                color={worksheetData?.fill}
                setColor={(color: string) => update({ type: 'fill', value: color })}
                setOpacity={(opacity: number) => update({ type: 'opacity', value: opacity })}
            />

            <p className="title">Background Image</p>
            <Button component="label" size="medium">
                <input type="file" accept="image/*" hidden onChange={onImage} />
                Choose Image
            </Button>
        </div>
    );
}
