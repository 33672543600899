import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    createRewardPurchaseProps,
    updateRewardPurchaseProps
} from './interfaces/reward-purchase.interface';

const rewardPurchaseServices = createApi({
    reducerPath: 'reward-purchase-service',
    baseQuery: baseQuery,
    tagTypes: ['RewardPurchases', 'RewardPurchase', 'RewardPurchaseOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ org_id, user_id, ...props }: createRewardPurchaseProps) => ({
                method: 'POST',
                url: `/reward-purchases/${org_id}/create/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'RewardPurchases' }, { type: 'RewardPurchaseOptions' }]
        }),
        get: builder.query({
            query: ({ org_id, type, value, ...props }: getProps) => ({
                method: 'POST',
                url: `/reward-purchases/${org_id}/get/${type}/${value}`,
                body: props
            }),
            providesTags: [{ type: 'RewardPurchase' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/reward-purchases/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'RewardPurchases' }, { type: 'RewardPurchaseOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/reward-purchases/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'RewardPurchaseOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, user_id, ...props }: updateRewardPurchaseProps) => ({
                method: 'PATCH',
                url: `/reward-purchases/${org_id}/update/${user_id}`,
                body: props
            }),
            invalidatesTags: [
                { type: 'RewardPurchases' },
                { type: 'RewardPurchaseOptions' },
                { type: 'RewardPurchase' }
            ]
        }),
        delete: builder.mutation({
            query: ({ org_id, user_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/reward-purchases/${org_id}/delete/${user_id}`,
                body: props
            }),
            invalidatesTags: [
                { type: 'RewardPurchases' },
                { type: 'RewardPurchaseOptions' },
                { type: 'RewardPurchase' }
            ]
        })
    })
});

export default rewardPurchaseServices;
