import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import TextField from '../../../components/form/text-field';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { notifyActions } from '../../../redux/reducers/notify';
import SearchSelect from '../../../components/form/search-select';
import { useNavigate } from 'react-router-dom';
import rewardElementUtils from '../../../utils/reward-elements';
import elementTemplateServices from '../../../services/element-templates.services';
import samples from '../../../editor/assets/samples.json';

const initialValues = {
    name: '',
    type: ''
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Activity name is required'),
    type: Yup.string().required('Type is required')
});

export default function AddElementTemplate() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);

    /* mutation */
    const [
        create,
        { error: elementTemplateError, isError: isElementTemplateError, isLoading: isCreating }
    ]: any = elementTemplateServices.useCreateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isElementTemplateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementTemplateError.data?.message
                })
            );
        }
    }, [elementTemplateError, isElementTemplateError]);

    /* functions */
    const getElementTypes = React.useCallback(() => {
        return Object.entries(rewardElementUtils.types).map(([key, value]) => {
            return { value: key, label: value };
        });
    }, [rewardElementUtils.types]);

    const onSubmit = async (values: any) => {
        const result = await create({
            org_id: authUser.organization_id,
            name: values.name,
            type: values.type,
            description: '',
            reward: samples.templates[values.type],
            custom_attributes: samples.custom_attributes
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container activity-modal">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <SearchSelect name="type" placeholder="Select Type" data={getElementTypes()} />

                    <TextField
                        name="name"
                        label="Element Name"
                        placeholder="Element Name"
                        required
                    />
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isCreating} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isCreating} variant="contained">
                        Create Element
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
