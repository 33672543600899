import { configureStore, combineReducers } from '@reduxjs/toolkit';
import modalReducer from './reducers/modal';
import notifyReducer from './reducers/notify';
import userReducer from './reducers/user';
import backdropReducer from './reducers/backdrop';
import logoutReducer from './reducers/logout';
import activityReducer from './reducers/activity';
import elementReducer from './reducers/element';
import editorReducer from './reducers/editor';
import editorOptionsReducer from './reducers/editor/options';

import authServices from '../services/auth.services';
import orgServices from '../services/org.services';
import userServices from '../services/user.services';
import inviteServices from '../services/invite.services';
import rewardPurchaseServices from '../services/reward-purchase.services';
import linkedinProfilesServices from '../services/linkedin-profile.services';
import activityServices from '../services/activity.services';
import rewardServices from '../services/reward.services';
import activityTagServices from '../services/activity-tags.services';
import receiverServices from '../services/receiver.services';
import receiverDetailServices from '../services/receiver-detail.services';
import rewardElementServices from '../services/reward-element.services';
import rewardPostServices from '../services/reward-post.services';
import dashboardServices from '../services/dashboard.services';
import elementTemplateServices from '../services/element-templates.services';
import rewardIssuanceServices from '../services/reward-issuance.services';
import fontServices from '../services/fonts.services';
import zedeidServices from '../services/zedeid.services';
import receiverGroupServices from '../services/receiver-group.services';
import groupServices from '../services/group.services';
import transcriptSubjectServices from '../services/transcript-subject.services';
import activityBatchServices from '../services/activity-batch.services';

export default configureStore({
    reducer: combineReducers({
        modal: modalReducer,
        notify: notifyReducer,
        user: userReducer,
        backdrop: backdropReducer,
        logout: logoutReducer,
        activity: activityReducer,
        element: elementReducer,
        editor: editorReducer,
        editor_options: editorOptionsReducer,
        [authServices.reducerPath]: authServices.reducer,
        [orgServices.reducerPath]: orgServices.reducer,
        [userServices.reducerPath]: userServices.reducer,
        [inviteServices.reducerPath]: inviteServices.reducer,
        [rewardPurchaseServices.reducerPath]: rewardPurchaseServices.reducer,
        [rewardIssuanceServices.reducerPath]: rewardIssuanceServices.reducer,
        [linkedinProfilesServices.reducerPath]: linkedinProfilesServices.reducer,
        [activityServices.reducerPath]: activityServices.reducer,
        [rewardServices.reducerPath]: rewardServices.reducer,
        [activityTagServices.reducerPath]: activityTagServices.reducer,
        [activityBatchServices.reducerPath]: activityBatchServices.reducer,
        [receiverServices.reducerPath]: receiverServices.reducer,
        [receiverDetailServices.reducerPath]: receiverDetailServices.reducer,
        [receiverGroupServices.reducerPath]: receiverGroupServices.reducer,
        [groupServices.reducerPath]: groupServices.reducer,
        [rewardElementServices.reducerPath]: rewardElementServices.reducer,
        [rewardPostServices.reducerPath]: rewardPostServices.reducer,
        [dashboardServices.reducerPath]: dashboardServices.reducer,
        [elementTemplateServices.reducerPath]: elementTemplateServices.reducer,
        [zedeidServices.reducerPath]: zedeidServices.reducer,
        [transcriptSubjectServices.reducerPath]: transcriptSubjectServices.reducer,
        [fontServices.reducerPath]: fontServices.reducer
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authServices.middleware)
            .concat(orgServices.middleware)
            .concat(userServices.middleware)
            .concat(inviteServices.middleware)
            .concat(rewardPurchaseServices.middleware)
            .concat(rewardIssuanceServices.middleware)
            .concat(linkedinProfilesServices.middleware)
            .concat(activityServices.middleware)
            .concat(rewardServices.middleware)
            .concat(activityTagServices.middleware)
            .concat(activityBatchServices.middleware)
            .concat(receiverServices.middleware)
            .concat(receiverDetailServices.middleware)
            .concat(groupServices.middleware)
            .concat(receiverGroupServices.middleware)
            .concat(rewardElementServices.middleware)
            .concat(rewardPostServices.middleware)
            .concat(dashboardServices.middleware)
            .concat(elementTemplateServices.middleware)
            .concat(zedeidServices.middleware)
            .concat(transcriptSubjectServices.middleware)
            .concat(fontServices.middleware)
});
