import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../redux/reducers/modal';
import Icon from '../components/icon';

import VerifyDomain from './containers/organization/verify-domain';
import DeleteOrg from './containers/confirm/org/delete';
import User from './containers/user';
import AddUser from './containers/user/add';
import EditUser from './containers/user/edit';
import RewardPurchase from './containers/reward-purchase';
import DeleteRewardPurchase from './containers/confirm/reward-purchase/delete';
import OrgLinkedinProfile from './containers/organization/linkedin-profile';
import DeleteLinkedinProfile from './containers/confirm/org/delete-linkedin-profile';
import AddActivity from './containers/activity/add';
import InviteRewardee from './containers/receivers/invite-rewardee';
import ManageTags from './containers/activity/tags';
import DeleteReward from './containers/confirm/reward/delete';
import UploadReceivers from './containers/receivers/upload-receivers';
import ManageDetails from './containers/receivers/details';
import Profile from './containers/profile';
import EditProfile from './containers/profile/edit';
import IncompatibleEditor from './containers/editor';
import IconButton from '../components/custom/icon-button';
import SaveTemplate from './containers/element-templates/save';
import DeleteRewardElement from './containers/confirm/reward-element/delete';
import DeleteRewardPost from './containers/confirm/reward-post/delete';
import PreviewRewardElement from './containers/reward-element/preview';
import Skeleton from '../components/custom/skeleton';
import DeleteElementTemplate from './containers/confirm/element-template/delete';
import AddElementTemplate from './containers/element-templates/add';
import DeleteActivity from './containers/confirm/activity/delete';
import GenerateRewards from './containers/activity/generate';
import RewardElements from './containers/reward-element/elements';
import RewardPosts from './containers/reward-post/posts';
import RewardIssuances from './containers/reward-issuances';
import DeleteUser from './containers/confirm/user/delete';
import AddGroup from './containers/receivers/add-group';
import CreateGroup from './containers/receivers/create-group';
import ViewGroup from './containers/receivers/view-group';
import Subjects from './containers/reward-element/subjects';
import CompleteActivity from './containers/confirm/activity/complete';
import AssignActivityBatch from './containers/confirm/activity/assign-batch';
import InterruptPrompt from './containers/confirm/prompt';
import CreateActivityBatch from './containers/confirm/activity-batch/create';
import PreviewRewardQR from './containers/activity/qr';
import OrgAdd from './containers/organization/add';
import GeneratePrivateKey from './containers/organization/generate-private-key';
import PreviewRewardPost from './containers/reward-post/preview';
import SelectOtherInfoTag from './containers/activity/select-tag';
import DeleteOtherInfoTag from './containers/confirm/activity/delete-tag';
import DeleteWallet from './containers/confirm/delete';
import SavePrivateKey from './containers/organization/save-private-key';
import GenerateBackEndRewards from './containers/activity/generate-back-end';

interface DialogTitleProps extends MuiDialogTitleProps {
    children: React.ReactNode;
    onClose: any;
}

const MODAL_CONTAINERS: any = {
    ADD_ORG: {
        modal: OrgAdd,
        props: { title: 'Add Organization' }
    },
    VERIFY_DOMAIN: {
        modal: VerifyDomain,
        props: { title: 'Verify Domain' }
    },
    DELETE_ORG: {
        modal: DeleteOrg,
        props: { title: 'Confirm' }
    },
    USER: {
        modal: User,
        props: { title: 'User Profile' }
    },
    ADD_USER: {
        modal: AddUser,
        props: { title: 'Add User' }
    },
    EDIT_USER: {
        modal: EditUser,
        props: { title: 'Manage User', maxWidth: 'md' }
    },
    DELETE_USER: {
        modal: DeleteUser,
        props: { title: 'Confirm' }
    },
    REWARD_PURCHASE: {
        modal: RewardPurchase,
        props: { title: 'Add Reward Purchase' }
    },
    REWARD_ELEMENTS: {
        modal: RewardElements,
        props: { title: 'Preview Element' }
    },
    REWARD_POSTS: {
        modal: RewardPosts,
        props: { title: 'Preview Posts' }
    },
    DELETE_REWARD_PURCHASE: {
        modal: DeleteRewardPurchase,
        props: { title: 'Confirm' }
    },
    LINKEDIN_PROFILE: {
        modal: OrgLinkedinProfile,
        props: { title: 'Linkedin Profile' }
    },
    DELETE_LINKEDIN_PROFILE: {
        modal: DeleteLinkedinProfile,
        props: { title: 'Confirm' }
    },
    ADD_ACTIVITY: {
        modal: AddActivity,
        props: { title: 'Create Activity' }
    },
    INVITE_REWARDEE: {
        modal: InviteRewardee,
        props: { title: 'Invite Single Receiver' }
    },
    MANAGE_ACTIVITY_TAGS: {
        modal: ManageTags,
        props: { title: 'Activity Tags' }
    },
    DELETE_ACTIVITY: {
        modal: DeleteActivity,
        props: { title: 'Confirm' }
    },
    ADD_RECEIVERS_GROUP: {
        modal: AddGroup,
        props: { title: 'Add to Group', maxWidth: 'md' }
    },
    GROUP_RECEIVERS: {
        modal: ViewGroup,
        props: { title: 'Group Receivers', maxWidth: 'md' }
    },
    CREATE_RECEIVERS_GROUP: {
        modal: CreateGroup,
        props: { title: 'Create New Group' }
    },
    MANAGE_RECEIVER_DETAILS: {
        modal: ManageDetails,
        props: { title: 'Receiver Details' }
    },
    UPLOAD_RECEIVERS: {
        modal: UploadReceivers,
        props: { title: 'Upload Receivers' }
    },
    DELETE_REWARD: {
        modal: DeleteReward,
        props: { title: 'Confirm' }
    },
    DELETE_WALLET: {
        modal: DeleteWallet,
        props: { title: 'Confirm' }
    },
    GENERATE_REWARDS: {
        modal: GenerateRewards,
        props: { title: 'Generate Rewards' }
    },
    GENERATE_BACKEND_REWARDS: {
        modal: GenerateBackEndRewards,
        props: { title: 'Generate & Share Rewards' }
    },
    GENERATE_KEY: {
        modal: GeneratePrivateKey,
        props: { title: 'Generate Private Key' }
    },
    PROFILE: {
        modal: Profile,
        props: { title: 'Profile' }
    },
    EDIT_PROFILE: {
        modal: EditProfile,
        props: { title: 'Edit Profile', maxWidth: 'md' }
    },
    INCOMPATIBLE_EDITOR: {
        modal: IncompatibleEditor,
        props: { title: 'Info' }
    },
    SAVE_TEMPLATE: {
        modal: SaveTemplate,
        props: { title: 'Save As Template' }
    },
    ADD_TEMPLATE: {
        modal: AddElementTemplate,
        props: { title: 'Create Element' }
    },
    DELETE_ELEMENT_TEMPLATE: {
        modal: DeleteElementTemplate,
        props: { title: 'Confirm' }
    },
    PREVIEW_REWARD_ELEMENT: {
        modal: PreviewRewardElement,
        props: { title: <Skeleton width={150} height={20} />, maxWidth: 'md' }
    },
    PREVIEW_REWARD_POST: {
        modal: PreviewRewardPost,
        props: { title: <Skeleton width={150} height={20} />, maxWidth: 'md' }
    },
    DELETE_REWARD_ELEMENT: {
        modal: DeleteRewardElement,
        props: { title: 'Confirm' }
    },
    REWARD_ISSUANCES: {
        modal: RewardIssuances,
        props: { title: 'Reward Invitations', maxWidth: 'lg' }
    },
    TRANSCRIPT_SUBJECTS: {
        modal: Subjects,
        props: { title: 'Transcript Details', maxWidth: 'lg' }
    },
    COMPLETE_ACTIVITY: {
        modal: CompleteActivity,
        props: { title: 'Mark as Complete' }
    },
    ASSIGN_BATCH: {
        modal: AssignActivityBatch,
        props: { title: 'Notification' }
    },
    INTERRUPT_PROMPT: {
        modal: InterruptPrompt,
        props: { title: 'Warning' }
    },
    CREATE_ACTIVITY_BATCH: {
        modal: CreateActivityBatch,
        props: { title: 'Create Activity Batch' }
    },
    PREVIEW_REWARD_QR: {
        modal: PreviewRewardQR,
        props: { title: 'Reward Register & Login QR' }
    },
    DELETE_REWARD_POST: {
        modal: DeleteRewardPost,
        props: { title: 'Confirm' }
    },
    OTHER_INFO_TAG: {
        modal: SelectOtherInfoTag,
        props: { title: 'Activity Tags' }
    },
    DELETE_OTHER_INFO_TAG: {
        modal: DeleteOtherInfoTag,
        props: { title: 'Confirm' }
    },
    SAVE_ORG_KEY: {
        modal: SavePrivateKey,
        props: { title: 'Securely Save Organization Private Key' }
    }
};

export const modalTypes = {
    ADD_ORG: 'ADD_ORG',
    VERIFY_DOMAIN: 'VERIFY_DOMAIN',
    DELETE_ORG: 'DELETE_ORG',
    USER: 'USER',
    ADD_USER: 'ADD_USER',
    EDIT_USER: 'EDIT_USER',
    DELETE_USER: 'DELETE_USER',
    REWARD_PURCHASE_ADD: 'REWARD_PURCHASE',
    REWARD_PURCHASE_EDIT: 'REWARD_PURCHASE',
    REWARD_ELEMENTS: 'REWARD_ELEMENTS',
    REWARD_POSTS: 'REWARD_POSTS',
    DELETE_REWARD_PURCHASE: 'DELETE_REWARD_PURCHASE',
    ADD_LINKEDIN_PROFILE: 'LINKEDIN_PROFILE',
    EDIT_LINKEDIN_PROFILE: 'LINKEDIN_PROFILE',
    DELETE_LINKEDIN_PROFILE: 'DELETE_LINKEDIN_PROFILE',
    ADD_ACTIVITY: 'ADD_ACTIVITY',
    INVITE_REWARDEE: 'INVITE_REWARDEE',
    MANAGE_ACTIVITY_TAGS: 'MANAGE_ACTIVITY_TAGS',
    DELETE_ACTIVITY: 'DELETE_ACTIVITY',
    DELETE_REWARD: 'DELETE_REWARD',
    GENERATE_REWARDS: 'GENERATE_REWARDS',
    UPLOAD_RECEIVERS: 'UPLOAD_RECEIVERS',
    MANAGE_RECEIVER_DETAILS: 'MANAGE_RECEIVER_DETAILS',
    PROFILE: 'PROFILE',
    EDIT_PROFILE: 'EDIT_PROFILE',
    INCOMPATIBLE_EDITOR: 'INCOMPATIBLE_EDITOR',
    SAVE_TEMPLATE: 'SAVE_TEMPLATE',
    ADD_TEMPLATE: 'ADD_TEMPLATE',
    CREATE_TEMPLATE: 'CREATE_TEMPLATE',
    DELETE_ELEMENT_TEMPLATE: 'DELETE_ELEMENT_TEMPLATE',
    DELETE_REWARD_ELEMENT: 'DELETE_REWARD_ELEMENT',
    PREVIEW_REWARD_ELEMENT: 'PREVIEW_REWARD_ELEMENT',
    REWARD_ISSUANCES: 'REWARD_ISSUANCES',
    CREATE_RECEIVERS_GROUP: 'CREATE_RECEIVERS_GROUP',
    ADD_RECEIVERS_GROUP: 'ADD_RECEIVERS_GROUP',
    GROUP_RECEIVERS: 'GROUP_RECEIVERS',
    ADD_RECEIVERS: 'ADD_RECEIVERS',
    TRANSCRIPT_SUBJECTS: 'TRANSCRIPT_SUBJECTS',
    COMPLETE_ACTIVITY: 'COMPLETE_ACTIVITY',
    ASSIGN_BATCH: 'ASSIGN_BATCH',
    INTERRUPT_PROMPT: 'INTERRUPT_PROMPT',
    CREATE_ACTIVITY_BATCH: 'CREATE_ACTIVITY_BATCH',
    PREVIEW_REWARD_QR: 'PREVIEW_REWARD_QR',
    GENERATE_KEY: 'GENERATE_KEY',
    DELETE_REWARD_POST: 'DELETE_REWARD_POST',
    PREVIEW_REWARD_POST: 'PREVIEW_REWARD_POST',
    OTHER_INFO_TAG: 'OTHER_INFO_TAG',
    DELETE_OTHER_INFO_TAG: 'DELETE_OTHER_INFO_TAG',
    DELETE_WALLET: 'DELETE_WALLET',
    SAVE_ORG_KEY: 'SAVE_ORG_KEY',
    GENERATE_BACKEND_REWARDS: 'GENERATE_BACKEND_REWARDS'
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(3)
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }: DialogTitleProps) => {
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose} className="popup-close-icon">
                    <Icon className="icon w-500">close</Icon>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function ModalContainer() {
    const { modalTypes, modalProps } = useSelector((state: any) => state.modal);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(modalActions.hide());
    };

    if (modalTypes.length === 0) return null;

    return modalTypes.map((type: string, idx: number) => {
        const Container: any = MODAL_CONTAINERS[type];
        const props: any = modalProps[type];
        const containerProps = Container?.props;

        let options: any = {
            maxWidth: 'sm'
        };

        if (containerProps) {
            options = {
                ...options,
                ...containerProps
            };
        }

        if (props) {
            options = {
                ...options,
                ...props
            };
        }

        return (
            <div key={`popup-${idx}`} className="popup-modal-wrap">
                <BootstrapDialog
                    className="popup-modal"
                    fullWidth={true}
                    maxWidth={options?.maxWidth}
                    open={true}>
                    <BootstrapDialogTitle
                        className="popup-modal-title"
                        id="customized-dialog-title"
                        onClose={onClose}>
                        {options?.title}
                    </BootstrapDialogTitle>
                    <DialogContent className="popup-modal-content">
                        <Container.modal />
                    </DialogContent>
                </BootstrapDialog>
            </div>
        );
    });
}
