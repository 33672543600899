import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    createLinkedinProfileProps,
    updateLinkedinProfileProps
} from './interfaces/linkedin-profiles.interface';

const linkedinProfilesServices = createApi({
    reducerPath: 'linkedin-profiles-service',
    baseQuery: baseQuery,
    tagTypes: ['LinkedinProfiles', 'LinkedinProfile', 'LinkedinProfileOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ org_id, user_id, ...props }: createLinkedinProfileProps) => ({
                method: 'POST',
                url: `/linkedin-profiles/${org_id}/create/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'LinkedinProfiles' }, { type: 'LinkedinProfileOptions' }]
        }),
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/linkedin-profiles/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'LinkedinProfile' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/linkedin-profiles/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'LinkedinProfiles' }, { type: 'LinkedinProfileOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/linkedin-profiles/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'LinkedinProfileOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, user_id, ...props }: updateLinkedinProfileProps) => ({
                method: 'PATCH',
                url: `/linkedin-profiles/${org_id}/update/${user_id}`,
                body: props
            }),
            invalidatesTags: [
                { type: 'LinkedinProfiles' },
                { type: 'LinkedinProfileOptions' },
                { type: 'LinkedinProfile' }
            ]
        }),
        delete: builder.mutation({
            query: ({ org_id, user_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/linkedin-profiles/${org_id}/delete/${user_id}`,
                body: props
            }),
            invalidatesTags: [
                { type: 'LinkedinProfiles' },
                { type: 'LinkedinProfileOptions' },
                { type: 'LinkedinProfile' }
            ]
        })
    })
});

export default linkedinProfilesServices;
