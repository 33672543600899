// @ts-ignore
import { eddsa as EdDSA } from 'elliptic';

export default class Ed25519 {
    private ed: any;

    constructor() {
        this.ed = new EdDSA('ed25519');
    }

    /**
     * sign message with private key
     *
     * @param message - Buffer | Uint8Array
     * @param privateKey - Buffer | Uint8Array
     *
     * @returns - Signed buffer
     */
    sign(message: Buffer | Uint8Array, privateKey: Buffer | Uint8Array) {
        const pvt = this.ed.keyFromSecret(privateKey);
        return pvt.sign(message);
    }

    /**
     * verify message with public key
     *
     * @param signature - Buffer | Uint8Array | Hex
     * @param message - Buffer | Uint8Array
     * @param publicKey - Hex string
     *
     * @returns - boolean
     */
    verify(
        signature: Buffer | Uint8Array | string,
        message: Buffer | Uint8Array,
        publicKey: string
    ) {
        const pub = this.ed.keyFromPublic(publicKey, 'hex');
        return pub.verify(message, signature);
    }
}
