import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import TextField from '../../../components/form/text-field';
import { DialogActions } from '@mui/material';
import userUtils from '../../../utils/users';
import { modalActions } from '../../../redux/reducers/modal';
import { useDispatch, useSelector } from 'react-redux';
import { notifyActions } from '../../../redux/reducers/notify';
import userServices from '../../../services/user.services';
import { modalTypes } from '../..';

const initialPasswordValues = {
    old_password: '',
    password: '',
    confirm_password: ''
};

const validationSchema = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    username: Yup.string().required('Username is required'),
    role: Yup.string().required('Role is required'),
    state: Yup.string().required('Status is required'),
    email: Yup.string().email().required('Email is required'),
    phone: Yup.string()
});

const passwordValidationSchema = Yup.object().shape({
    old_password: Yup.string().required('Current password is required'),
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required')
});

export default function EditUser() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);
    const { modalProps } = useSelector((state: any) => state.modal);
    const { data: user } = modalProps[modalTypes.EDIT_USER];

    const formikRef: any = React.useRef();
    const formikPasswordRef: any = React.useRef();
    const [image, setImage] = React.useState('');

    /* mutations */
    const [
        update,
        { isLoading: isUserUpdating, error: userUpdateError, isError: isUserUpdateError }
    ]: any = userServices.useUpdateByAdminMutation();
    const [
        changePassword,
        {
            isLoading: isUserPasswordUpdating,
            error: userPasswordUpdateError,
            isError: isUserPasswordUpdateError
        }
    ]: any = userServices.useUpdatePasswordByAdminMutation();

    /* error handling */
    React.useEffect(() => {
        if (isUserUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: userUpdateError.data?.message
                })
            );
        }
        if (isUserPasswordUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: userPasswordUpdateError.data?.message
                })
            );
        }
    }, [userUpdateError, isUserUpdateError, userPasswordUpdateError, isUserPasswordUpdateError]);

    const data = React.useMemo(() => {
        if (user?.avatar) setImage(user.avatar);

        return {
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            username: user.username || '',
            state: user.state || '',
            email: user.email || '',
            role: user.role || '',
            phone: user.phone || ''
        };
    }, [user]);

    const onSubmitClick = () => {
        formikRef.current.handleSubmit();
    };

    const onImage = (event: any, image: string) => {
        setImage(image);
    };

    const onSubmit = async (values: any) => {
        const result: any = await update({
            id: user?.id,
            role: values.role,
            user_id: authUser.id,
            org_id: authUser.organization_id,
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            avatar: image
        });

        if (result?.data?.status === 'success') {
            dispatch(userServices.util.invalidateTags(['Users', 'UsersOptions']));

            dispatch(modalActions.hide());

            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(
                modalActions.show({
                    type: modalTypes.USER,
                    props: { data: user?.data }
                })
            );
        }
    };

    const onSubmitPassword = async (values: any) => {
        const result: any = await changePassword({
            id: user?.id,
            user_id: authUser.id,
            org_id: authUser.organization_id,
            password: values.password,
            confirm_password: values.confirm_password,
            old_password: values.old_password
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            if (formikPasswordRef?.current) formikPasswordRef.current.resetForm();
        }
    };

    return (
        <div className="modal-container user-edit-modal">
            <div className="modal-content">
                <div className="sections">
                    <div className="section">
                        <Form
                            innerRef={formikRef}
                            initialValues={data}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            className="form">
                            <div className="double-wrap">
                                <TextField
                                    name="first_name"
                                    placeholder="First Name"
                                    label="First Name"
                                />
                                <TextField
                                    name="last_name"
                                    placeholder="Last Name"
                                    label="Last Name"
                                />
                            </div>
                            <TextField
                                name="username"
                                placeholder="Username"
                                label="Username"
                                disabled
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                name="state"
                                placeholder="Status"
                                label="Status"
                                InputProps={{ readOnly: true }}
                                disabled
                            />
                            <TextField
                                name="email"
                                type="email"
                                placeholder="Email"
                                label="Email"
                                required
                                InputProps={{ readOnly: true }}
                                disabled
                            />
                            <TextField name="phone" placeholder="Phone" label="Phone" />
                        </Form>
                    </div>

                    <div className="section password">
                        <h5 className="title">Change Password</h5>
                        <Form
                            innerRef={formikPasswordRef}
                            initialValues={initialPasswordValues}
                            validationSchema={passwordValidationSchema}
                            onSubmit={onSubmitPassword}
                            className="form">
                            <TextField
                                name="old_password"
                                type="password"
                                placeholder="Current Password"
                                label="Current Password"
                                required
                            />
                            <TextField
                                name="password"
                                type="password"
                                placeholder="Password"
                                label="Password"
                                required
                            />
                            <TextField
                                name="confirm_password"
                                type="password"
                                placeholder="Confirm Password"
                                label="Confirm Password"
                                required
                            />

                            <SubmitButton
                                disabled={isUserUpdating}
                                loading={isUserPasswordUpdating}
                                variant="contained">
                                Change Password
                            </SubmitButton>
                        </Form>
                    </div>
                </div>
            </div>

            <DialogActions className="modal-buttons">
                <Button
                    disabled={isUserUpdating || isUserPasswordUpdating}
                    onClick={() => dispatch(modalActions.hide())}>
                    Cancel
                </Button>
                <Button
                    disabled={isUserPasswordUpdating}
                    loading={isUserUpdating}
                    variant="contained"
                    onClick={onSubmitClick}>
                    Save
                </Button>
            </DialogActions>
        </div>
    );
}
