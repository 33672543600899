import React from 'react';
import Button from '../../../../components/custom/button';
import TextField from '../../../../components/custom/text-field';
import formatImage from '../../../../functions/format-image';
import ColorPicker from '../../../components/color-picker';
import Common from './common';

interface Props {
    canvas: any;
}

export default function Image({ canvas }: Props) {
    const [data, setData] = React.useState({
        opacity: 100,
        backgroundColor: '#ffffff'
    });

    React.useEffect(() => {
        if (canvas) {
            const objects = canvas.getActiveObjects();
            let temp: any = {};

            /* get common data from all objects */
            for (const obj of objects) {
                obj['image'] = obj.src ? obj.getSrc() : '';

                for (const key in obj) {
                    if (Object.hasOwnProperty.call(obj, key)) {
                        const element = obj[key];

                        if (temp[key] && temp[key] !== element) {
                            delete temp[key];
                        }

                        if (!temp[key]) {
                            temp[key] = element;
                        }
                    }
                }
            }

            setData(temp);
        }
    }, [canvas]);

    const update = ({ type, value }: { type: string; value: any }) => {
        const objects = canvas.getActiveObjects();

        for (const obj of objects) {
            if (type === 'image') {
                obj.setSrc(value, () => {
                    canvas.requestRenderAll();
                });
            } else {
                obj.set({ [type]: value });
                setData({ ...data, [type]: value });
                canvas.requestRenderAll();
            }
        }
    };

    return (
        <div className="editor-settings">
            <Common canvas={canvas} />

            <ColorPicker
                opacity={data?.opacity}
                color={data?.backgroundColor}
                setColor={(color: string) => update({ type: 'backgroundColor', value: color })}
                setOpacity={(opacity: number) => update({ type: 'opacity', value: opacity })}
            />
        </div>
    );
}
