import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import {
    acceptUserProps,
    getRewardLinksProps,
    inviteReceiversProps
} from './interfaces/invite.interface';

const inviteServices = createApi({
    reducerPath: 'invite-service',
    baseQuery: baseQuery,
    tagTypes: ['Invite'],
    endpoints: (builder) => ({
        inviteRewardees: builder.mutation({
            query: ({ org_id, user_id, ...props }: inviteReceiversProps) => ({
                method: 'POST',
                url: `/invitations/${org_id}/activity/${user_id}`,
                body: props
            })
        }),
        acceptUser: builder.mutation({
            query: ({ token, password }: acceptUserProps) => ({
                method: 'PATCH',
                url: `/invitations/accept/user`,
                body: { token, password }
            })
        }),
        getRewardLinks: builder.query({
            query: ({ org_id, ...props }: getRewardLinksProps) => ({
                method: 'POST',
                url: `/invitations/${org_id}/get-links`,
                body: props
            })
        })
    })
});

export default inviteServices;
