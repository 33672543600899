import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../../../components/custom/icon-button';
import Icon from '../../../components/icon';
import { editorOptionsActions } from '../../../redux/reducers/editor/options';
import { elementActions } from '../../../redux/reducers/element';
import Settings from './settings';

interface Props {
    canvas?: any;
}

const Titles = {
    general: 'General',
    tag: 'Tag',
    image: 'Image',
    title: 'Text',
    shapes: 'Shape',
    attributes: 'Custom Attributes'
};

const Shapes = ['rect', 'circle', 'line'];

export default function RightBar({ canvas }: Props) {
    const dispatch = useDispatch();
    const { attributes } = useSelector((state: any) => state.element);
    const { selected, options } = useSelector((state: any) => state.editor_options);
    const [setting, setSetting] = React.useState('');

    React.useEffect(() => {
        let value: string;

        switch (options?.selected_obj) {
            case 'rect':
            case 'circle':
            case 'line':
                value = 'shapes';
                break;
            case 'textbox':
                value = 'title';
                break;
            case 'text':
                value = 'tag';
                break;
            case 'image':
                value = 'image';
                break;

            default:
                value = '';
                break;
        }

        if (value) setSelected(value);
    }, [options]);

    React.useEffect(() => {
        if (!options?.selected_obj && selected !== 'general' && selected !== 'attributes') {
            setSelected('general');
        }
    }, [selected, options]);

    /* functions */
    const onAddAttribute = () => {
        dispatch(elementActions.set({ attributes: [...attributes, { name: '', value: '' }] }));
    };

    const setSelected = (value: string) => {
        if (value === 'general' || value === 'attributes') canvas.discardActiveObject().renderAll();

        dispatch(editorOptionsActions.set({ selected: value }));
    };

    return (
        <div className="right-bar">
            <div className="menu">
                <IconButton
                    tooltip={{ title: 'General settings', placement: 'left' }}
                    onClick={() => setSelected('general')}
                    data-active={selected === 'general'}>
                    <Icon className="icon w-600">tune</Icon>
                </IconButton>
                <IconButton
                    tooltip={{ title: 'Custom attributes', placement: 'left' }}
                    onClick={() => setSelected('attributes')}
                    data-active={selected === 'attributes'}>
                    <Icon variant="outlined" className="icon w-600">
                        sell
                    </Icon>
                </IconButton>

                <div data-active={Boolean(options?.selected_obj)} className="item">
                    <IconButton
                        data-display={options?.selected_obj === 'textbox'}
                        tooltip={{ title: 'Text settings', placement: 'left' }}
                        onClick={() => setSelected('title')}
                        data-active={selected === 'title'}>
                        <Icon className="icon w-600">title</Icon>
                    </IconButton>
                    <IconButton
                        data-display={options?.selected_obj === 'image'}
                        tooltip={{ title: 'Image settings', placement: 'left' }}
                        onClick={() => setSelected('image')}
                        data-active={selected === 'image'}>
                        <Icon className="icon w-600">image</Icon>
                    </IconButton>
                    <IconButton
                        data-display={Shapes.includes(options?.selected_obj)}
                        tooltip={{ title: 'Shape settings', placement: 'left' }}
                        onClick={() => setSelected('shapes')}
                        data-active={selected === 'shapes'}>
                        <Icon className="icon w-600">shapes</Icon>
                    </IconButton>
                    <IconButton
                        data-display={options?.selected_obj === 'text'}
                        tooltip={{ title: 'Tag settings', placement: 'left' }}
                        onClick={() => setSelected('tag')}
                        data-active={selected === 'tag'}>
                        <Icon className="icon w-600">data_object</Icon>
                    </IconButton>
                </div>
            </div>

            <div className="content">
                <div className="title-wrap">
                    <p className="title">{Titles[selected]}</p>

                    {selected === 'attributes' && (
                        <IconButton
                            tooltip={{ title: 'Add attribute', placement: 'bottom' }}
                            onClick={onAddAttribute}>
                            <Icon className="icon w-600">add</Icon>
                        </IconButton>
                    )}
                </div>

                <Settings canvas={canvas} selected={selected} />
            </div>
        </div>
    );
}
