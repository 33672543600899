import * as Yup from 'yup';

interface ValidateReceiverProps {
    name: string;
    email: string;
    [x: string]: any;
}

const receiverValidationSchema = Yup.object().shape({
    name: Yup.string().required('Receiver name is required'),
    email: Yup.string()
        .matches(
            /^\S+@\S+\.\S+$/,
            'Invalid email. Email must not contain any spaces and should be lowercase'
        )
        .lowercase()
        .required('Receiver email is required'),
    details: Yup.object()
});

const receiverUtils = {
    validate: (data: ValidateReceiverProps) => {
        return new Promise((resolve) => {
            receiverValidationSchema
                .validate(data)
                .then((result) => {
                    resolve(true);
                })
                .catch((error: any) => {
                    resolve({ error: error.message });
                });
        });
    }
};

export default receiverUtils;
