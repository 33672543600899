import React from 'react';
import TextField from '../../components/custom/text-field';

interface Props {
    title?: string;
    opacity?: any;
    color?: any;
    setColor?: any;
    setOpacity?: any;
}

export default function ColorPicker({
    title = 'Fill',
    color,
    opacity,
    setColor,
    setOpacity
}: Props) {
    const colorRef: any = React.useRef();
    const [values, setValues] = React.useState({
        color: 'ffffff',
        opacity: 100
    });
    const [props, setProps] = React.useState({
        error: false,
        helperText: ''
    });

    React.useEffect(() => {
        let values = {
            color: 'ffffff',
            opacity: 100
        };

        if (color) values.color = color.replace('#', '');
        if (opacity) values.opacity = Number(opacity) * 100;

        setValues(values);
    }, [color, opacity]);

    /* functions */
    const onColor = (value: string) => {
        setValues({ ...values, color: value });

        value = `#${value}`;
        if (isHexColor(value)) {
            setProps({ error: false, helperText: '' });
            setColor(value);
        } else {
            setProps({ error: true, helperText: 'Invalid hex color code' });
        }
    };
    const onOpacity = (value: string | null) => {
        setValues({ ...values, opacity: Number(value) });
        setOpacity(Number(value) * 0.01);
    };

    /* utils */
    const isHexColor = (hex: any) => {
        let color = '';
        let result = true;
        let e: any = document.createElement('div');
        e.style.borderColor = '';
        e.style.borderColor = hex;
        color = e.style.borderColor;
        if (color.length == 0) {
            result = false;
        }
        e.style.borderColor = '';
        return result;
    };

    return (
        <div className="color-picker">
            <p className="title">{title}</p>

            <div className="inputs">
                <div className="color" style={{ backgroundColor: `#${values?.color}` }}></div>
                <TextField
                    InputProps={{
                        startAdornment: '#'
                    }}
                    error={props?.error}
                    helperText={props?.helperText}
                    value={values.color}
                    inputRef={colorRef}
                    onChange={(e) => onColor(e.target.value)}
                />
                <TextField
                    value={values.opacity}
                    className="opacity"
                    inputRef={colorRef}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    onChange={(e) => onOpacity(e.target.value)}
                />
            </div>
        </div>
    );
}
