import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import Form from '../components/form';
import SubmitButton from '../components/form/submit-button';
import TextField from '../components/form/text-field';
import { notifyActions } from '../redux/reducers/notify';
import userServices from '../services/user.services';

const initialValues = {
    password: '',
    confirm_password: ''
};

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required')
});

export default function ResetPassword() {
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const [searchParams] = useSearchParams();

    const [
        resetPassword,
        {
            error: resetPasswordError,
            isError: isResetPasswordError,
            isLoading: isResetPasswordLoading
        }
    ]: any = userServices.useResetPasswordMutation();

    /* error handling */
    React.useEffect(() => {
        if (isResetPasswordError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: resetPasswordError.data?.message
                })
            );
        }
    }, [resetPasswordError, isResetPasswordError]);

    const onSubmit = async (values: any) => {
        const token = searchParams.get('token');

        const result = await resetPassword({
            token: token,
            password: values.password,
            confirm_password: values.confirm_password
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            setTimeout(() => {
                navigator('/');
            }, 300);
        }
    };

    return (
        <div className="main-page">
            <div className="card hs">
                <div className="left">
                    <img src="/logo.svg" className="logo" alt="Logo" />
                </div>
                <div className="right">
                    <div className="titles">
                        <span className="title">Reset password</span>
                        <span className="desc txt-theme">Enter your new password.</span>
                    </div>
                    <Form
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        className="form">
                        <TextField
                            size="medium"
                            type="password"
                            name="password"
                            placeholder="Password"
                            label="Password"
                        />
                        <TextField
                            size="medium"
                            type="password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            label="Confirm Password"
                        />

                        <SubmitButton
                            loading={isResetPasswordLoading}
                            size="large"
                            variant="contained">
                            Reset Password
                        </SubmitButton>
                    </Form>
                </div>
            </div>
        </div>
    );
}
