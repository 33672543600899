import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import TextField from '../../../components/form/text-field';
import { DialogActions, Collapse } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { notifyActions } from '../../../redux/reducers/notify';
import Checkbox from '../../../components/form/checkbox';
import List from '../../../components/custom/list';
import linkedinProfilesServices from '../../../services/linkedin-profile.services';
import SearchSelect from '../../../components/form/search-select';
import activityServices from '../../../services/activity.services';
import { useNavigate } from 'react-router-dom';
import { modalTypes } from '../..';
import orgServices from '../../../services/org.services';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Activity name is required'),
    event_name: Yup.string().required('Event name is required'),
    other_info: Yup.string(),
    issued_by: Yup.string().required('Issued By is required'),
    linkedin_profile_id: Yup.number().integer(),
    batch_size: Yup.number().integer().required('Batch size is required'),
    show_date: Yup.boolean(),
    start_date: Yup.string().when('show_date', {
        is: true,
        then: Yup.string().required('Start date is required')
    }),
    end_date: Yup.string().when('show_date', {
        is: true,
        then: Yup.string().required('End date is required')
    })
});

export default function AddActivity() {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);
    const { modalProps } = useSelector((state: any) => state.modal);
    const props: any = modalProps[modalTypes.ADD_ACTIVITY];

    const [showDates, setShowDates] = React.useState(false);

    /* query */
    const {
        data: organization,
        error: organizationError,
        isError: isOrganizationError,
        isLoading: isOrganizationLoading
    }: any = orgServices.useGetQuery(
        {
            org_id: authUser?.organization_id,
            type: 'id',
            value: authUser?.organization_id
        },
        { skip: !authUser?.organization_id || authUser?.role === 'SUPER_ADMIN' }
    );

    const {
        data: linkedinProfiles,
        error: linkedinProfilesError,
        isError: isLinkedinProfilesError
    }: any = linkedinProfilesServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            all: true
        },
        { skip: !authUser?.organization_id }
    );

    /* mutation */
    const [
        create,
        { error: activityCreateError, isError: isActivityCreateError, isLoading: isCreating }
    ]: any = activityServices.useCreateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isActivityCreateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityCreateError?.data?.message
                })
            );
        }
        if (isLinkedinProfilesError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: linkedinProfilesError?.data?.message
                })
            );
        }
        if (isOrganizationError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: organizationError.data?.message
                })
            );
        }
    }, [
        activityCreateError,
        isActivityCreateError,
        linkedinProfilesError,
        isLinkedinProfilesError,
        organizationError,
        isOrganizationError
    ]);

    /* data */
    const linkedinProfilesData = React.useMemo(() => {
        let data = [];

        if (Array.isArray(linkedinProfiles?.data)) {
            data = linkedinProfiles?.data.map((item: any) => {
                return { value: item.id.toString(), label: item.name };
            });
        }

        return data;
    }, [linkedinProfiles]);

    const initialValues = React.useMemo(() => {
        const template = {
            name: '',
            event_name: '',
            other_info: '',
            issued_by: '',
            linkedin_profile_id: '',
            show_date: false,
            start_date: '',
            end_date: '',
            batch_size: 1000
        };

        if (organization?.data?.batch_size) template.batch_size = organization.data.batch_size;

        return template;
    }, [organization]);

    const onSubmit = async (values: any) => {
        const result = await create({
            name: values.name,
            event_name: values.event_name,
            other_info: values.other_info,
            issued_by: values.issued_by,
            linkedin_profile_id: values.linkedin_profile_id || null,
            start_date: values.show_date ? values.start_date : '',
            end_date: values.show_date ? values.end_date : '',
            org_id: authUser.organization_id,
            template_id: props.template_id,
            user_id: authUser.id,
            batch_size: values.batch_size
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            navigator('/activities/edit', { state: result?.data?.data });
            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container activity-modal">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <TextField
                        name="name"
                        label="Activity Name"
                        placeholder="Activity Name"
                        required
                    />

                    <TextField
                        name="event_name"
                        label="Event Name"
                        placeholder="Event Name"
                        required
                    />

                    <TextField name="other_info" label="Other Info" placeholder="Other Info" />

                    <TextField
                        name="issued_by"
                        label="Issued By Info"
                        placeholder="Issued By Info"
                        required
                    />

                    <TextField
                        name="batch_size"
                        label="Activity Batch Size"
                        placeholder="Activity Batch Size"
                        required
                    />

                    <SearchSelect
                        name="linkedin_profile_id"
                        placeholder=""
                        label="Select LinkedIn in profile"
                        data={linkedinProfilesData}
                        required
                    />

                    <div className="dates">
                        <Checkbox
                            label="Specify Start and End Date"
                            name="show_date"
                            onChange={(e: any) => setShowDates(e.target.checked)}
                        />

                        <List className="list-wrap">
                            {showDates && (
                                <Collapse>
                                    <div className="double-wrap">
                                        <TextField
                                            name="start_date"
                                            type="date"
                                            placeholder="Start Date"
                                            label="Start Date"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        <TextField
                                            name="end_date"
                                            type="date"
                                            placeholder="End Date"
                                            label="End Date"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                </Collapse>
                            )}
                        </List>
                    </div>
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isCreating} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isCreating} variant="contained">
                        Create Activity
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
