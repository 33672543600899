import { Ed25519VerificationKey2018 } from '@transmute/ed25519-signature-2018';

export const getVerificationMethod = async (seed: string) => {
    const k = await Ed25519VerificationKey2018.generate({
        secureRandom: () => {
            return Buffer.from(seed, 'hex');
        }
    });

    let jwk = await k.export({
        privateKey: true,
        type: 'Ed25519VerificationKey2018'
    });

    return jwk;
};
