import { TablePaginationProps } from '@mui/material';
import React from 'react';
import ReactSelect from 'react-select';
import Icon from '../icon';
import IconButton from '../custom/icon-button';

interface RowsPerPageOptionsProps {
    value: any;
    label: any;
}

interface Props {
    rowsPerPageOptions?: RowsPerPageOptionsProps[];
    rowsPerPage: number;
    count: number;
    page: number;
    onPageChange: any;
    onRowsPerPageChange: any;
    className?: string;
}

export default function TablePagination({
    className,
    rowsPerPage,
    count,
    page,
    onPageChange,
    onRowsPerPageChange,
    rowsPerPageOptions = [
        {
            label: '10',
            value: 10
        },
        {
            label: '50',
            value: 50
        },
        {
            label: '100',
            value: 100
        },
        {
            label: 'All',
            value: count
        }
    ]
}: Props) {
    const selectRef: any = React.useRef();

    const onRowsChange = (e: any) => {
        if (onRowsPerPageChange) onRowsPerPageChange(e);
    };

    const onBefore = (e) => {
        onPageChange(e, page - 1 < 0 ? 0 : page - 1);
    };
    const onNext = (e) => {
        const nextPage = page + 1;
        const pages = count / rowsPerPage - 1;

        onPageChange(e, nextPage > pages ? pages : nextPage);
    };

    return (
        <div className={`table-pagination ${className ? className : ''}`}>
            <div className="rows-per">
                <div className="text">Rows per page: </div>
                <select
                    ref={selectRef}
                    className="drop-down"
                    value={rowsPerPage}
                    onChange={onRowsChange}>
                    {rowsPerPageOptions.map((_) => {
                        return (
                            <option
                                className="option"
                                key={`pagination-row-${_.value}`}
                                value={_.value}>
                                {_.label}
                            </option>
                        );
                    })}
                </select>
            </div>

            <div className="count-wrap">
                {count < page * rowsPerPage + 1 ? count : page * rowsPerPage + 1}{' '}
                <span className="line"></span>{' '}
                {count < (page + 1) * rowsPerPage ? count : (page + 1) * rowsPerPage} of {count}
            </div>

            <div className="icons">
                <IconButton onClick={onBefore} disabled={page <= 0}>
                    <Icon className="icon w-700 before">navigate_before</Icon>
                </IconButton>
                <IconButton onClick={onNext} disabled={count - (page + 1) * rowsPerPage <= 0}>
                    <Icon className="icon w-700 next">navigate_next</Icon>
                </IconButton>
            </div>
        </div>
    );
}
