import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import {
    deleteProps,
    getAllQueryProps,
    getOptionsQueryProps,
    getProps
} from './interfaces/common.interface';
import {
    createOrgProps,
    updateOrgProps,
    generateTxTProps,
    verifyProps,
    updateOrgKeyProps
} from './interfaces/org.interface';

const orgServices = createApi({
    reducerPath: 'org-service',
    baseQuery: baseQuery,
    tagTypes: ['Orgs', 'Org', 'OrgOptions', 'OrgUsers', 'OrgUsersOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ user_id, ...props }: createOrgProps) => ({
                method: 'POST',
                url: `/organizations/create/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'Orgs' }, { type: 'OrgOptions' }]
        }),
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/organizations/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'Org' }]
        }),
        getAll: builder.query({
            query: ({ page, find, limit }: getAllQueryProps) => ({
                method: 'GET',
                url: `/organizations/all/${page}`,
                params: {
                    ...find,
                    limit: limit
                }
            }),
            providesTags: [{ type: 'Orgs' }]
        }),
        options: builder.query({
            query: ({ find }: getOptionsQueryProps) => ({
                method: 'GET',
                url: `/organizations/options`,
                params: find
            }),
            providesTags: [{ type: 'OrgOptions' }]
        }),
        getAllUsers: builder.query({
            query: ({ page, find, limit, org_id }: getAllQueryProps) => ({
                method: 'GET',
                url: `/organizations/${org_id}/users/${page}`,
                params: {
                    ...find,
                    limit: limit
                }
            }),
            providesTags: [{ type: 'OrgUsers' }]
        }),
        allUsersOptions: builder.query({
            query: ({ find, org_id }: getOptionsQueryProps) => ({
                method: 'GET',
                url: `/organizations/${org_id}/users/options`,
                params: find
            }),
            providesTags: [{ type: 'OrgUsersOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, user_id, ...props }: updateOrgProps) => ({
                method: 'PATCH',
                url: `/organizations/${org_id}/update/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'Orgs' }, { type: 'OrgOptions' }, { type: 'Org' }]
        }),
        updateKey: builder.mutation({
            query: ({ org_id, ...props }: updateOrgKeyProps) => ({
                method: 'PATCH',
                url: `/organizations/${org_id}/update/key`,
                body: props
            }),
            invalidatesTags: [{ type: 'Orgs' }, { type: 'OrgOptions' }, { type: 'Org' }]
        }),
        generateTxT: builder.mutation({
            query: ({ org_id, user_id, ...props }: generateTxTProps) => ({
                method: 'POST',
                url: `/organizations/${org_id}/generate-txt/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'Orgs' }, { type: 'OrgOptions' }, { type: 'Org' }]
        }),
        verify: builder.mutation({
            query: ({ org_id, user_id, ...props }: verifyProps) => ({
                method: 'POST',
                url: `/organizations/${org_id}/verify/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'Orgs' }, { type: 'OrgOptions' }, { type: 'Org' }]
        }),
        delete: builder.mutation({
            query: ({ user_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/organizations/delete/${user_id}`,
                body: { user_id, ...props }
            }),
            invalidatesTags: [{ type: 'Orgs' }, { type: 'OrgOptions' }, { type: 'Org' }]
        })
    })
});

export default orgServices;
