import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';

const zedeidServices = createApi({
    reducerPath: 'zedeid-service',
    baseQuery: baseQuery,
    tagTypes: ['Issuer'],
    endpoints: (builder) => ({
        doc: builder.query({
            query: ({ key }: { key: string }) => ({
                method: 'POST',
                url: `/zedeid/doc`,
                body: {
                    key: key
                }
            }),
            providesTags: [{ type: 'Issuer' }]
        })
    })
});

export default zedeidServices;
