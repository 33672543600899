import React from 'react';
import Button from '../../../components/custom/button';
import * as Yup from 'yup';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { modalTypes } from '../..';
import Form from '../../../components/form';
import TextField from '../../../components/form/text-field';
import SubmitButton from '../../../components/form/submit-button';
import { notifyActions } from '../../../redux/reducers/notify';
import axios from 'axios';
import config from '../../../config/config';
import { getVerificationMethod } from '../../../functions/wallet';

const initialValues = {
    key: ''
};

const validationSchema = Yup.object().shape({
    key: Yup.string().required('Key is required')
});

export default function GenerateRewards() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const onSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            const verificationMethod = await getVerificationMethod(values?.key);
            const issuerDID = verificationMethod.id.split('#')[0];

            const result = await axios.get(`${config.server_urls.zedeid_api}did/${issuerDID}`);

            if (result?.status !== 200) {
                return dispatch(
                    notifyActions.open({
                        type: 'error',
                        message: result.data?.message
                    })
                );
            }

            if (result?.data) {
                dispatch(
                    modalActions.update({
                        type: modalTypes.GENERATE_REWARDS,
                        props: {
                            did_document: result?.data?.didDocument,
                            issuer_key: values?.key
                        }
                    })
                );
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="modal-container">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    Please enter private key
                    <TextField
                        type="password"
                        autoComplete="off"
                        name="key"
                        placeholder="Enter Key"
                        label="Key"
                        required
                    />
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isLoading} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isLoading} variant="contained">
                        Generate
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
