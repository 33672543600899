import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalTypes } from '../..';
import { notifyActions } from '../../../redux/reducers/notify';
import inviteServices from '../../../services/invite.services';
import qrcode from 'qrcode';
import Tabs from '../../../components/tabs';
import TextField from '../../../components/custom/text-field';
import Icon from '../../../components/icon';
import IconButton from '../../../components/custom/icon-button';
import { Tooltip } from '@mui/material';

export default function PreviewRewardQR() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);
    const { modalProps } = useSelector((state: any) => state.modal);
    const props: any = modalProps[modalTypes.PREVIEW_REWARD_QR];

    const [isLoading, setIsLoading] = React.useState(false);
    const [qrCodes, setQrCodes] = React.useState({
        register: '',
        login: ''
    });
    const [tab, setTab] = React.useState(0);

    /* query */
    const {
        data: rewardLinks,
        error: rewardLinksError,
        isError: isRewardLinkError,
        isLoading: isRewardLinkLoading
    }: any = inviteServices.useGetRewardLinksQuery(
        {
            org_id: authUser?.organization_id,
            activity_id: props?.activity,
            reward_id: props?.reward
        },
        { skip: !authUser?.organization_id || !props?.activity || !props.reward }
    );

    /* error handling */
    React.useEffect(() => {
        if (isRewardLinkError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: rewardLinksError.data?.message
                })
            );
        }
    }, [rewardLinksError, isRewardLinkError]);

    /* data handling */
    React.useEffect(() => {
        if (rewardLinks?.data) {
            generateQRCodes(rewardLinks?.data);
        }
    }, [rewardLinks]);

    const generateQRCodes = async (links: any) => {
        let register = '';
        let login = '';

        if (links.register) {
            register = await qrcode.toDataURL(links.register);
        }
        if (links.login) {
            login = await qrcode.toDataURL(links.login);
        }

        setQrCodes({ register, login });
    };

    const CopyTextField = ({ link }) => {
        const [open, setOpen] = React.useState(false);

        const onCopy = () => {
            if (link) {
                navigator.clipboard.writeText(link);
                setOpen(true);
            }
        };

        return (
            <TextField
                value={link}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <Tooltip onClose={() => setOpen(false)} open={open} title="Copied!">
                            <IconButton>
                                <Icon className="icon" onClick={onCopy}>
                                    content_copy
                                </Icon>
                            </IconButton>
                        </Tooltip>
                    )
                }}
            />
        );
    };

    const tabsData = React.useMemo(() => {
        const template: any = [];

        if (qrCodes?.register)
            template.push({
                label: 'Register',
                children: () => (
                    <div className="qr-wrap">
                        <img className="image" src={qrCodes?.register} alt="Register QR Code" />

                        <CopyTextField link={rewardLinks?.data?.register} />
                    </div>
                )
            });

        if (qrCodes?.login)
            template.push({
                label: 'Login',
                children: () => (
                    <div className="qr-wrap">
                        <img className="image" src={qrCodes?.login} alt="Login QR Code" />

                        <CopyTextField link={rewardLinks?.data?.login} />
                    </div>
                )
            });

        return template;
    }, [qrCodes]);

    return (
        <div className="preview-qr-modal modal-container">
            <div className="modal-content">
                {tabsData?.length === 0 ? (
                    'No invitations are found'
                ) : (
                    <Tabs tab={tab} setTab={setTab} tabs={tabsData} />
                )}
            </div>
        </div>
    );
}
