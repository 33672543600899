import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import { updateTranscriptSubjectProps } from './interfaces/transcript-subject.interface';

const transcriptSubjectServices = createApi({
    reducerPath: 'transcript-subject-service',
    baseQuery: baseQuery,
    tagTypes: ['TranscriptSubject', 'TranscriptSubjects', 'TranscriptSubjectOptions'],
    endpoints: (builder) => ({
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/transcript-subjects/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'TranscriptSubject' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/transcript-subjects/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'TranscriptSubjects' }, { type: 'TranscriptSubjectOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/transcript-subjects/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'TranscriptSubjectOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateTranscriptSubjectProps) => ({
                method: 'PUT',
                url: `/transcript-subjects/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [
                { type: 'TranscriptSubjects' },
                { type: 'TranscriptSubjectOptions' },
                { type: 'TranscriptSubject' }
            ]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/transcript-subjects/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [
                { type: 'TranscriptSubjects' },
                { type: 'TranscriptSubjectOptions' },
                { type: 'TranscriptSubject' }
            ]
        })
    })
});

export default transcriptSubjectServices;
