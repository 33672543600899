import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    addReceiverGroupProps,
    createReceiverGroupProps,
    updateReceiverGroupProps
} from './interfaces/receiver-group.interface';

const receiverGroupServices = createApi({
    reducerPath: 'receiver-groups-service',
    baseQuery: baseQuery,
    tagTypes: ['ReceiverGroups', 'ReceiverGroupsOptions'],
    endpoints: (builder) => ({
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/receiver-groups/${org_id}/get/${type}/${value}`
            })
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/receiver-groups/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'ReceiverGroups' }, { type: 'ReceiverGroupsOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/receiver-groups/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'ReceiverGroupsOptions' }]
        }),
        create: builder.mutation({
            query: ({ org_id, ...props }: createReceiverGroupProps) => ({
                method: 'POST',
                url: `/receiver-groups/${org_id}/create`,
                body: props
            }),
            invalidatesTags: [{ type: 'ReceiverGroups' }, { type: 'ReceiverGroupsOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateReceiverGroupProps) => ({
                method: 'PATCH',
                url: `/receiver-groups/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'ReceiverGroups' }, { type: 'ReceiverGroupsOptions' }]
        }),
        add: builder.mutation({
            query: ({ org_id, ...props }: addReceiverGroupProps) => ({
                method: 'PUT',
                url: `/receiver-groups/${org_id}/add`,
                body: props
            }),
            invalidatesTags: [{ type: 'ReceiverGroups' }, { type: 'ReceiverGroupsOptions' }]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/receiver-groups/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'ReceiverGroups' }, { type: 'ReceiverGroupsOptions' }]
        })
    })
});

export default receiverGroupServices;
