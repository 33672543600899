import React from 'react';
import * as Yup from 'yup';
import Button from '../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { modalTypes } from '../..';
import orgServices from '../../../services/org.services';
import SubmitButton from '../../../components/form/submit-button';
import Form from '../../../components/form';
import { notifyActions } from '../../../redux/reducers/notify';
import TextField from '../../../components/form/text-field';
import rewardElementServices from '../../../services/reward-element.services';
import elementTemplateServices from '../../../services/element-templates.services';

const initialValues = {
    name: ''
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
});

export default function SaveTemplate() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.SAVE_TEMPLATE];

    /* mutation */
    const [
        create,
        { error: elementTemplateError, isError: isElementTemplateError, isLoading: isCreating }
    ]: any = elementTemplateServices.useCreateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isElementTemplateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementTemplateError.data?.message
                })
            );
        }
    }, [elementTemplateError, isElementTemplateError]);

    const onSubmit = async (values: any) => {
        const result = await create({
            org_id: authUser.organization_id,
            name: values.name,
            type: props.type,
            description: props.description,
            reward: props.reward,
            custom_attributes: props.custom_attributes
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container organization-modal">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <TextField
                        name="name"
                        label="Template Name"
                        placeholder="Enter Template Name"
                        required
                    />
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isCreating} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isCreating} variant="contained">
                        Confirm
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
