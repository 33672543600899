import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '../../components/custom/icon-button';
import SearchSelect from '../../components/custom/search-select';
import TextField from '../../components/custom/text-field';
import Icon from '../../components/icon';
import { notifyActions } from '../../redux/reducers/notify';
import fontServices from '../../services/fonts.services';
import ColorPicker from './color-picker';
import WebFont from 'webfontloader';

interface Props {
    data: any;
    setData: any;
    textAlign?: boolean;
}

export default function TextSettings({ data, setData, textAlign = true }: Props) {
    const dispatch = useDispatch();
    const [font, setFont] = React.useState('Roboto');

    /* query */
    const {
        data: fonts,
        error: fontsError,
        isError: isFontsError,
        isLoading: isFontsLoading
    }: any = fontServices.useGetAllQuery({});

    React.useEffect(() => {
        if (isFontsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: fonts?.data?.message
                })
            );
        }
    }, [fontsError, isFontsError]);

    const fontData = React.useMemo(() => {
        let template = [];

        if (fonts?.data) {
            template = fonts?.data?.map((font: any) => {
                return {
                    label: font.family,
                    value: font.family
                };
            });
        }
        return template;
    }, [fonts]);

    React.useEffect(() => {
        if (data?.fontFamily) setFont(data?.fontFamily);
    }, [data]);

    /* functions */
    const onFont = (event: any, value: string) => {
        WebFont.load({
            google: {
                families: [value]
            },
            loading: function () {
                console.log('Fonts are being loaded');
            },
            active: function () {
                console.log('Fonts have been rendered');

                setData({ type: 'fontFamily', value: value });
                setFont(value);
            }
        });
    };

    return (
        <div className="text-settings">
            <div className="section">
                <div className="double-wrap">
                    <TextField
                        className="font-size-txt"
                        type="number"
                        value={data?.fontSize}
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={(e) =>
                            setData({ type: 'fontSize', value: Number(e.target.value) })
                        }
                    />
                    <SearchSelect
                        loading={isFontsLoading}
                        value={font}
                        data={fontData}
                        onSelect={onFont}
                    />
                </div>
            </div>

            <div className="section">
                <ColorPicker
                    opacity={data?.opacity}
                    color={data?.fill}
                    setColor={(color: string) => setData({ type: 'fill', value: color })}
                    setOpacity={(opacity: number) => setData({ type: 'opacity', value: opacity })}
                />
            </div>

            {textAlign && (
                <div className="section">
                    <p className="title">Text Align</p>

                    <div className="items">
                        <IconButton
                            onClick={() => setData({ type: 'textAlign', value: 'left' })}
                            data-active={data?.textAlign === 'left'}>
                            <Icon className="icon w-600">format_align_left</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => setData({ type: 'textAlign', value: 'center' })}
                            data-active={data?.textAlign === 'center'}>
                            <Icon className="icon w-600">format_align_center</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => setData({ type: 'textAlign', value: 'right' })}
                            data-active={data?.textAlign === 'right'}>
                            <Icon className="icon w-600">format_align_right</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => setData({ type: 'textAlign', value: 'justify' })}
                            data-active={data?.textAlign === 'justify'}>
                            <Icon className="icon w-600">format_align_justify</Icon>
                        </IconButton>
                    </div>
                </div>
            )}

            <div className="section">
                <p className="title">Formatting</p>

                <div className="items">
                    <IconButton
                        onClick={() =>
                            setData({
                                type: 'fontWeight',
                                value: data?.fontWeight === 'bold' ? 'normal' : 'bold'
                            })
                        }
                        data-active={data?.fontWeight === 'bold'}>
                        <Icon className="icon w-600">format_bold</Icon>
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            setData({
                                type: 'underline',
                                value: !data?.underline
                            })
                        }
                        data-active={data?.underline}>
                        <Icon className="icon w-600">format_underlined</Icon>
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            setData({
                                type: 'fontStyle',
                                value: data?.fontStyle === 'italic' ? 'normal' : 'italic'
                            })
                        }
                        data-active={data?.fontStyle === 'italic' ? 'normal' : 'italic'}>
                        <Icon className="icon w-600">format_italic</Icon>
                    </IconButton>
                </div>
            </div>
        </div>
    );
}
