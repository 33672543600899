import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sessionUtils from '../../utils/session';
import utils from '../../utils';
import axios from 'axios';
import config from '../../config/config';

interface userProps {
    id: string;
    email: string;
    role: string;
    organization_id: string;
    main_admin: boolean;
    [prop: string]: string | boolean | number;
}

interface actionsProps {
    payload: userProps;
}

interface stateProps {
    loading?: boolean;
    user: userProps;
}

const initState: stateProps = {
    loading: true,
    user: {
        id: '',
        email: '',
        role: '',
        organization_id: '',
        main_admin: false
    }
};

export const fetchUser = createAsyncThunk('user/me', async (_, { dispatch }) => {
    if (!sessionStorage.getItem(sessionUtils.LOGIN_STATUS)) return null;

    const response = await axios.get(`${config.server_urls.mr_org_api}users/me`, {
        withCredentials: true
    });

    return response.data.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState: initState,
    reducers: {
        set(state, { payload }: actionsProps) {
            state.user = payload;
            state.loading = false;

            sessionStorage.setItem(sessionUtils.LOGIN_STATUS, 'true');
        },
        remove(state) {
            axios.delete(`${config.server_urls.mr_org_api}users/signout`, {
                withCredentials: true
            });

            state.user = {
                id: '',
                email: '',
                role: '',
                organization_id: '',
                main_admin: false
            };

            sessionStorage.removeItem(sessionUtils.LOGIN_STATUS);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
        });
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.loading = false;
        });
    }
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
