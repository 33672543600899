import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '../../../../components/custom/text-field';
import { v4 as uuid } from 'uuid';
import IconButton from '../../../../components/custom/icon-button';
import Icon from '../../../../components/icon';
import { elementActions } from '../../../../redux/reducers/element';

export default React.memo(function Attributes() {
    const dispatch = useDispatch();
    const { attributes } = useSelector((state: any) => state.element);

    /* functions */
    const onUpdate = ({ event, type, value, index }: any) => {
        const array = [...attributes];
        const data = { ...attributes[index] };
        data[type] = value;
        array[index] = data;

        dispatch(elementActions.set({ attributes: array }));
    };

    const removeAttribute = (index: number) => {
        dispatch(
            elementActions.set({
                attributes: attributes.filter((v: any, i: number) => i !== index)
            })
        );
    };

    return (
        <div className="editor-settings attribute-settings">
            {attributes?.length === 0
                ? 'No custom attributes available'
                : attributes?.map((item: any, index: number) => {
                      return (
                          <div key={index} className="collapse-item">
                              <div className="attribute-item">
                                  <div className="inputs">
                                      <TextField
                                          placeholder="Attribute Name"
                                          label="Attribute Name"
                                          value={item?.name}
                                          InputLabelProps={{
                                              shrink: true
                                          }}
                                          onChange={(e) =>
                                              onUpdate({
                                                  event: e,
                                                  type: 'name',
                                                  value: e.target.value,
                                                  index: index
                                              })
                                          }
                                      />
                                      <TextField
                                          placeholder="Attribute Value"
                                          label="Attribute Value"
                                          value={item?.value}
                                          InputLabelProps={{
                                              shrink: true
                                          }}
                                          onChange={(e) =>
                                              onUpdate({
                                                  event: e,
                                                  type: 'value',
                                                  value: e.target.value,
                                                  index: index
                                              })
                                          }
                                      />
                                  </div>
                                  <IconButton onClick={() => removeAttribute(index)}>
                                      <Icon className="icon w-600">delete</Icon>
                                  </IconButton>
                              </div>
                          </div>
                      );
                  })}
        </div>
    );
});
