import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import TextField from '../../../components/form/text-field';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { notifyActions } from '../../../redux/reducers/notify';
import inviteServices from '../../../services/invite.services';
import { modalTypes } from '../..';
import activityTagServices from '../../../services/activity-tags.services';
import Checkbox from '../../../components/form/checkbox';
import rewardServices from '../../../services/reward.services';
import activityBatchServices from '../../../services/activity-batch.services';

const initialValues = {
    name: '',
    email: '',
    create_only: false
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Receiver name is required'),
    email: Yup.string().email().required('Receiver email is required'),
    create_only: Yup.boolean()
});

export default function InviteRewardee() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);
    const { activity } = useSelector((state: any) => state.activity);
    const { modalProps } = useSelector((state: any) => state.modal);
    const props = modalProps[modalTypes.INVITE_REWARDEE];

    /* mutation */
    const [
        updateTags,
        {
            error: activityTagUpdateError,
            isError: isActivityTagsUpdateError,
            isLoading: isRewardsUpdating
        }
    ]: any = activityTagServices.useUpdateMutation();

    const [invite, { error: inviteError, isError: isInviteError, isLoading: isInviting }]: any =
        inviteServices.useInviteRewardeesMutation();

    /* error handling */
    React.useEffect(() => {
        if (isInviteError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: inviteError.data?.message
                })
            );
        }
        if (isActivityTagsUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityTagUpdateError?.data?.message
                })
            );
        }
    }, [inviteError, isInviteError, activityTagUpdateError, isActivityTagsUpdateError]);

    const onSubmit = async (values: any) => {
        const tags = await updateTags({
            data: [
                { name: 'name', tag: 'NAME' },
                { name: 'email', tag: 'EMAIL' }
            ],
            activity_id: activity?.id,
            org_id: authUser.organization_id
        });

        if (tags?.data?.status !== 'success') {
            dispatch(
                notifyActions.open({
                    type: tags?.data?.status,
                    message: tags.data?.message
                })
            );

            return;
        }

        const result = await invite({
            activity_id: activity?.id,
            create_only: !values.create_only,
            invitees: [{ name: values.name, email: values.email.trim().toLowerCase() }],
            org_id: authUser.organization_id,
            date: new Date().toString(),
            user_id: authUser.id,
            batch: props.batch
        });

        if (result?.data?.status === 'success') {
            dispatch(rewardServices.util.invalidateTags(['Rewards', 'RewardsOptions']));
            dispatch(activityBatchServices.util.invalidateTags(['ActivityBatches']));

            dispatch(modalActions.hide());
        }

        if (result?.data?.status) {
            dispatch(
                notifyActions.open({
                    type: result?.data?.status,
                    message: result.data?.message
                })
            );
        }
    };

    return (
        <div className="modal-container invite-rewardee-modal">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <TextField
                        name="name"
                        label="Receiver Name"
                        placeholder="Receiver Name"
                        required
                    />
                    <TextField
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Email"
                        required
                    />

                    <Checkbox name="create_only" label="Send the invitation email now" />
                </div>

                <DialogActions className="modal-buttons">
                    <Button
                        disabled={isRewardsUpdating || isInviting}
                        onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isRewardsUpdating || isInviting} variant="contained">
                        Invite Receiver
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
