import React from 'react';
import Button from '../../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../../redux/reducers/modal';
import { modalTypes } from '../../..';
import { notifyActions } from '../../../../redux/reducers/notify';
import activityBatchServices from '../../../../services/activity-batch.services';

export default function CreateActivityBatch() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.CREATE_ACTIVITY_BATCH];

    /* mutation */
    const [
        createBatch,
        {
            error: createActivityBatchError,
            isError: isCreateActivityBatchError,
            isLoading: isCreating
        }
    ]: any = activityBatchServices.useCreateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isCreateActivityBatchError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: createActivityBatchError.data?.message
                })
            );
        }
    }, [createActivityBatchError, isCreateActivityBatchError]);

    const onSubmit = async () => {
        const result = await createBatch({
            batch: props.batch,
            activity_id: props.activity,
            org_id: authUser.organization_id,
            user_id: authUser.id
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container">
            <div className="modal-content">
                Are you sure you want to create a new activity batch?
            </div>

            <DialogActions className="modal-buttons">
                <Button disabled={isCreating} onClick={() => dispatch(modalActions.hide())}>
                    Cancel
                </Button>
                <Button onClick={onSubmit} loading={isCreating} variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </div>
    );
}
