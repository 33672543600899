import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { notifyActions } from '../../../redux/reducers/notify';
import { modalTypes } from '../..';
import receiverGroupServices from '../../../services/receiver-group.services';
import PageTable from '../../../components/page-table';
import IconButton from '../../../components/custom/icon-button';
import Icon from '../../../components/icon';
import { DialogActions } from '@mui/material';
import Button from '../../../components/custom/button';

export default function ViewGroup() {
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const { add, group_id }: any = modalProps[modalTypes.GROUP_RECEIVERS];

    /* query */
    const {
        data: receiverGroups,
        error: receiverGroupsError,
        isError: isReceiverGroupsError,
        isFetching: isFetching,
        isLoading: isLoading
    }: any = receiverGroupServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            page: page,
            find: [
                { column: 'group_id', value: group_id, like: false },
                { column: 'receivers.id', value: '', like: false }
            ]
        },
        { skip: !authUser?.organization_id || !group_id }
    );

    const {
        data: options,
        refetch: refetchReceiverGroupsOptions,
        isLoading: isReceiverGroupsOptionsLoading,
        error: receiverGroupsOptionsError,
        isError: isReceiverGroupsOptionsError
    }: any = receiverGroupServices.useOptionsQuery(
        {
            org_id: authUser.organization_id,
            limit: pageSize,
            find: [
                { column: 'group_id', value: group_id, like: false },
                { column: 'receivers.id', value: '', like: false }
            ]
        },
        { skip: !authUser?.organization_id || !group_id }
    );

    /* error handling */
    React.useEffect(() => {
        if (isReceiverGroupsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: receiverGroupsError?.data?.message
                })
            );
        }
        if (isReceiverGroupsOptionsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: receiverGroupsOptionsError?.data?.message
                })
            );
        }
    }, [
        receiverGroupsError,
        isReceiverGroupsError,
        receiverGroupsOptionsError,
        isReceiverGroupsOptionsError
    ]);

    /* cells */
    const renderName = ({ data }: any) => {
        return data?.receivers?.name || '-';
    };

    const renderEmail = ({ data }: any) => {
        return data?.receivers?.email || '-';
    };

    const tableOptions = {
        headers: [{ render: 'Receiver Name' }, { render: 'Receiver Email' }],
        cells: [{ render: renderName }, { render: renderEmail }]
    };

    /* functions */
    const onBack = () => {
        dispatch(modalActions.hide());

        dispatch(
            modalActions.show({
                type: modalTypes.ADD_RECEIVERS_GROUP
            })
        );
    };

    const onContinue = () => {
        dispatch(
            modalActions.update({
                type: modalTypes.GROUP_RECEIVERS,
                props: { confirmed: true, data: receiverGroups?.data }
            })
        );

        dispatch(modalActions.hide());
    };

    return (
        <div className="modal-container">
            <div className="modal-content">
                {add && (
                    <span>
                        Receivers from the group will be added to the receivers list.{' '}
                        <b>Do you want to continue?</b>
                    </span>
                )}

                <PageTable
                    className="table"
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    rows={receiverGroups?.data}
                    options={options?.data}
                    headers={tableOptions.headers}
                    cells={tableOptions.cells}
                    loading={isLoading}
                    fetching={isFetching}
                    optionsLoading={isReceiverGroupsOptionsLoading}
                />
            </div>

            <DialogActions className="modal-buttons">
                {add ? (
                    <Button onClick={onContinue} variant="contained">
                        Continue
                    </Button>
                ) : (
                    <Button onClick={onBack} variant="contained">
                        Back
                    </Button>
                )}
            </DialogActions>
        </div>
    );
}
