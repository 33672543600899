export default {
    snakeCase: (string: string = '', uppercase: boolean = true) => {
        if (!string) return '';

        let value = string.replace(' ', '_').replace(/[^a-zA-Z0-9_]/g, '_');

        if (uppercase) value = value.toUpperCase();

        return value;
    },
    isJson: (str: any) => {
        try {
            JSON.parse(str);
        } catch (error) {
            return false;
        }

        return true;
    },
    sizeOfObject: (obj: any) => {
        let str: any = null;
        if (typeof obj === 'string') {
            // If obj is a string, then use it
            str = obj;
        } else {
            // Else, make obj into a string
            str = JSON.stringify(obj);
        }
        // Get the length of the Uint8Array
        const bytes = new TextEncoder().encode(str).length;
        return bytes;
    },
    parseDomain: (string: string, subdomain: boolean = true) => {
        subdomain = subdomain || false;

        let url: any = string.replace(/(https?:\/\/)?(www.)?/i, '');

        if (!subdomain) {
            url = url.split('.');

            url = url.slice(url.length - 2).join('.');
        }

        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }

        if (!url) return {};

        return { domain: url };
    }
};
