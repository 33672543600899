import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalTypes } from '../..';
import { notifyActions } from '../../../redux/reducers/notify';
import rewardElementServices from '../../../services/reward-element.services';
import Skeleton from '../../../components/custom/skeleton';
import CanvasPreview from '../../../components/preview/canvas';
import { modalActions } from '../../../redux/reducers/modal';
import Icon from '../../../components/icon';
import Button from '../../../components/custom/button';
import ModelPreview from '../../../components/preview/model';

export default function PreviewRewardElement() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.PREVIEW_REWARD_ELEMENT];

    const [json, setJson] = React.useState({});

    /* query */
    const {
        data: element,
        error: elementError,
        isError: isElementError,
        isLoading: isElementLoading
    }: any = rewardElementServices.useGetQuery(
        {
            org_id: authUser?.organization_id,
            type: 'id',
            value: props?.id
        },
        { skip: !props?.id || !authUser?.organization_id }
    );

    const [
        generate,
        { error: generateElementError, isError: isGenerateElementError, isLoading: isGenerating }
    ]: any = rewardElementServices.useGenerateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isElementError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementError.data?.message
                })
            );
        }
        if (isGenerateElementError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: generateElementError.data?.message
                })
            );
        }
    }, [elementError, isElementError, generateElementError, isGenerateElementError]);

    React.useEffect(() => {
        if (element?.data?.name)
            dispatch(
                modalActions.update({
                    type: modalTypes.PREVIEW_REWARD_ELEMENT,
                    props: { ...props, title: element?.data?.name }
                })
            );

        if (element?.data?.reward) {
            generateElement();
        }
    }, [element]);

    /* functions */
    const generateElement = async () => {
        if (!props?.reward_id || element?.data?.type === 'MODEL') {
            setJson(element?.data?.reward);
            return;
        }

        const result = await generate({
            reward_id: props?.reward_id,
            element_id: element?.data?.id
        });

        if (result?.data?.data) setJson(result?.data?.data);
    };

    const onBack = () => {
        if (!props?.back_modal?.type) return;

        dispatch(modalActions.hide());

        dispatch(
            modalActions.show({
                type: props?.back_modal?.type,
                props: props?.back_modal?.props
            })
        );
    };

    return (
        <div className="modal-container reward-element-preview-modal">
            <div className="modal-content">
                {props?.back_modal && (
                    <Button
                        variant="text"
                        onClick={onBack}
                        className="back"
                        startIcon={<Icon className="icon">chevron_left</Icon>}>
                        Back
                    </Button>
                )}

                {isElementLoading || isGenerating ? (
                    <Skeleton height={250} />
                ) : element?.data?.type === 'MODEL' ? (
                    <ModelPreview json={json} />
                ) : (
                    <CanvasPreview json={json} />
                )}
            </div>
        </div>
    );
}
