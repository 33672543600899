import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/custom/button';

interface Props {
    className?: string;
}

export default function NotFound({ className = 'container' }: Props) {
    return (
        <div id="not-found" className={className}>
            <span className="text">404</span>
            <span className="sub-text">
                The page you are looking for may have been moved, deleted, or possibly never
                existed.
            </span>
            <Link to="/">
                <Button variant="outlined">Back to Home</Button>
            </Link>
        </div>
    );
}
