import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

export default function TextField({
    size = 'small',
    type = 'text',
    className,
    fullWidth = true,
    ...props
}: TextFieldProps) {
    return (
        <MuiTextField
            size={size}
            type={type}
            fullWidth={fullWidth}
            className={`input ${className ? className : ''}`}
            {...props}
        />
    );
}
