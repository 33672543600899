import _ from 'lodash';

const rewardElementUtils = {
    validate: ({ element }) => {
        const { name, description, attributes, type, other_attributes } = element;

        const attributes_template = {
            BADGE: {
                name: name,
                description: description,
                issued_date: other_attributes?.issued_date
            },
            CERTIFICATE: {
                title: name,
                description: description,
                conducted_by: other_attributes?.conducted_by,
                issued_date: other_attributes?.issued_date
            },
            TRANSCRIPT: {
                name: name,
                description: description,
                university: other_attributes?.university,
                qualification: other_attributes?.qualification,
                issued_date: other_attributes?.issued_date
            },
            OTHER: {
                title: name
            },
            MODEL: {
                name: name,
                description: description,
                issued_date: other_attributes?.issued_date
            }
        };
        const required_attributes = attributes_template[type];

        if (required_attributes) {
            for (let key in required_attributes) {
                if (!required_attributes[key]) {
                    return {
                        error: {
                            type: 'warning',
                            message: `${_.startCase(key)} is required`
                        }
                    };
                }
            }
        }

        return {};
    }
};

export default rewardElementUtils;
