import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import { DialogActions, Collapse } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { notifyActions } from '../../../redux/reducers/notify';
import List from '../../../components/custom/list';
import { FieldArray } from 'formik';
import TextField from '../../../components/form/text-field';
import { modalTypes } from '../..';
import { v4 as uuid } from 'uuid';
import Skeleton from '../../../components/custom/skeleton';
import receiverDetailServices from '../../../services/receiver-detail.services';
import activityTagServices from '../../../services/activity-tags.services';
import { useLocation } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    details: Yup.array().of(
        Yup.object().shape({
            tag: Yup.string().required('Tag is required'),
            value: Yup.string()
        })
    )
});

export default function ManageDetails() {
    const dispatch = useDispatch();
    const { state: routerState } = useLocation();

    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const { data: props } = modalProps[modalTypes.MANAGE_RECEIVER_DETAILS];
    const helper: any = React.useRef(null);

    /* query */
    const {
        data: activityTags,
        error: activityTagsError,
        isError: isActivityTagsError,
        isLoading: isTagsLoading
    }: any = activityTagServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            page: 0,
            all: true,
            find: [{ column: 'activity_id', value: props?.activity_id, like: false }]
        },
        { skip: !authUser?.organization_id || !props?.activity_id }
    );

    /* query */
    const {
        data: receiverDetails,
        error: receiverDetailsError,
        isError: isReceiverDetailsError,
        isLoading: isLoading
    }: any = receiverDetailServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            page: 0,
            find: {
                activity: props.activity_id,
                reward: props.id
            }
        },
        { skip: !authUser?.organization_id || !props?.activity_id || !props?.id }
    );

    /* mutation */
    const [
        update,
        {
            error: receiverDetailUpdateError,
            isError: isReceiverDetailsUpdateError,
            isLoading: isUpdating
        }
    ]: any = receiverDetailServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isReceiverDetailsUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: receiverDetailUpdateError?.data?.message
                })
            );
        }
        if (isReceiverDetailsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: receiverDetailsError?.data?.message
                })
            );
        }
        if (isActivityTagsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityTagsError?.data?.message
                })
            );
        }
    }, [
        receiverDetailUpdateError,
        isReceiverDetailsUpdateError,
        receiverDetailsError,
        isReceiverDetailsError,
        activityTagsError,
        isActivityTagsError
    ]);

    /* data */
    const data: any = React.useMemo(() => {
        const data = {
            details: [{ tag: '', value: '' }]
        };

        if (Array.isArray(receiverDetails?.data) && Array.isArray(activityTags?.data)) {
            data.details = activityTags?.data.map((i: any) => {
                let value = '';
                const details = receiverDetails?.data.find((j: any) => j.tag === i.tag);

                if (details?.value) value = details.value;
                return {
                    tag: i.tag,
                    value: value
                };
            });
        }

        return data;
    }, [activityTags, receiverDetails]);

    const onSubmit = async (values: any) => {
        const parsed = values?.details?.map((item: any) => {
            return {
                tag: item.tag,
                value: item.value
            };
        });

        const result = await update({
            details: parsed,
            reward_id: props.id,
            activity_id: props?.activity_id,
            org_id: authUser.organization_id
        });

        if (result?.data?.status) {
            dispatch(
                notifyActions.open({
                    type: result?.data?.status,
                    message: result.data?.message
                })
            );
        }

        if (result?.data?.status === 'success') {
            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container receiver-details-modal">
            {isTagsLoading || isLoading ? (
                <div className="modal-content">
                    <List className="list-wrap">
                        <Collapse>
                            <div className="detail">
                                <span className="label">
                                    <Skeleton width={45} height={10}></Skeleton>
                                </span>
                                <p className="value view">
                                    <Skeleton height={20}></Skeleton>
                                </p>
                            </div>
                        </Collapse>
                    </List>
                </div>
            ) : (
                <Form
                    enableReinitialize
                    initialValues={data}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    className="form">
                    <div className="form-content auto-padding overflow-content">
                        <FieldArray
                            name="details"
                            render={(arrayHelpers) => {
                                helper.current = arrayHelpers;

                                const {
                                    form: { values, errors }
                                } = arrayHelpers;

                                return values?.details?.length === 0 ? (
                                    'No receiver details available'
                                ) : (
                                    <div className="list-wrap">
                                        {values?.details?.map((item: any, index: number) => {
                                            return (
                                                <div key={index}>
                                                    <TextField
                                                        label={item.tag}
                                                        placeholder="Value"
                                                        name={`details.${index}.value`}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }}
                        />
                    </div>
                    <DialogActions className="modal-buttons">
                        <Button disabled={isUpdating} onClick={() => dispatch(modalActions.hide())}>
                            Cancel
                        </Button>
                        <SubmitButton
                            disabled={!!routerState?.read_only}
                            loading={isUpdating}
                            variant="contained">
                            Save Details
                        </SubmitButton>
                    </DialogActions>
                </Form>
            )}
        </div>
    );
}
