import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import TextField from '../../../components/form/text-field';
import MuiTextField from '../../../components/custom/text-field';
import { DialogActions, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import orgServices from '../../../services/org.services';
import { notifyActions } from '../../../redux/reducers/notify';
import userServices from '../../../services/user.services';
import IconButton from '../../../components/custom/icon-button';
import Icon from '../../../components/icon';

export default function GeneratePrivateKey() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);
    const [privateKey, setPrivateKey] = React.useState('');
    const [open, setOpen] = React.useState(false);

    /* mutation */
    const [
        update,
        { error: orgUpdateError, isError: isOrgUpdateError, isLoading: isUpdating }
    ]: any = orgServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isOrgUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: orgUpdateError.data?.message
                })
            );
        }
    }, [orgUpdateError, isOrgUpdateError]);

    const onSubmit = async () => {
        try {
            const data: any = await (await import('../../../utils/generate-pvt')).default();

            const org = await update({
                org_id: authUser.organization_id,
                user_id: authUser.id,
                issuer_did: data.issuer.did
            });

            if (org.data.status === 'success') {
                setPrivateKey(data?.issuer?.private_key);

                dispatch(
                    notifyActions.open({
                        type: 'success',
                        message: org.data?.message
                    })
                );
            }
        } catch (error: any) {
            if (error?.error) {
                return dispatch(
                    notifyActions.open({
                        type: 'error',
                        message: error.error
                    })
                );
            }
        }
    };

    const onCopy = () => {
        if (privateKey) {
            navigator.clipboard.writeText(privateKey);
            setOpen(true);
        }
    };

    return (
        <div className="modal-container organization-modal">
            <>
                <div className="modal-content">
                    <p className="notice">
                        <b>Important</b> : The private key is top secret and must be kept safe. You
                        can use this key to sign rewards of your organization also this key cannot
                        be recovered if lost.
                    </p>

                    {privateKey && (
                        <MuiTextField
                            autoComplete="off"
                            value={privateKey}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <Tooltip
                                        onClose={() => setOpen(false)}
                                        open={open}
                                        title="Copied!">
                                        <IconButton>
                                            <Icon className="icon" onClick={onCopy}>
                                                content_copy
                                            </Icon>
                                        </IconButton>
                                    </Tooltip>
                                )
                            }}
                        />
                    )}
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isUpdating} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!authUser?.main_admin || !!privateKey}
                        onClick={onSubmit}
                        loading={isUpdating}
                        variant="contained">
                        Generate
                    </Button>
                </DialogActions>
            </>
        </div>
    );
}
