import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'yup';
import SearchSelect from '../../../../components/custom/search-select';
import { notifyActions } from '../../../../redux/reducers/notify';
import activityTagServices from '../../../../services/activity-tags.services';
import ColorPicker from '../../../components/color-picker';
import TextSettings from '../../../components/text-settings';
import Common from './common';

interface Props {
    canvas?: any;
}

export default function Tag({ canvas }: Props) {
    const dispatch = useDispatch();
    const { options } = useSelector((state: any) => state.editor_options);
    const { user: authUser } = useSelector((state: any) => state.user);
    const { activity } = useSelector((state: any) => state.activity);

    const [selectedTag, setSelectedTag] = React.useState('');

    const [data, setData] = React.useState({
        opacity: 100,
        fill: '#000000',
        textAlign: 'center'
    });

    /* query */
    const {
        data: activityTags,
        error: activityTagsError,
        isError: isActivityTagsError,
        isLoading: isTagsLoading
    }: any = activityTagServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            page: 0,
            all: true,
            find: [{ column: 'activity_id', value: activity?.id, like: false }]
        },
        { skip: !authUser?.organization_id || !activity?.id }
    );

    /* error handling */
    React.useEffect(() => {
        if (isActivityTagsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityTagsError?.data?.message
                })
            );
        }
    }, [activityTagsError, isActivityTagsError]);

    /* data */
    const tagsData: any = React.useMemo(() => {
        let data: any = [];

        if (Array.isArray(activityTags?.data)) {
            data = activityTags?.data.map((i) => {
                return {
                    label: i.name,
                    value: `{{ ${i.tag} }}`
                };
            });
        }

        return data;
    }, [activityTags]);

    React.useEffect(() => {
        if (canvas) {
            const objects = canvas.getActiveObjects();
            let temp: any = {};

            /* get common data from all objects */
            for (const obj of objects) {
                obj['image'] = obj.src ? obj.getSrc() : '';

                for (const key in obj) {
                    if (Object.hasOwnProperty.call(obj, key)) {
                        const element = obj[key];

                        if (temp[key] && temp[key] !== element) {
                            delete temp[key];
                        }

                        if (!temp[key]) {
                            temp[key] = element;
                        }
                    }
                }
            }

            if (temp.text) {
                let data: any = activityTags?.data?.find((i: any) => temp.text.includes(i.tag));

                if (data?.tag) setSelectedTag(`{{ ${data.tag} }}`);
            }

            setData(temp);
        }
    }, [canvas, activityTags]);

    const update = ({ type, value }: { type: string; value: any }) => {
        const objects = canvas.getActiveObjects();

        for (const obj of objects) {
            if (type === 'image') {
                obj.setSrc(value, () => {
                    canvas.requestRenderAll();
                });
            } else {
                obj.set({ [type]: value });
                setData({ ...data, [type]: value });
                canvas.requestRenderAll();
            }
        }
    };

    const onSelectTag = (event: any, value: string) => {
        setSelectedTag(value);
        update({ type: 'text', value: value });
    };

    if (options?.selected_obj !== 'text')
        return <div className="editor-settings">Please select a {`{{ TAG }}`} item</div>;

    return (
        <div className="editor-settings">
            <Common canvas={canvas} />

            <SearchSelect
                className="header"
                value={selectedTag}
                onSelect={onSelectTag}
                data={tagsData}
            />

            <TextSettings data={data} setData={(data: any) => update(data)} />
        </div>
    );
}
