import { createSlice } from '@reduxjs/toolkit';

const initState: any = {
    activity: {}
};

const activitySlice = createSlice({
    name: 'activity',
    initialState: initState,
    reducers: {
        set(state, { payload }) {
            state.activity = payload;
        },
        close(state) {
            state.activity = {};
        },
        update(state, { payload }) {
            for (const key in payload) {
                state.activity[key] = payload[key];
            }
        }
    }
});

export const activityActions = activitySlice.actions;

export default activitySlice.reducer;
