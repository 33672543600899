import { TableCell } from '@mui/material';
import React from 'react';
import { v4 as uuid } from 'uuid';
import Checkbox from '../custom/checkbox';

export default function TableBody({
    _index,
    item,
    cells,
    checkBoxSelection,
    onSelect,
    isSelected
}) {
    const ItemSelected = isSelected ? isSelected(item?.id) : false;

    const typeCheck = (value: any) => {
        return typeof value === 'string' || typeof value === 'number';
    };

    return (
        <React.Fragment>
            {checkBoxSelection && (
                <TableCell className="icon-button-cell" align="center" padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={ItemSelected}
                        onClick={(e) => onSelect(e, item?.id)}
                    />
                </TableCell>
            )}
            {cells.map(({ render: Render, props = {} }: any) => {
                if (!props.align) props.align = 'left';

                return (
                    <TableCell key={uuid()} {...props}>
                        {typeCheck(Render) ? (
                            <span className="text">{item[Render] || '-'}</span>
                        ) : (
                            <Render data={item} />
                        )}
                    </TableCell>
                );
            })}
        </React.Fragment>
    );
}
