import React from 'react';
import Button from '../../../components/custom/button';
import { DialogActions, Collapse } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { notifyActions } from '../../../redux/reducers/notify';
import { modalTypes } from '../..';
import receiverGroupServices from '../../../services/receiver-group.services';
import PageTable from '../../../components/page-table';
import IconButton from '../../../components/custom/icon-button';
import Icon from '../../../components/icon';
import groupServices from '../../../services/group.services';

export default function AddGroup() {
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [selectedGroups, setSelectedGroups] = React.useState([]);

    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const { data: receivers } = modalProps[modalTypes.ADD_RECEIVERS_GROUP];
    const helper: any = React.useRef(null);

    /* mutation */
    const [
        addToGroup,
        { error: addToGroupError, isError: isAddToGroupError, isLoading: isAdding }
    ]: any = receiverGroupServices.useAddMutation();

    /* query */
    const {
        data: receiverGroups,
        error: receiverGroupsError,
        isError: isReceiverGroupsError,
        isLoading: isLoading,
        isFetching: isFetching,
        refetch: refetchGroups
    }: any = groupServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            page: page,
            find: [{ column: 'receiverGroups.id', value: '', like: true, required: false }]
        },
        { skip: !authUser?.organization_id }
    );

    const {
        data: options,
        refetch: refetchReceiverGroupsOptions,
        isLoading: isReceiverGroupsOptionsLoading,
        error: receiverGroupsOptionsError,
        isError: isReceiverGroupsOptionsError,
        refetch: refetchGroupOptions
    }: any = groupServices.useOptionsQuery({
        org_id: authUser.organization_id,
        limit: pageSize,
        find: [{ column: 'receiverGroups.id', value: '', like: true, required: false }]
    });

    /* error handling */
    React.useEffect(() => {
        if (isReceiverGroupsOptionsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: receiverGroupsOptionsError?.data?.message
                })
            );
        }
        if (isReceiverGroupsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: receiverGroupsError?.data?.message
                })
            );
        }
        if (isAddToGroupError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: addToGroupError?.data?.message
                })
            );
        }
    }, [
        receiverGroupsError,
        isReceiverGroupsError,
        receiverGroupsOptionsError,
        isReceiverGroupsOptionsError,
        addToGroupError,
        isAddToGroupError
    ]);

    /* cells */
    const renderMembersCell = ({ data }: any) => {
        return data?.receiver_groups?.length || 0;
    };

    const renderViewBtn = ({ data }: any) => {
        const onView = () => {
            dispatch(modalActions.hide());

            dispatch(
                modalActions.show({
                    type: modalTypes.GROUP_RECEIVERS,
                    props: { group_id: data.id }
                })
            );
        };

        return (
            <IconButton tooltip={{ title: 'View', placement: 'bottom' }} onClick={onView}>
                <Icon className="icon">visibility</Icon>
            </IconButton>
        );
    };

    const tableOptions = {
        headers: [
            { render: 'Group Name' },
            { render: 'No Of Members' },
            { render: '', props: { className: 'icon-button-cell' } }
        ],
        cells: [{ render: 'name' }, { render: renderMembersCell }, { render: renderViewBtn }]
    };

    /* functions */
    const onCreateGroup = () => {
        dispatch(modalActions.hide());

        dispatch(
            modalActions.show({
                type: modalTypes.CREATE_RECEIVERS_GROUP
            })
        );
    };

    const onAddToGroup = async () => {
        if (selectedGroups.length === 0) {
            return dispatch(
                notifyActions.open({
                    type: 'warning',
                    message: 'Please select at least on group'
                })
            );
        }

        const result = await addToGroup({
            org_id: authUser.organization_id,
            groups: selectedGroups.map((group: any) => {
                return {
                    id: group
                };
            }),
            receivers: receivers
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());

            refetchGroups();
            refetchGroupOptions();
        }
    };

    return (
        <div className="modal-container add-group-receivers-modal">
            <div className="modal-content">
                <PageTable
                    className="table"
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    rows={receiverGroups?.data}
                    options={options?.data}
                    headers={tableOptions.headers}
                    cells={tableOptions.cells}
                    loading={isLoading}
                    fetching={isFetching}
                    optionsLoading={isReceiverGroupsOptionsLoading}
                    selected={selectedGroups}
                    setSelected={setSelectedGroups}
                    checkBoxSelection
                />
            </div>

            <DialogActions className="modal-buttons">
                <div className="section">
                    <Button disabled={isAdding} onClick={onCreateGroup} variant="contained">
                        Create New Group
                    </Button>
                </div>
                <div className="section">
                    <Button disabled={isAdding} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <Button loading={isAdding} onClick={onAddToGroup} variant="contained">
                        Add to Group
                    </Button>
                </div>
            </DialogActions>
        </div>
    );
}
