import React from 'react';
import Icon from '../../../components/icon';
import { fabric } from 'fabric';
import samples from '../../assets/samples.json';
import IconButton from '../../../components/custom/icon-button';
import { ClickAwayListener, Popper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { notifyActions } from '../../../redux/reducers/notify';

interface Props {
    canvas?: any;
}

export default React.memo(function LeftBar({ canvas }: Props) {
    const dispatch = useDispatch();
    const { options } = useSelector((state: any) => state.editor_options);
    const keysMap: any = options?.keysMap || {};

    const [menu, setMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    /* functions */
    const onMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
        setMenu(!menu);
    };

    async function onItem(type: string, data?: any) {
        if (type === 'title') {
            let obj: any = new fabric.Textbox('Text', {
                fontSize: 45,
                fontFamily: 'Roboto',
                textAlign: 'center',
                fontStyle: 'normal',
                fill: '#000000',
                width: 180,
                height: 136,
                selectable: true,
                editable: false
            });

            canvas.add(obj);
            canvas.centerObject(obj);
            canvas.setActiveObject(obj);
        } else if (type === 'tag') {
            let obj: any = new fabric.Textbox('{{ TAG }}', {
                name: 'activity-tag',
                fontSize: 45,
                fontFamily: 'Roboto',
                textAlign: 'center',
                fontStyle: 'normal',
                fill: '#000000',
                width: 180,
                height: 136,
                selectable: true,
                editable: false
            });

            canvas.add(obj);
            canvas.centerObject(obj);
            canvas.setActiveObject(obj);
        } else if (type === 'image') {
            const type = data.substring('data:image/'.length, data.indexOf(';base64')) || '';

            let img = new Image();
            img.onload = function () {
                if (type.includes('svg')) {
                    const base64 = data.split(',')[1];
                    const svg = window.atob(base64);

                    fabric.loadSVGFromString(String(svg), function (objects, options) {
                        let obj: any = fabric.util.groupSVGElements(objects, options);

                        canvas.add(obj);
                        canvas.centerObject(obj);
                        canvas.setActiveObject(obj);
                    });
                } else {
                    let obj: any = new fabric.Image(img, {
                        fill: '#fffffff',
                        selectable: true
                    });

                    canvas.add(obj);
                    canvas.centerObject(obj);
                    canvas.setActiveObject(obj);
                }
            };
            img.src = data;
        } else if (type === 'square') {
            let obj: any = new fabric.Rect({
                fill: '#eff0f6',
                width: 400,
                height: 400,
                selectable: true
            });

            canvas.add(obj);
            canvas.centerObject(obj);
            canvas.setActiveObject(obj);
        } else if (type === 'circle') {
            let obj: any = new fabric.Circle({
                fill: '#eff0f6',
                radius: 400,
                selectable: true
            });

            canvas.add(obj);
            canvas.centerObject(obj);
            canvas.setActiveObject(obj);
        } else if (type === 'line') {
            let obj: any = new fabric.Line([700, 0, 0, 0], {
                stroke: '#0000000',
                selectable: true
            });

            canvas.add(obj);
            canvas.centerObject(obj);
            canvas.setActiveObject(obj);
        } else if (type === 'qr_code') {
            const base64 = samples['qr_code'];
            const svg = window.atob(base64);

            fabric.loadSVGFromString(String(svg), function (objects, options) {
                let obj: any = fabric.util.groupSVGElements(objects, options);
                obj.name = 'qr_code';

                canvas.add(obj);
                canvas.centerObject(obj);
                canvas.setActiveObject(obj);
            });
        } else if (type === 'signature') {
            const type = data.substring('data:image/'.length, data.indexOf(';base64')) || '';

            let img = new Image();
            img.onload = function () {
                if (type.includes('svg')) {
                    return dispatch(
                        notifyActions.open({
                            type: 'warning',
                            message: 'Signature image should be in jpeg, jpg or png format'
                        })
                    );
                } else {
                    let obj: any = new fabric.Image(img, {
                        fill: '#fffffff',
                        selectable: true
                    });
                    obj.name = 'signature';

                    canvas.add(obj);
                    canvas.centerObject(obj);
                    canvas.setActiveObject(obj);
                }
            };
            img.src = data;
        }

        canvas.requestRenderAll();
    }

    function onImage(e: any, type: string) {
        let files = e.target.files;
        const reader = new FileReader();

        if (files[0]) {
            reader.readAsDataURL(files[0]);

            reader.onload = async () => {
                onItem(type, reader.result);
            };
        }
    }

    return (
        <div className="left-bar">
            <IconButton
                data-active={!keysMap[17]}
                tooltip={{ title: 'Cursor ( Release Ctrl )', placement: 'right' }}
                onClick={() => onItem('cursor')}>
                <Icon className="icon">arrow_selector_tool</Icon>
            </IconButton>
            <IconButton
                data-active={keysMap[17]}
                tooltip={{ title: 'Pan ( Hold Ctrl )', placement: 'right' }}
                onClick={() => onItem('pan')}>
                <Icon className="icon w-600">pan_tool</Icon>
            </IconButton>

            <div className="divider"></div>
            <ClickAwayListener onClickAway={() => setMenu(false)}>
                <div className="items">
                    <IconButton
                        onClick={onMenu}
                        tooltip={{ title: menu ? '' : 'Shapes', placement: 'right' }}>
                        <Icon className="icon w-600">shapes</Icon>
                    </IconButton>

                    <Popper
                        className="popper sm"
                        open={menu}
                        anchorEl={anchorEl}
                        placement="right-start">
                        <IconButton
                            tooltip={{ title: 'Rectangle', placement: 'right' }}
                            onClick={() => onItem('square')}>
                            <Icon className="icon w-600">square</Icon>
                        </IconButton>
                        <IconButton
                            tooltip={{ title: 'Circle', placement: 'right' }}
                            onClick={() => onItem('circle')}>
                            <Icon className="icon w-600">circle</Icon>
                        </IconButton>
                        <IconButton
                            tooltip={{ title: 'Line', placement: 'right' }}
                            onClick={() => onItem('line')}>
                            <Icon className="icon w-600 line-shape">horizontal_rule</Icon>
                        </IconButton>
                    </Popper>
                </div>
            </ClickAwayListener>
            <IconButton
                tooltip={{ title: 'Text', placement: 'right' }}
                onClick={() => onItem('title')}>
                <Icon className="icon w-600">title</Icon>
            </IconButton>
            <IconButton tooltip={{ title: 'Image', placement: 'right' }} component="label">
                <input hidden accept="image/*" type="file" onChange={(e) => onImage(e, 'image')} />
                <Icon className="icon w-600">image</Icon>
            </IconButton>

            <div className="divider"></div>
            <IconButton
                tooltip={{ title: 'Tag', placement: 'right' }}
                onClick={() => onItem('tag')}>
                <Icon className="icon w-600">data_object</Icon>
            </IconButton>
            <IconButton
                tooltip={{ title: 'Qr Code', placement: 'right' }}
                onClick={() => onItem('qr_code')}>
                <Icon className="icon w-600">qr_code</Icon>
            </IconButton>
            <IconButton tooltip={{ title: 'Signature', placement: 'right' }} component="label">
                <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e) => onImage(e, 'signature')}
                />
                <Icon className="icon w-600">draw</Icon>
            </IconButton>
        </div>
    );
});
