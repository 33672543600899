import React from 'react';
import Icon from './icon';

interface Props {
    id?: string;
    accept?: string;
    className?: string;
    multiple?: boolean;
    onData: any;
}

export default function FileUpload({
    id = 'file-upload',
    accept = 'text/csv',
    multiple = false,
    onData,
    className
}: Props) {
    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        onData(files);
    };

    const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        onData(files);
    };

    return (
        <div onDrop={onDrop} className={`file-upload ${className ? className : ''}`}>
            <label className="upload-label" htmlFor={id}>
                <Icon className="icon">upload_file</Icon>
                <p className="text">Upload file{multiple ? 's' : ''}</p>
                <input
                    id={id}
                    accept={accept}
                    multiple={multiple}
                    type="file"
                    hidden
                    onChange={onUpload}
                />
            </label>
        </div>
    );
}
