import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { analyticsProps } from './interfaces/dashboard.interface';

const dashboardServices = createApi({
    reducerPath: 'dashboard-service',
    baseQuery: baseQuery,
    tagTypes: ['Analytics'],
    endpoints: (builder) => ({
        analytics: builder.query({
            query: ({ org_id, date }: analyticsProps) => ({
                method: 'GET',
                url: `/dashboard/${org_id}/analytics`,
                params: { date }
            })
        })
    })
});

export default dashboardServices;
