import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileUpload from '../../../components/file-upload';
import Papa from 'papaparse';
import utils from '../../../utils';
import { notifyActions } from '../../../redux/reducers/notify';
import PageTable from '../../../components/page-table';
import { modalTypes } from '../..';
import { modalActions } from '../../../redux/reducers/modal';
import { DialogActions, Tooltip } from '@mui/material';
import Button from '../../../components/custom/button';
import rewardServices from '../../../services/reward.services';
import activityTagServices from '../../../services/activity-tags.services';
import activityBatchServices from '../../../services/activity-batch.services';
import receiverUtils from '../../../utils/receiver';
import Icon from '../../../components/icon';
import TextField from '../../../components/custom/text-field';

export default function UploadReceivers() {
    const dispatch = useDispatch();
    const { activity } = useSelector((state: any) => state.activity);
    const { user: authUser } = useSelector((state: any) => state.user);
    const { modalProps } = useSelector((state: any) => state.modal);
    const props = modalProps[modalTypes.UPLOAD_RECEIVERS];

    const [columns, setColumns] = React.useState({});
    const [data, setData] = React.useState([]);
    const [isValid, setIsValid] = React.useState(false);

    /* mutation */
    const [
        updateRewards,
        { error: rewardsUpdateError, isError: isRewardsUpdateError, isLoading: isTagsUpdating }
    ]: any = rewardServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isRewardsUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: rewardsUpdateError.data?.message
                })
            );
        }
    }, [rewardsUpdateError, isRewardsUpdateError]);

    /* functions */
    const onFileData = (files: any[]) => {
        if (files?.length > 0) {
            const headers: any = {};

            for (const file of files) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    delimiter: ',',
                    dynamicTyping: true,
                    transformHeader: (header: any) => {
                        header = header.replaceAll('*', '');
                        let _header = utils.snakeCase(header);

                        if (!headers[_header]) {
                            headers[_header] = header;
                        }
                        return _header;
                    },
                    complete(results) {
                        if (!headers['EMAIL'] || !headers['NAME']) {
                            return dispatch(
                                notifyActions.open({
                                    type: 'info',
                                    message: 'Name and email columns are required'
                                })
                            );
                        }

                        const parsed: any = results?.data.map((details: any) => {
                            return {
                                name: details.NAME,
                                email: details.EMAIL ? details.EMAIL.trim().toLowerCase() : '',
                                details: details
                            };
                        });

                        setColumns(headers);
                        setValidData(parsed);

                        dispatch(
                            modalActions.update({
                                type: modalTypes.UPLOAD_RECEIVERS,
                                props: { ...props, maxWidth: 'lg' }
                            })
                        );
                    },
                    error: (error) => {
                        console.error(error);
                        dispatch(
                            notifyActions.open({
                                type: 'error',
                                message: `File parsing error. ${error}`
                            })
                        );
                    }
                });
            }
        }
    };

    const setValidData = async (records) => {
        let valid = true;

        for (let i = 0; i < records.length; i++) {
            const record = records[i];
            const result: any = await receiverUtils.validate(record);

            if (result?.error) {
                valid = false;
                record['details']['VALID'] = result.error;
            } else {
                record['details']['VALID'] = true;
            }
        }

        setData(records);
        setIsValid(valid);
    };

    const onSave = async () => {
        let formattedData = data.map((record: any) => {
            let obj = { ...record };

            delete obj.details['VALID'];

            return obj;
        });

        const tags = Object.entries(columns).map(([key, value]: any, i) => {
            return {
                name: value,
                tag: key
            };
        });

        const result = await updateRewards({
            activity_id: activity?.id,
            receivers: formattedData,
            org_id: authUser.organization_id,
            batch: props?.batch !== '0' ? props?.batch : null,
            tags
        });

        if (result?.data?.status) {
            dispatch(
                notifyActions.open({
                    type: result?.data?.status,
                    message: result.data?.message
                })
            );
        }

        if (result?.data?.status === 'success') {
            dispatch(activityBatchServices.util.invalidateTags(['ActivityBatches']));
            dispatch(modalActions.hide());
        }
    };

    const renderValidCell = ({ data }) => {
        const rowData = data['details'];

        return rowData['VALID'] === true ? (
            <Tooltip className="icon-wrapper" title={'Data record is valid'}>
                <div>
                    <Icon className="validity-icon">check_circle</Icon>
                </div>
            </Tooltip>
        ) : (
            <Tooltip className="icon-wrapper" title={rowData['VALID']}>
                <div>
                    <Icon className="validity-icon invalid">cancel</Icon>
                </div>
            </Tooltip>
        );
    };

    const RenderColumn = ({ data, column }: any) => {
        const rowData = data.details;

        return rowData[column];
    };

    /* data handling */
    const tableOptions = React.useMemo(() => {
        const template: any = {
            headers: [],
            cells: []
        };

        template.headers = [
            { render: 'Validation' },
            ...Object.values(columns).map((i: any) => {
                return { render: i };
            })
        ];
        template.cells = [
            {
                render: renderValidCell
            },
            ...Object.keys(columns).map((column: any) => {
                return { render: ({ data }) => <RenderColumn data={data} column={column} /> };
            })
        ];

        return template;
    }, [columns, data]);

    return (
        <div className="modal-container upload-receivers-container">
            <div className="modal-content">
                {data.length > 0 ? (
                    <PageTable
                        className="table"
                        rows={data}
                        headers={tableOptions.headers}
                        cells={tableOptions.cells}
                        hidePagination
                    />
                ) : (
                    <FileUpload onData={onFileData} multiple />
                )}
            </div>

            <DialogActions className="modal-buttons">
                <Button disabled={isTagsUpdating} onClick={() => dispatch(modalActions.hide())}>
                    Cancel
                </Button>
                <Button
                    disabled={!isValid || data.length === 0}
                    onClick={onSave}
                    loading={isTagsUpdating}
                    variant="contained">
                    Add Receivers
                </Button>
            </DialogActions>
        </div>
    );
}
