import { createSlice } from '@reduxjs/toolkit';

interface actionsProps {
    payload: {
        element?: string | number;
        is_template?: boolean;
        type?: 'ELEMENT' | 'POST';
    };
}

const initState: any = {
    open: false,
    is_template: false,
    element: '',
    type: 'ELEMENT'
};

const editorSlice = createSlice({
    name: 'editor-modal',
    initialState: initState,
    reducers: {
        open(state, { payload }: actionsProps) {
            state.open = true;
            state.type = payload?.type || 'ELEMENT';
            if (payload?.element) state.element = payload?.element;
            if (payload?.is_template) state.is_template = payload?.is_template;
        },
        close(state) {
            state.open = false;
            state.is_template = false;
            state.element = '';
            state.type = '';
        },
        update(state, { payload }: actionsProps) {
            if (payload?.type) state.type = payload?.type;
            if (payload?.element) state.element = payload?.element;
            if (payload?.is_template !== undefined && payload?.is_template !== null) {
                state.is_template = payload?.is_template;
            }
        }
    }
});

export const editorActions = editorSlice.actions;

export default editorSlice.reducer;
