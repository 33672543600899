import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './layout/navbar';
import './styles/app.scss';
import 'material-symbols';
import loadable from '@loadable/component';
import LogoutContainer from './components/logout';
import NotifyContainer from './components/notify';
import BackdropContainer from './components/back-drop';
import EditorContainer from './editor/index';
import { useDispatch, useSelector } from 'react-redux';
import ProtectedRoute from './routes';
import NotFound from './pages/not-found';
import ResetPassword from './pages/reset-password';
import { fetchUser } from './redux/reducers/user';
import ModalContainer from './modals';
import * as buffer from 'buffer';

const SignIn = loadable(() => import('./pages/signin'));
const SignUp = loadable(() => import('./pages/signup'));
const IssuerProfile = loadable(() => import('./pages/issuer-profile'));
const ForgotPassword = loadable(() => import('./pages/forgot-password'));
const Home = loadable(() => import('./pages/home'));
const Activities = loadable(() => import('./pages/activities'));
const ActivityEdit = loadable(() => import('./pages/activities/edit'));
const OrgProfile = loadable(() => import('./pages/settings/profile'));
const OrgProfileEdit = loadable(() => import('./pages/settings/profile/edit'));
const Users = loadable(() => import('./pages/settings/users'));
const Settings = loadable(() => import('./pages/settings'));
const Rewards = loadable(() => import('./pages/settings/rewards'));
const OrganizationEdit = loadable(() => import('./pages/organizations/edit'));
const Elements = loadable(() => import('./pages/elements'));

// @ts-ignore
window.Buffer = buffer.Buffer;

function App() {
    const navigator = useNavigate();
    const dispatch = useDispatch<any>();
    const { loading: userLoading, user: authUser } = useSelector((state: any) => state.user);

    const [logged, setLogged] = React.useState(false);

    React.useEffect(() => {
        dispatch(fetchUser());
    }, []);

    React.useEffect(() => {
        const routes = ['', 'register', 'issuer-profile', 'forgot-password', 'reset-password'];

        if (!userLoading) {
            if (authUser?.id) {
                setLogged(true);
                const path = window.location.pathname.split('/');

                if (path[1].includes('register')) {
                    setLogged(false);
                }
            } else {
                const path = window.location.pathname.split('/');

                if (!routes.includes(path[1])) navigator('/');
                setLogged(false);
            }
        }
    }, [userLoading, authUser]);

    return (
        <div className="app">
            {logged ? (
                <React.Fragment>
                    <Navbar />
                    <main className="main">
                        <Routes>
                            <Route path="/" element={<Home />} />

                            <Route
                                path="/organizations/edit"
                                element={
                                    <ProtectedRoute role={authUser?.role} allow={['SUPER_ADMIN']}>
                                        <OrganizationEdit />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/elements"
                                element={
                                    <ProtectedRoute
                                        role={authUser?.role}
                                        allow={['ORG_ADMIN', 'ORG_USER']}>
                                        <Elements />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/activities"
                                element={
                                    <ProtectedRoute
                                        role={authUser?.role}
                                        allow={['ORG_ADMIN', 'ORG_USER']}>
                                        <Activities />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/activities/edit"
                                element={
                                    <ProtectedRoute
                                        role={authUser?.role}
                                        allow={['ORG_ADMIN', 'ORG_USER']}>
                                        <ActivityEdit />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/settings"
                                element={
                                    <ProtectedRoute
                                        role={authUser?.role}
                                        allow={['ORG_ADMIN', 'ORG_USER']}>
                                        <Settings />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/settings/profile"
                                element={
                                    <ProtectedRoute
                                        role={authUser?.role}
                                        allow={['ORG_ADMIN', 'ORG_USER']}>
                                        <OrgProfile />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/settings/profile/edit"
                                element={
                                    <ProtectedRoute role={authUser?.role} allow={['ORG_ADMIN']}>
                                        <OrgProfileEdit />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/settings/users"
                                element={
                                    <ProtectedRoute
                                        role={authUser?.role}
                                        allow={['ORG_ADMIN', 'ORG_USER']}>
                                        <Users />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/settings/rewards"
                                element={
                                    <ProtectedRoute
                                        role={authUser?.role}
                                        allow={['ORG_ADMIN', 'ORG_USER']}>
                                        <Rewards />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </main>
                </React.Fragment>
            ) : (
                <Routes>
                    <Route path="/" element={<SignIn />} />
                    <Route path="/register" element={<SignUp />} />
                    <Route path="/issuer-profile">
                        <Route path=":org_id" element={<IssuerProfile />} />
                    </Route>
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Routes>
            )}

            {/* logout container */}
            <LogoutContainer />
            {/* popup modals container */}
            <ModalContainer />
            {/* notify container */}
            <NotifyContainer />
            {/* backdrop container */}
            <BackdropContainer />
            {/* backdrop container */}
            <EditorContainer />
        </div>
    );
}

export default App;
