import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import TextField from '../../../components/form/text-field';
import MuiTextField from '../../../components/custom/text-field';
import { DialogActions, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import orgServices from '../../../services/org.services';
import { notifyActions } from '../../../redux/reducers/notify';
import userServices from '../../../services/user.services';
import IconButton from '../../../components/custom/icon-button';
import Icon from '../../../components/icon';

const initialValues = {
    name: '',
    domain: '',
    admin_name: '',
    admin_email: '',
    phone: ''
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    domain: Yup.string(),
    admin_name: Yup.string().required('Admin name is required'),
    admin_email: Yup.string().email().required('Admin email is required'),
    phone: Yup.string()
});

export default function Organization() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);
    const [open, setOpen] = React.useState(false);

    /* mutation */
    const [
        create,
        { error: orgCreateError, isError: isOrgCreateError, isLoading: isCreating }
    ]: any = orgServices.useCreateMutation();

    const [signup, { error: signupError, isError: isSignupError, isLoading: isSigningUp }]: any =
        userServices.useSignupMutation();

    /* error handling */
    React.useEffect(() => {
        if (isOrgCreateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: orgCreateError.data?.message
                })
            );
        }
        if (isSignupError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: signupError.data?.message
                })
            );
        }
    }, [orgCreateError, isOrgCreateError, signupError, isSignupError]);

    const onSubmit = async (values: any) => {
        try {
            const org = await create({
                name: values.name,
                phone: values.phone,
                domain: values.domain,
                user_id: authUser.id,
                user: {
                    email: values.admin_email,
                    invitee_id: authUser.id,
                    first_name: values.admin_name
                }
            });

            if (org.data.status === 'success') {
                dispatch(
                    notifyActions.open({
                        type: 'success',
                        message: org.data?.message
                    })
                );

                dispatch(orgServices.util.invalidateTags(['Orgs', 'OrgOptions']));
                dispatch(modalActions.hide());
            }
        } catch (error: any) {
            if (error?.error) {
                return dispatch(
                    notifyActions.open({
                        type: 'error',
                        message: error.error
                    })
                );
            }
        }
    };

    return (
        <div className="modal-container organization-modal">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <TextField
                        name="name"
                        label="Organization Name"
                        placeholder="Enter Organization Name"
                        required
                    />
                    <TextField name="domain" label="Domain" placeholder="Enter Domain" />
                    <TextField
                        name="admin_name"
                        label="Org. Admin Name"
                        placeholder="Enter Org. Admin Name"
                        required
                    />
                    <TextField
                        name="admin_email"
                        type="email"
                        label="Org. Admin Email"
                        placeholder="Enter Org. Admin Email"
                        required
                    />
                    <TextField
                        name="admin_phone"
                        label="Org. Telephone Number"
                        placeholder="Enter Org. Telephone Number"
                    />
                </div>

                <DialogActions className="modal-buttons">
                    <Button
                        disabled={isSigningUp || isCreating}
                        onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isSigningUp || isCreating} variant="contained">
                        Add Organization
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
