import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    createRewardPostProps,
    generateRewardPostProps,
    updateRewardPostProps
} from './interfaces/reward-post.interface';

const rewardPostServices = createApi({
    reducerPath: 'reward-post-service',
    baseQuery: baseQuery,
    tagTypes: ['RewardPost', 'RewardPosts', 'RewardPostOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ org_id, ...props }: createRewardPostProps) => ({
                method: 'POST',
                url: `/reward-posts/${org_id}/create`,
                body: props
            }),
            invalidatesTags: [{ type: 'RewardPosts' }, { type: 'RewardPostOptions' }]
        }),
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/reward-posts/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'RewardPost' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/reward-posts/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'RewardPosts' }, { type: 'RewardPostOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/reward-posts/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'RewardPostOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateRewardPostProps) => ({
                method: 'PATCH',
                url: `/reward-posts/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [
                { type: 'RewardPosts' },
                { type: 'RewardPostOptions' },
                { type: 'RewardPost' }
            ]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/reward-posts/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [
                { type: 'RewardPosts' },
                { type: 'RewardPostOptions' },
                { type: 'RewardPost' }
            ]
        }),
        generate: builder.mutation({
            query: ({ org_id, ...props }: generateRewardPostProps) => ({
                method: 'POST',
                url: `/reward-posts/${org_id}/generate`,
                body: props
            })
        })
    })
});

export default rewardPostServices;
