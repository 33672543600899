import React from 'react';
import TopBar from './top-bar';

import Workspace from './workspace';
import RightBar from './right-bar';

export default function ModelLayout() {
    const cameraRef: any = React.useRef(null);
    const [model, setModel] = React.useState('');

    return (
        <div className="tools layout-3d">
            <TopBar model={model} setModel={setModel} cameraRef={cameraRef} />

            <div className="content">
                <Workspace model={model} setModel={setModel} cameraRef={cameraRef} />
                <RightBar model={model} />
            </div>
        </div>
    );
}
