import * as React from 'react';
import MuiList, { ListProps } from '@mui/material/List';
import { TransitionGroup } from 'react-transition-group';

export default function List({ className, children, ...props }: ListProps) {
    return (
        <MuiList className={`list ${className ? className : ''}`} {...props}>
            <TransitionGroup className="group-list">{children}</TransitionGroup>
        </MuiList>
    );
}
