import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorActions } from '../redux/reducers/editor';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CanvasLayout from './layout/canvas';
import ModelLayout from './layout/model';
import 'fabric-history';
import { modalActions } from '../redux/reducers/modal';
import { modalTypes } from '../modals';
import { elementActions } from '../redux/reducers/element';
import { editorOptionsActions } from '../redux/reducers/editor/options';

export default function Editor() {
    const { type } = useSelector((state: any) => state.element);
    const { open } = useSelector((state: any) => state.editor);
    const dispatch = useDispatch();

    React.useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const onResize = () => {
        if (!open) return null;

        if (window.innerWidth <= 1024) {
            dispatch(elementActions.reset());
            dispatch(editorOptionsActions.reset());
            dispatch(editorActions.close());

            dispatch(
                modalActions.show({
                    type: modalTypes.INCOMPATIBLE_EDITOR
                })
            );
        } else {
            /* close all opened popups */
            dispatch(modalActions.hide());
        }
    };

    if (!open) return null;

    return (
        <div className="design-editor-wrap">
            <Dialog className="editor-modal" fullWidth={true} maxWidth="xl" open={true}>
                <DialogContent className="editor-modal-content">
                    {type === 'MODEL' ? <ModelLayout /> : <CanvasLayout />}
                </DialogContent>
            </Dialog>
        </div>
    );
}
