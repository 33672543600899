import React from 'react';
import Button from '../../../components/custom/button';
import { DialogActions } from '@mui/material';
import ImageUpload from '../../../components/image-upload';
import { v4 as uuid } from 'uuid';
import { modalActions } from '../../../redux/reducers/modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalTypes } from '../..';
import userUtils from '../../../utils/users';

export default function User() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);
    const { modalProps } = useSelector((state: any) => state.modal);
    const [image, setImage] = React.useState('');
    const { data: user } = modalProps[modalTypes.USER];

    const data = React.useMemo(() => {
        if (user?.avatar) setImage(user.avatar);

        return [
            {
                label: 'First Name',
                value: user?.first_name || '-'
            },
            {
                label: 'Last Name',
                value: user?.last_name || '-'
            },
            {
                label: 'Username',
                value: user?.username || '-'
            },
            {
                label: 'Email',
                value: user?.email || '-'
            },
            {
                label: 'Role',
                value: user?.organizations?.[0]?.role
                    ? userUtils.types[user?.organizations?.[0]?.role]
                    : '-'
            },
            {
                label: 'State',
                value: user?.organizations?.[0]?.state
                    ? userUtils.states[user?.organizations?.[0]?.state]
                    : '-'
            }
        ];
    }, [user]);

    const onImage = (event: any, image: string) => {
        setImage(image);
    };

    const onEdit = () => {
        dispatch(modalActions.hide());

        dispatch(
            modalActions.show({
                type: modalTypes.EDIT_USER,
                props: { data: user }
            })
        );
    };

    const onDelete = async () => {
        dispatch(modalActions.hide());

        dispatch(
            modalActions.show({
                type: modalTypes.DELETE_USER,
                props: { id: user.id }
            })
        );
    };

    return (
        <div className="modal-container user-modal">
            <div className="modal-content">
                <div className="image-section">
                    <ImageUpload
                        className="profile-image"
                        image={image}
                        onChange={onImage}
                        preview
                        previewIcon="mood"
                    />
                </div>

                {data.map((item) => {
                    return (
                        <div key={uuid()} className="item-wrap">
                            <p className="title">{item.label}</p>
                            <p className="value">{item.value}</p>
                        </div>
                    );
                })}
            </div>

            <DialogActions className="modal-buttons">
                <Button onClick={onDelete} role={authUser?.role} restrict={['ORG_USER']}>
                    Delete
                </Button>
                <Button
                    role={authUser?.role}
                    restrict={['ORG_USER']}
                    variant="contained"
                    onClick={onEdit}>
                    Edit User
                </Button>
            </DialogActions>
        </div>
    );
}
