import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import TextField from '../../../components/form/text-field';
import { DialogActions } from '@mui/material';
import { modalActions } from '../../../redux/reducers/modal';
import { useDispatch, useSelector } from 'react-redux';
import { notifyActions } from '../../../redux/reducers/notify';
import { modalTypes } from '../..';
import rewardPurchaseServices from '../../../services/reward-purchase.services';

const validationSchema = Yup.object().shape({
    rewards: Yup.number().integer().required('Reward allocations are required'),
    expire_date: Yup.date().required('Expire date is required'),
    private_comment: Yup.string(),
    public_comment: Yup.string()
});

export default function RewardPurchase() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);

    const { modalProps } = useSelector((state: any) => state.modal);
    const props = modalProps[modalTypes.REWARD_PURCHASE_ADD];

    const data = React.useMemo(() => {
        const template = {
            rewards: '',
            expire_date: '',
            private_comment: '',
            public_comment: ''
        };

        if (props?.data?.rewards) template.rewards = props?.data?.rewards;
        if (props?.data?.expire_date) template.expire_date = props?.data?.expire_date;
        if (props?.data?.private_comment) template.private_comment = props?.data?.private_comment;
        if (props?.data?.public_comment) template.public_comment = props?.data?.public_comment;

        return template;
    }, [props]);

    /* mutation */
    const [
        create,
        {
            error: rewardPurchaseCreateError,
            isError: isRewardPurchaseCreateError,
            isLoading: isRewardPurchaseCreating
        }
    ]: any = rewardPurchaseServices.useCreateMutation();
    const [
        update,
        {
            error: rewardPurchaseUpdateError,
            isError: isRewardPurchaseUpdateError,
            isLoading: isRewardPurchaseUpdating
        }
    ]: any = rewardPurchaseServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isRewardPurchaseCreateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: rewardPurchaseCreateError.data?.message
                })
            );
        }
        if (isRewardPurchaseUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: rewardPurchaseUpdateError.data?.message
                })
            );
        }
    }, [
        rewardPurchaseServices,
        isRewardPurchaseCreateError,
        rewardPurchaseUpdateError,
        isRewardPurchaseUpdateError
    ]);

    const onSubmit = async (values: any) => {
        let result: any;

        if (props?.data?.id) {
            result = await update({
                ...values,
                id: props?.data?.id,
                org_id: props?.organization_id ?? authUser.organization_id,
                user_id: authUser.id
            });
        } else {
            result = await create({
                ...values,
                purchase_date: new Date().toString(),
                org_id: props?.organization_id ?? authUser.organization_id,
                user_id: authUser.id
            });
        }

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container user-add-modal">
            <Form
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <TextField
                        className="no-arrows"
                        name="rewards"
                        type="number"
                        placeholder="Reward Allocation"
                        label="Reward Allocation"
                        InputProps={{ inputProps: { min: 0 } }}
                    />
                    <TextField
                        name="expire_date"
                        type="date"
                        placeholder="Reward Expiry Date"
                        label="Reward Expiry Date"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        multiline
                        minRows={5}
                        name="private_comment"
                        placeholder="Super Admin Comment"
                        label="Super Admin Comment"
                    />
                    <TextField
                        multiline
                        minRows={5}
                        name="public_comment"
                        placeholder="Public Comment"
                        label="Public Comment"
                    />
                </div>

                <DialogActions className="modal-buttons">
                    <Button
                        loading={isRewardPurchaseCreating || isRewardPurchaseUpdating}
                        onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton
                        loading={isRewardPurchaseCreating || isRewardPurchaseUpdating}
                        variant="contained">
                        {props?.button || 'Add Reward'}
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
