import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '../../../../components/custom/text-field';
import { editorOptionsActions } from '../../../../redux/reducers/editor/options';
import { elementActions } from '../../../../redux/reducers/element';
import ColorPicker from '../../../components/color-picker';

interface Props {
    model?: any;
}

export default function General({ model }: Props) {
    const dispatch = useDispatch();
    const element = useSelector((state: any) => state.element);
    const { settings } = useSelector((state: any) => state.editor_options);
    const [worksheetData, setWorksheetData] = React.useState({
        background: '#ffffff',
        opacity: 100
    });
    const data = settings?.general;

    const update = ({ type, value }: { type: string; value: any }) => {
        dispatch(
            editorOptionsActions.set({
                settings: {
                    ...settings,
                    general: {
                        ...data,
                        [type]: value
                    }
                }
            })
        );
    };

    const updateElement = ({ type, value }: { type: string; value: any }) => {
        dispatch(elementActions.set({ [type]: value }));
    };

    const updateElementAttributes = ({ type, value }: { type: string; value: any }) => {
        dispatch(
            elementActions.set({
                other_attributes: { ...element?.other_attributes, [type]: value }
            })
        );
    };

    return (
        <div className="editor-settings">
            <TextField
                label={'Name'}
                placeholder={'Name'}
                value={element?.name}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => updateElement({ type: 'name', value: e.target.value })}
                required
            />

            <TextField
                label="Description"
                placeholder="Description"
                value={element?.description}
                multiline
                minRows={4}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => updateElement({ type: 'description', value: e.target.value })}
                required
            />

            <TextField
                label="Issued Date"
                placeholder="Issued Date"
                type="date"
                value={
                    element?.other_attributes?.issued_date
                        ? moment(element?.other_attributes?.issued_date).format('YYYY-MM-DD')
                        : ''
                }
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                    updateElementAttributes({ type: 'issued_date', value: e.target.value })
                }
                required
            />

            <p className="title">Scale & Position</p>
            <TextField
                label="Scale"
                placeholder="1"
                type="number"
                value={data?.scale}
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => update({ type: 'scale', value: Number(e.target.value) })}
            />

            <div className="double-wrap">
                <TextField
                    label="X Position"
                    placeholder="0"
                    type="number"
                    value={data?.positionX}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => update({ type: 'positionX', value: e.target.value })}
                />
                <TextField
                    label="Y Position"
                    placeholder="0"
                    type="number"
                    value={data?.positionY}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => update({ type: 'positionY', value: e.target.value })}
                />
                <TextField
                    label="Z Position"
                    placeholder="0"
                    type="number"
                    value={data?.positionZ}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => update({ type: 'positionZ', value: e.target.value })}
                />
            </div>

            <p className="title">Rotation (Radians)</p>
            <div className="double-wrap">
                <TextField
                    label="X Rotation"
                    placeholder="0"
                    type="number"
                    value={data?.rotationX}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => update({ type: 'rotationX', value: e.target.value })}
                />
                <TextField
                    label="Y Rotation"
                    placeholder="0"
                    type="number"
                    value={data?.rotationY}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => update({ type: 'rotationY', value: e.target.value })}
                />
                <TextField
                    label="Z Rotation"
                    placeholder="0"
                    type="number"
                    value={data?.rotationZ}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => update({ type: 'rotationZ', value: e.target.value })}
                />
            </div>

            {/* <ColorPicker
                opacity={worksheetData?.opacity}
                color={worksheetData?.background}
                setColor={(color: string) => update({ type: 'background', value: color })}
                setOpacity={(opacity: number) => update({ type: 'opacity', value: opacity })}
            /> */}
        </div>
    );
}
