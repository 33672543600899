import React from 'react';
import Button from '../../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../../redux/reducers/modal';
import { modalTypes } from '../../..';
import { notifyActions } from '../../../../redux/reducers/notify';
import rewardElementServices from '../../../../services/reward-element.services';

export default function DeleteRewardElement() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.DELETE_REWARD_ELEMENT];

    /* mutation */
    const [
        deleteRewardElement,
        {
            error: deleteRewardElementError,
            isError: isDeleteRewardElementError,
            isLoading: isDeleting
        }
    ]: any = rewardElementServices.useDeleteMutation();

    /* error handling */
    React.useEffect(() => {
        if (isDeleteRewardElementError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: deleteRewardElementError.data?.message
                })
            );
        }
    }, [deleteRewardElementError, isDeleteRewardElementError]);

    const onSubmit = async () => {
        const result = await deleteRewardElement({
            delete_id: props.id,
            org_id: authUser.organization_id
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container">
            <div className="modal-content">
                Are you sure you want to delete this reward element?
            </div>

            <DialogActions className="modal-buttons">
                <Button disabled={isDeleting} onClick={() => dispatch(modalActions.hide())}>
                    Cancel
                </Button>
                <Button
                    onClick={onSubmit}
                    loading={isDeleting}
                    className="bg-delete"
                    variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </div>
    );
}
