import React from 'react';
import General from './general';
import Shape from './shape';
import Text from './text';
import Tag from './tag';
import Image from './image';
import Attributes from './attributes';

const Containers = {
    general: General,
    title: Text,
    tag: Tag,
    shapes: Shape,
    image: Image,
    attributes: Attributes
};

interface Props {
    canvas: any;
    selected: 'title' | 'image' | 'tag' | 'shape' | 'attributes' | string;
}

export default function Settings({ canvas, selected }: Props) {
    const Container = Containers[selected];

    return Container ? <Container canvas={canvas} /> : null;
}
