import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalTypes } from '../..';
import TextField from '../../../components/custom/text-field';
import Icon from '../../../components/icon';
import PageTable from '../../../components/page-table';
import { notifyActions } from '../../../redux/reducers/notify';
import rewardServices from '../../../services/reward.services';

export default function RewardIssuances() {
    const dispatch = useDispatch();

    const { modalProps } = useSelector((state: any) => state.modal);
    const props = modalProps[modalTypes.REWARD_ISSUANCES];

    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState('');

    const searchOptions: any = React.useMemo(() => {
        const template: any = [
            { column: 'issue_code', value: props?.issue_code, like: false, operator: 'and' },
            { column: 'receiving_did', value: search, like: true },
            { column: 'receivers.name', value: search, like: true },
            { column: 'receivers.email', value: search, like: true },
            { column: 'receiverInvitations.key', value: search, like: true, as: 'invitations' }
        ];

        return template;
    }, [props, search]);

    /* query */
    const {
        data: rewards,
        refetch: refetchRewards,
        isLoading: isRewardsLoading,
        isFetching: isRewardsFetching,
        error: rewardsError,
        isError: isRewardsError
    }: any = rewardServices.useGetAllQuery({
        org_id: props?.org_id,
        page: page,
        limit: pageSize,
        find: searchOptions
    });

    const {
        data: options,
        refetch: refetchRewardsOptions,
        isLoading: isRewardsOptionsLoading,
        error: rewardsOptionsError,
        isError: isRewardsOptionsError
    }: any = rewardServices.useOptionsQuery({
        org_id: props?.org_id,
        limit: pageSize,
        find: searchOptions
    });

    /* error handling */
    React.useEffect(() => {
        if (isRewardsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: rewardsError.data?.message
                })
            );
        }
        if (isRewardsOptionsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: rewardsOptionsError.data?.message
                })
            );
        }
    }, [rewardsError, isRewardsError, rewardsOptionsError, isRewardsOptionsError]);

    /* cells */
    const renderNameCell = ({ data }: any) => {
        return data?.receivers?.name || '-';
    };

    const renderEmailCell = ({ data }: any) => {
        return data?.receivers?.email || '-';
    };

    const renderInviteCodeCell = ({ data }: any) => {
        const invitation = data?.invitations?.[0];

        return invitation?.key || '-';
    };

    const tableOptions = {
        headers: [
            { render: 'Name' },
            { render: 'Email' },
            { render: 'Issuance Code' },
            { render: 'Invite Code' },
            { render: 'DID' }
        ],
        cells: [
            { render: renderNameCell },
            { render: renderEmailCell },
            { render: 'issue_code' },
            { render: renderInviteCodeCell },
            { render: 'receiving_did' }
        ]
    };

    return (
        <div className="modal-container">
            <div className="modal-content">
                <TextField
                    size="small"
                    placeholder="Search"
                    className="input search"
                    InputProps={{
                        startAdornment: <Icon className="icon text-search">search</Icon>
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            <PageTable
                className="table"
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                rows={rewards?.data}
                options={options?.data}
                headers={tableOptions.headers}
                cells={tableOptions.cells}
                loading={isRewardsLoading}
                fetching={isRewardsFetching}
                optionsLoading={isRewardsOptionsLoading}
            />
        </div>
    );
}
