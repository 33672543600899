import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps,
    searchProps
} from './interfaces/common.interface';
import {
    generatedRewardProps,
    generateRewardProps,
    rewardReceiverDetailsProps,
    shareRewardProps,
    updateRewardProps
} from './interfaces/reward.interface';

const rewardServices = createApi({
    reducerPath: 'rewards-service',
    baseQuery: baseQuery,
    tagTypes: ['Rewards', 'Reward', 'RewardsOptions', 'RewardReceiver'],
    endpoints: (builder) => ({
        update: builder.mutation({
            query: ({ org_id, ...props }: updateRewardProps) => ({
                method: 'PUT',
                url: `/rewards/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'Rewards' }, { type: 'RewardsOptions' }, { type: 'Reward' }]
        }),
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/rewards/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'Reward' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, limit, find = {} }: searchProps) => ({
                method: 'GET',
                url: `/rewards/${org_id}/all/${page}`,
                params: {
                    limit,
                    ...find
                }
            }),
            providesTags: [{ type: 'Rewards' }, { type: 'RewardsOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/rewards/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'RewardsOptions' }]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/rewards/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'Rewards' }, { type: 'RewardsOptions' }, { type: 'Reward' }]
        }),
        deleteWallet: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/rewards/${org_id}/delete/wallet/${props.delete_id}`
            }),
            invalidatesTags: [{ type: 'Rewards' }, { type: 'RewardsOptions' }, { type: 'Reward' }]
        }),
        rewardReceiverDetails: builder.mutation({
            query: (props: rewardReceiverDetailsProps) => ({
                method: 'GET',
                url: `/rewards/${props.org_id}/receiver/${props.receiving_did}/${props.reward_id}`
            }),
            invalidatesTags: [{ type: 'RewardReceiver' }]
        }),
        generate: builder.mutation({
            query: ({ org_id, ...props }: generateRewardProps) => ({
                method: 'POST',
                url: `/rewards/${org_id}/generate`,
                body: props
            }),
            invalidatesTags: [{ type: 'Rewards' }, { type: 'RewardsOptions' }]
        }),
        generated: builder.mutation({
            query: ({ org_id, ...props }: generatedRewardProps) => ({
                method: 'POST',
                url: `/rewards/${org_id}/generated`,
                body: props
            }),
            invalidatesTags: [{ type: 'Rewards' }, { type: 'RewardsOptions' }]
        }),
        share: builder.mutation({
            query: ({ org_id, user_id, ...props }: shareRewardProps) => ({
                method: 'POST',
                url: `/rewards/${org_id}/share/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'Rewards' }, { type: 'RewardsOptions' }]
        })
    })
});

export default rewardServices;
