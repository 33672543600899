const {
    REACT_APP_NODE_ENV,
    REACT_APP_ORG_API_URL,
    REACT_APP_ZEDEID_API_URL,
    REACT_APP_ORG_APP_URL,
    REACT_APP_REWARDS_APP_URL,
    REACT_APP_VIEW_APP_URL
} = process.env;

const config = {
    node_env: REACT_APP_NODE_ENV as string,
    client_urls: {
        org_app: formatUrl(REACT_APP_ORG_APP_URL),
        rewards_app: formatUrl(REACT_APP_REWARDS_APP_URL),
        view_app: formatUrl(REACT_APP_VIEW_APP_URL)
    },
    server_urls: {
        mr_org_api: formatUrl(REACT_APP_ORG_API_URL),
        zedeid_api: formatUrl(REACT_APP_ZEDEID_API_URL)
    }
};

function formatUrl(url: any) {
    if (!url) return '';

    return url.slice(-1) === '/' ? url : url + '/';
}

export default config;
