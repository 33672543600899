import { Popper, ClickAwayListener } from '@mui/material';
import React from 'react';
import formatImage from '../functions/format-image';
import Icon from './icon';

interface Props {
    id?: string;
    image?: string;
    className?: string;
    onChange?: any;
    accept?: string;
    preview?: boolean;
    previewIcon?: any;
    [props: string]: any;
}

export default function ImageUpload({
    id,
    image,
    className,
    onChange,
    preview = false,
    previewIcon,
    accept = 'image/*',
    ...props
}: Props) {
    const [menu, setMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const inputRef: any = React.useRef(null);

    const onMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
        setMenu(!menu);
    };

    const onMenuItem = (type: any) => {
        if (type === 'upload') {
            if (inputRef.current) inputRef.current.click();
        }
        if (type === 'remove') {
            onChange(inputRef.current, '');
        }
    };

    const onFile = (event: any) => {
        let files = event.target.files;
        const reader = new FileReader();

        if (files[0]) {
            reader.readAsDataURL(files[0]);

            reader.onload = async () => {
                const base64 = await formatImage(reader.result);
                if (onChange) onChange(event, base64);
            };
        }
    };

    return (
        <div
            data-preview={preview}
            data-empty={!image}
            className={`image-upload ${className ? className : ''}`}
            {...props}>
            {image ? (
                <img src={image} alt="Image" className="image" />
            ) : preview ? (
                <div className="icon-wrap">
                    <Icon className="preview-icon">{previewIcon ?? 'image'}</Icon>
                </div>
            ) : (
                <div className="icon-wrap">
                    <Icon className="icon" variant="outlined">
                        upload_file
                    </Icon>
                    Upload Image
                </div>
            )}

            <input
                ref={inputRef}
                hidden
                type="file"
                name="image-upload"
                id={id ? id : 'image-upload'}
                accept={accept}
                onChange={onFile}
            />

            <ClickAwayListener onClickAway={() => setMenu(false)}>
                <div
                    data-disabled={preview}
                    data-active={menu}
                    className="overlap"
                    onClick={onMenu}>
                    <Icon className="edit-icon">edit</Icon>

                    <Popper
                        className="popper ml-30"
                        open={menu}
                        anchorEl={anchorEl}
                        placement="right">
                        <div className="item" onClick={() => onMenuItem('upload')}>
                            <Icon className="icon">upload</Icon>
                            <span className="title">Upload</span>
                        </div>
                        <div className="item" onClick={() => onMenuItem('remove')}>
                            <Icon className="icon">delete</Icon>
                            <span className="title">Remove</span>
                        </div>
                    </Popper>
                </div>
            </ClickAwayListener>
        </div>
    );
}
