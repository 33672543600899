import React from 'react';
import Button from '../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';

export default function IncompatibleEditor() {
    const dispatch = useDispatch();

    return (
        <div className="modal-container">
            <div className="modal-content">
                This screen resolution is not compatible for the editor.
            </div>

            <DialogActions className="modal-buttons">
                <Button onClick={() => dispatch(modalActions.hide())} variant="contained">
                    Ok
                </Button>
            </DialogActions>
        </div>
    );
}
