import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../../components/custom/button';
import { editorActions } from '../../../redux/reducers/editor';
import { editorOptionsActions } from '../../../redux/reducers/editor/options';
import { elementActions } from '../../../redux/reducers/element';
import Icon from '../../../components/icon';
import IconButton from '../../../components/custom/icon-button';
import { notifyActions } from '../../../redux/reducers/notify';
import _ from 'lodash';
import rewardElementServices from '../../../services/reward-element.services';
import rewardElementUtils from '../../../utils/rewards/element';

interface Props {
    model?: any;
    setModel?: any;
    cameraRef: any;
}

export default function TopBar({ model, setModel, cameraRef }: Props) {
    const dispatch = useDispatch();
    const {
        element: element_id,
        is_template,
        type: element_type
    } = useSelector((state: any) => state.editor);
    const element = useSelector((state: any) => state.element);
    const { settings } = useSelector((state: any) => state.editor_options);
    const { state: routerState } = useLocation();

    /* mutations */
    const [
        updateElement,
        { error: elementUpdateError, isError: isUpdateElementError, isLoading: isUpdatingElement }
    ]: any = rewardElementServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isUpdateElementError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementUpdateError?.data?.message
                })
            );
        }
    }, [elementUpdateError, isUpdateElementError]);

    /* functions */
    const onExit = () => {
        dispatch(elementActions.reset());
        dispatch(editorOptionsActions.reset());
        dispatch(editorActions.close());
    };

    const onSave = async (close?: boolean) => {
        const { name, description, attributes, type, other_attributes } = element;

        const valid = rewardElementUtils.validate({ element });
        if (valid?.error) {
            dispatch(notifyActions.open(valid.error));
            return;
        }

        const data = settings?.general;

        const reward_data = {
            scale: data?.scale,
            positionX: data?.positionX,
            positionY: data?.positionY,
            positionZ: data?.positionZ,
            cameraPositionX: cameraRef?.current.position.x,
            cameraPositionY: cameraRef?.current.position.y,
            cameraPositionZ: cameraRef?.current.position.z,
            cameraRotationX: cameraRef?.current.rotation._x,
            cameraRotationY: cameraRef?.current.rotation._y,
            cameraRotationZ: cameraRef?.current.rotation._z,
            rotationX: data?.rotationX,
            rotationY: data?.rotationY,
            rotationZ: data?.rotationZ,
            opacity: data?.opacity,
            background: data?.background,
            model
        };

        const result = await updateElement({
            id: element_id,
            name: name,
            description: description,
            custom_attributes: attributes,
            other_attributes: other_attributes,
            reward: reward_data
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            if (close) {
                dispatch(elementActions.reset());
                dispatch(editorOptionsActions.reset());
                dispatch(editorActions.close());
            }
        }
    };

    const onUpload = async (e) => {
        const file = e.target.files?.[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function () {
                if (reader.result) {
                    setModel(reader.result);
                }
            };
            reader.onerror = function (error) {
                console.log('Model loading error: ', error);
            };
        }
    };

    const onRemove = async () => {
        dispatch(elementActions.reset());
        dispatch(editorOptionsActions.reset());
        setModel('');
    };

    return (
        <div className="top-bar">
            <div className="section">
                <IconButton tooltip={{ title: 'Upload', placement: 'bottom' }} onClick={onUpload}>
                    <label className="upload-label">
                        <Icon className="icon w-500">upload</Icon>
                        <input type="file" hidden accept=".glb" onChange={onUpload} />
                    </label>
                </IconButton>
                <IconButton
                    disabled={!!routerState?.read_only}
                    tooltip={{ title: 'Remove', placement: 'bottom' }}
                    onClick={onRemove}>
                    <Icon className="icon w-500">delete</Icon>
                </IconButton>
            </div>
            <div className="section buttons">
                <Button onClick={onExit} variant="text" className="cancel">
                    Cancel
                </Button>
                <div className="divider"></div>
                <Button
                    disabled={!!routerState?.read_only}
                    onClick={() => onSave(true)}
                    variant="text"
                    className="save">
                    Save
                </Button>
            </div>
        </div>
    );
}
