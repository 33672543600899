import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fabric } from 'fabric';
import { notifyActions } from '../../../redux/reducers/notify';
import rewardElementServices from '../../../services/reward-element.services';
import _ from 'lodash';
import { elementActions } from '../../../redux/reducers/element';
import editorUtils from '../../../utils/editor';
import elementTemplateServices from '../../../services/element-templates.services';
import { editorOptionsActions } from '../../../redux/reducers/editor/options';
import rewardPostServices from '../../../services/reward-post.services';

interface Props {
    canvas: any;
    setCanvas: any;
}

export default function Workspace({ canvas = {}, setCanvas }: Props) {
    const dispatch = useDispatch();
    const { element: element_id, type: element_type } = useSelector((state: any) => state.editor);
    const { options, is_template } = useSelector((state: any) => state.editor_options);
    const { user: authUser } = useSelector((state: any) => state.user);
    const workspaceRef: any = React.useRef();

    const [copiedObjects, setCopiedObjects]: any = React.useState([]);

    const keysMap: any = options?.keysMap || {};

    /* query */
    var {
        data: element,
        error: elementError,
        isError: isElementError,
        isLoading: isElementLoading
    }: any = (element_type === 'ELEMENT' ? rewardElementServices : rewardPostServices).useGetQuery(
        {
            org_id: authUser?.organization_id,
            type: 'id',
            value: element_id
        },
        {
            skip: !element_id || !authUser?.organization_id || is_template
        }
    );

    var {
        data: elementTemplate,
        error: elementTemplateError,
        isError: isElementTemplateError,
        isLoading: isElementTemplateLoading
    }: any = elementTemplateServices.useGetQuery(
        {
            org_id: authUser?.organization_id,
            type: 'id',
            value: element_id
        },
        {
            skip:
                !element_id || !authUser?.organization_id || !is_template || element_type === 'POST'
        }
    );

    /* set element */
    if (elementTemplate) element = elementTemplate;

    /* error handling */
    React.useEffect(() => {
        if (isElementError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementError.data?.message
                })
            );
        }
        if (isElementTemplateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementTemplateError.data?.message
                })
            );
        }
    }, [elementError, isElementError, elementTemplateError, isElementTemplateError]);

    /* canvas init */
    React.useEffect(() => {
        var _canvas: any;

        if (workspaceRef.current) {
            const canvas_options = { width: 3508, height: 2480, zoomFactor: 0.17 };

            if (options?.type === 'BADGE') {
                canvas_options.width = 678;
                canvas_options.height = 678;
                canvas_options.zoomFactor = 0.9;
            }

            if (options?.type === 'CERTIFICATE') {
                canvas_options.width = 1200;
                canvas_options.height = 1840;
                canvas_options.zoomFactor = 0.32;
            }

            if (options?.type === 'TRANSCRIPT') {
                canvas_options.width = 2480;
                canvas_options.height = 3508;
                canvas_options.zoomFactor = 0.17;
            }

            _canvas = new fabric.Canvas('design-canvas', {
                height: workspaceRef.current.offsetHeight,
                width: workspaceRef.current.offsetWidth,
                fireRightClick: true,
                fireMiddleClick: true,
                stopContextMenu: true,
                backgroundColor: '#eff0f6',
                allowTouchScrolling: true
            });

            var worksheet = new fabric.Rect({
                name: 'worksheet',
                fill: '#fff',
                width: canvas_options.width,
                height: canvas_options.height,
                selectable: false
            });

            worksheet.center();
            _canvas.viewportCenterObject(worksheet);
            _canvas.add(worksheet);

            /* set zoom to center */
            _canvas.zoomToPoint(
                new fabric.Point(_canvas.width / 2, _canvas.height / 2),
                canvas_options.zoomFactor
            );

            editorUtils.initGuidelines(_canvas);
            _canvas.requestRenderAll();

            /* update state */
            dispatch(
                editorOptionsActions.set({
                    settings: {
                        general: {
                            ...canvas_options,
                            name: element?.data?.name || 'Name',
                            description: element?.data?.description || '',
                            opacity: 0,
                            color: '#ffffff'
                        }
                    }
                })
            );
        }

        /* init guidelines */
        setCanvas(_canvas);
    }, [workspaceRef]);

    /* load element data */
    React.useEffect(() => {
        canvasOptions.load();
    }, [canvas, element, element_type]);

    /* canvas zoom */
    React.useEffect(() => {
        if (canvas) {
            /* canvas zoom */
            canvas.on('mouse:wheel', function (opt) {
                var delta = opt.e.deltaY;
                var zoom = canvas.getZoom();

                zoom *= 0.999 ** delta;

                if (zoom > 20) zoom = 20;
                if (zoom < 0.01) zoom = 0.01;

                canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
                opt.e.preventDefault();
                opt.e.stopPropagation();
            });

            /* canvas pan */
            canvas.on('mouse:down', function (opt: any) {
                var evt = opt.e;

                if (evt.ctrlKey === true) {
                    /* @ts-ignore */
                    this.isDragging = true;
                    /* @ts-ignore */
                    this.selection = false;
                    /* @ts-ignore */
                    this.lastPosX = evt.clientX;
                    /* @ts-ignore */
                    this.lastPosY = evt.clientY;
                }
            });

            canvas.on('mouse:move', function (opt: any) {
                /* @ts-ignore */
                if (this.isDragging) {
                    var e = opt.e;
                    /* @ts-ignore */
                    var vpt = this.viewportTransform;
                    /* @ts-ignore */
                    vpt[4] += e.clientX - this.lastPosX;
                    /* @ts-ignore */
                    vpt[5] += e.clientY - this.lastPosY;
                    /* @ts-ignore */
                    this.requestRenderAll();
                    /* @ts-ignore */
                    this.lastPosX = e.clientX;
                    /* @ts-ignore */
                    this.lastPosY = e.clientY;
                }
            });

            canvas.on('mouse:up', function () {
                /* @ts-ignore */
                this.setViewportTransform(this.viewportTransform);
                /* @ts-ignore */
                this.isDragging = false;
                /* @ts-ignore */
                this.selection = true;
            });

            canvas.on('selection:created', canvasOptions.onSelect);
            canvas.on('selection:updated', canvasOptions.onSelect);
            canvas.on('selection:cleared', canvasOptions.onSelect);
        }
    }, [canvas, workspaceRef]);

    /* key event listeners */
    React.useEffect(() => {
        window.addEventListener('resize', eventHandlers.onResize);
        window.addEventListener('keydown', eventHandlers.onKeyDown);
        window.addEventListener('keyup', eventHandlers.onKeyUp);

        return () => {
            window.removeEventListener('resize', eventHandlers.onResize);
            window.removeEventListener('keydown', eventHandlers.onKeyDown);
            window.removeEventListener('keyup', eventHandlers.onKeyUp);
        };
    }, [canvas, keysMap, copiedObjects, workspaceRef]);

    /* state listeners */
    React.useEffect(() => {
        if (options?.delete_selected) {
            const objects = canvas.getActiveObjects();

            dispatch(editorOptionsActions.set({ options: { delete_selected: false } }));
            canvasOptions.onRemove(objects);
        }
    }, [canvas, options]);

    /* event handlers */
    const eventHandlers = {
        onKeyDown: (e: any) => {
            const key = e.keyCode;
            setKeysMap(key, true);

            /* on delete */
            if (keysMap[16] === true && key === 46) {
                const objects = canvas.getActiveObjects();
                canvasOptions.onRemove(objects);
            }

            /* copy */
            if (keysMap[17] === true && key === 67) {
                const objects = canvas.getActiveObjects() || [];
                setCopiedObjects(objects);
            }

            /* paste */
            if (keysMap[17] === true && key === 86) {
                for (const obj of copiedObjects) {
                    obj.clone((_obj: any) => {
                        canvas.discardActiveObject();

                        _obj.set({
                            top: _obj.top + 15,
                            left: _obj.left + 15
                        });

                        canvas.add(_obj);
                        canvas.setActiveObject(_obj);
                        canvas.requestRenderAll();
                    });
                }
            }

            /* undo */
            if (keysMap[17] === true && key === 90) {
                canvas.undo();
            }

            /* redo */
            if (keysMap[17] === true && key === 89) {
                canvas.redo();
            }

            /* save */
            if (keysMap[17] === true && key === 83) {
                // e.preventDefault();
                // onSave(true);
            }

            /* movement */
            const step = 5;
            if (keysMap[18] === true) {
                switch (key) {
                    case 37: // left
                        //e.preventDefault();
                        onKeyMovement('left', -step);
                        break;
                    case 38: // up
                        //e.preventDefault();
                        onKeyMovement('top', -step);
                        break;
                    case 39: // right
                        //e.preventDefault();
                        onKeyMovement('left', step);
                        break;
                    case 40: // down
                        //e.preventDefault();
                        onKeyMovement('top', step);
                        break;
                    default:
                        break;
                }
            }
        },
        onKeyUp: React.useCallback(
            (e: any) => {
                const key = e.keyCode;
                setKeysMap(key, false);
            },
            [canvas, keysMap]
        ),
        onResize: React.useCallback(() => {
            if (!workspaceRef.current) return;

            const bounds = workspaceRef.current.getBoundingClientRect();

            const canvas_container = workspaceRef.current.querySelector('.canvas-container');
            if (!canvas_container) return;

            const design_canvas = canvas_container.querySelector('#design-canvas');

            canvas_container.style.width = `${bounds.width}px`;
            canvas_container.style.height = `${bounds.height}px`;

            design_canvas.style.width = `${bounds.width}px`;
            design_canvas.style.height = `${bounds.height}px`;
        }, [workspaceRef])
    };

    /* canvas functions */
    const canvasOptions = {
        load: async () => {
            if (canvas && element?.data) {
                let json;

                if (element_type == 'POST') {
                    json = element?.data?.post;
                } else {
                    json = element?.data?.reward;
                }

                if (json) {
                    /* load fonts */
                    await editorUtils.loadFonts(json);

                    const canvas_options = { width: 3508, height: 2480, zoomFactor: 0.17 };

                    if (element?.data?.type === 'BADGE') {
                        canvas_options.width = 678;
                        canvas_options.height = 678;
                        canvas_options.zoomFactor = 0.9;
                    }

                    if (element?.data?.type === 'CERTIFICATE') {
                        canvas_options.width = 1200;
                        canvas_options.height = 1840;
                        canvas_options.zoomFactor = 0.32;
                    }

                    if (element?.data?.type === 'TRANSCRIPT') {
                        canvas_options.width = 2480;
                        canvas_options.height = 3508;
                        canvas_options.zoomFactor = 0.17;
                    }

                    if (element_type === 'POST') {
                        canvas_options.width = 672;
                        canvas_options.height = 350;
                        canvas_options.zoomFactor = 0.25;
                    }

                    /* set zoom to center */
                    canvas.zoomToPoint(
                        new fabric.Point(canvas.width / 2, canvas.height / 2),
                        canvas_options.zoomFactor
                    );

                    canvas.loadFromJSON(json);
                    canvas.requestRenderAll();

                    const worksheet = _.find(canvas._objects, function (o) {
                        return o.name === 'worksheet';
                    });

                    if (worksheet) worksheet.center();
                    if (worksheet) canvas.viewportCenterObject(worksheet);
                    setCanvas(canvas);

                    if (json.width) canvas_options.width = json.width;
                    if (json.height) canvas_options.height = json.height;

                    /* update state */
                    dispatch(
                        editorOptionsActions.set({
                            selected: 'general',
                            settings: {
                                general: {
                                    ...canvas_options,
                                    name: element?.data?.name || 'Element',
                                    description: element?.data?.description || '',
                                    opacity: 1,
                                    color: '#ffffff'
                                }
                            }
                        })
                    );

                    canvas.requestRenderAll();
                }

                if (element) {
                    const element_data = {
                        name: 'Element',
                        type: '',
                        description: '',
                        attributes: [],
                        other_attributes: {}
                    };

                    if (element?.data?.name) element_data.name = element?.data?.name;
                    if (element?.data?.type) element_data.type = element?.data?.type;
                    if (element?.data?.description)
                        element_data.description = element?.data?.description;
                    if (element?.data?.custom_attributes)
                        element_data.attributes = element?.data?.custom_attributes;
                    if (element?.data?.other_attributes)
                        element_data.other_attributes = element?.data?.other_attributes;

                    /* update element state */
                    dispatch(elementActions.set(element_data));
                }
            }
        },
        onSelect: React.useCallback((e: any) => {
            const objects = e.selected || [];
            let type: any = '';

            for (const obj of objects) {
                if (!type) {
                    type = obj.type;
                    if (obj.name === 'activity-tag') type = 'text';
                } else if (obj.type !== type) {
                    type = '';
                    break;
                }
            }
            dispatch(
                editorOptionsActions.set({
                    options: { selected_obj: type }
                })
            );
        }, []),
        onRemove: React.useCallback(
            (objects: any) => {
                for (const obj of objects) {
                    canvas.remove(obj);
                }
            },
            [canvas]
        ),
        onDrop: React.useCallback(
            (e: any) => {
                e.preventDefault();
                const files = e.dataTransfer.files;

                const reader = new FileReader();

                if (files[0]) {
                    reader.readAsDataURL(files[0]);

                    reader.onload = async () => {
                        let data: any = reader.result;
                        const type =
                            data.substring('data:image/'.length, data.indexOf(';base64')) || '';

                        var img = new Image();
                        img.onload = function () {
                            if (type.includes('svg')) {
                                const base64 = data.split(',')[1];
                                const svg = window.atob(base64);

                                fabric.loadSVGFromString(
                                    String(svg),
                                    function (objects: any, options: any) {
                                        var obj = fabric.util.groupSVGElements(objects, options);

                                        canvas.add(obj);
                                        canvas.centerObject(obj);
                                        canvas.setActiveObject(obj);
                                    }
                                );
                            } else {
                                var obj = new fabric.Image(img, {
                                    fill: '#ffffff',
                                    selectable: true
                                });

                                canvas.add(obj);
                                canvas.centerObject(obj);
                                canvas.setActiveObject(obj);
                            }
                        };
                        img.src = data;
                    };
                }
            },
            [canvas]
        )
    };

    const onKeyMovement = (type: string, number: number) => {
        const objects = canvas.getActiveObjects();

        for (const obj of objects) {
            obj.set({ [type]: obj[type] + number });
            canvas.requestRenderAll();
        }
    };

    /* function */
    const setKeysMap = (key: any, value: boolean) => {
        dispatch(editorOptionsActions.set({ options: { keysMap: { ...keysMap, [key]: value } } }));
    };

    return (
        <div ref={workspaceRef} className="workspace">
            <canvas id="design-canvas"></canvas>
        </div>
    );
}
