import React, { Suspense } from 'react';
import editorUtils from '../../utils/editor';
import { Canvas, useThree } from '@react-three/fiber';
import { Center, Environment, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

interface Props {
    json: any;
}

export function Camera({ settings, cameraRef }) {
    const {
        camera,
        gl: { domElement }
    } = useThree();

    React.useEffect(() => {
        camera.position.set(
            settings?.cameraPositionX,
            settings?.cameraPositionY,
            settings?.cameraPositionZ
        );
        cameraRef.current = camera;
    }, [settings, camera]);

    return (
        <OrbitControls
            autoRotateSpeed={2}
            autoRotate={true}
            enableZoom={false}
            enablePan={false}
            ref={cameraRef}
            target={[0, 0, 0]}
            minPolarAngle={Math.PI / 2}
            maxPolarAngle={Math.PI / 2}
            args={[camera, domElement]}
        />
    );
}

export default function ModelPreview({ json }: Props) {
    const cameraRef = React.useRef<any>(null);

    const [loading, setLoading] = React.useState(true);
    const [object, setObject]: any = React.useState(null);
    const [scene, setScene]: any = React.useState(null);
    const [cameraPosition, setCameraPosition]: any = React.useState({
        cameraPositionX: 0,
        cameraPositionY: 0,
        cameraPositionZ: 5
    });

    React.useEffect(() => {
        if (json?.model) {
            editorUtils.toGltf(json.model, (gltf) => {
                setObject(gltf.scene);

                if (gltf.scene) {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }
            });
        }
    }, [json]);

    React.useEffect(() => {
        onResize();

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [object]);
    const onResize = () => {
        if (object) {
            let bbox = new THREE.Box3().setFromObject(object);
            // let helper = new THREE.Box3Helper(bbox, new THREE.Color(0, 255, 0));
            let size = bbox.getSize(new THREE.Vector3()); // HEREyou get the size

            setScene(object);
            setCameraPosition({
                cameraPositionX: 0,
                cameraPositionY: 0,
                cameraPositionZ: depthCalculator(size.x, size.y, size.z)
            });
        }
    };

    const depthCalculator = (x, y, z) => {
        return 0.5 * x + 0.5 * y + z;
    };

    return (
        <div className="model-preview">
            {loading ? (
                'loading model...'
            ) : (
                <div className="threejs-model">
                    <Canvas>
                        <ambientLight intensity={0.3} />

                        <Suspense fallback={null}>
                            {scene && (
                                <Center>
                                    <primitive object={scene} />
                                </Center>
                            )}

                            <Environment files="/models/hdr.hdr" />
                        </Suspense>

                        <Camera settings={cameraPosition} cameraRef={cameraRef} />
                    </Canvas>
                </div>
            )}
        </div>
    );
}
