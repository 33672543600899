import { fabric } from 'fabric';
import { initAligningGuidelines } from './guidelines';
import WebFont from 'webfontloader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const editorUtils = {
    toJson: (canvas: any) => {
        if (!canvas) return null;

        return canvas.toJSON([
            'selectable',
            'name',
            'centeredRotation',
            'lockMovementX',
            'lockMovementY',
            'lockRotation',
            'lockScalingX',
            'lockScalingY',
            'lockSkewingX',
            'lockSkewingY',
            'lockScalingFlip'
        ]);
    },
    toImage: async (json: any, type = 'png') => {
        return new Promise((resolve) => {
            const canvas = new fabric.Canvas('');

            canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), (object) => {
                // objects
            });

            const interval = setInterval(() => {
                if (canvas?._objects?.length !== json?.objects?.length) return;

                clearInterval(interval);

                var group = new fabric.Group(canvas._objects, {
                    left: 150,
                    top: 100
                });

                const img = group.toDataURL({
                    format: type,
                    quality: 0.8
                });

                return resolve(img);
            }, 100);
        });
    },
    initGuidelines: (canvas: any) => {
        if (canvas) initAligningGuidelines(canvas);
    },
    loadFonts: async (json: any) => {
        return new Promise((resolve) => {
            const families: any = [];
            const ignore = ['Arial'];

            if (Array.isArray(json?.objects)) {
                for (const obj of json?.objects) {
                    if (obj.fontFamily && !ignore.includes(obj.fontFamily))
                        families.push(obj.fontFamily);
                }
            }

            if (families.length === 0) return resolve(true);

            WebFont.load({
                google: {
                    families: families
                },
                loading: function () {
                    console.log('Fonts are being loaded');
                },
                active: function () {
                    console.log('Fonts have been rendered');

                    return resolve(true);
                }
            });
        });
    },
    toGltf: (model: string, callback) => {
        const loader = new GLTFLoader();

        loader.load(model as string, (gltf: any) => {
            callback(gltf);
        });
    }
};

export default editorUtils;
