import React from 'react';
import * as Yup from 'yup';
import Button from '../../../components/custom/button';
import { DialogActions, Tooltip } from '@mui/material';
import TextField from '../../../components/form/text-field';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import Form from '../../../components/form';
import SubmitButton from '../../../components/form/submit-button';
import Icon from '../../../components/icon';
import { notifyActions } from '../../../redux/reducers/notify';
import orgServices from '../../../services/org.services';
import IconButton from '../../../components/custom/icon-button';
import moment from 'moment';
import vcsd from '../../../utils/vcsd';
import * as dns from 'dns';
import utils from '../../../utils';

const validationSchema = Yup.object().shape({
    domain: Yup.string().required('Domain is required'),
    private_key: Yup.string().required('Private key is required'),
    signature: Yup.string().required('Signature is required')
});

export default function VerifyDomain() {
    const dispatch = useDispatch();
    const copyInputRef = React.useRef<HTMLInputElement>();
    const domainInputRef = React.useRef<HTMLInputElement>();
    const privateKeyInputRef = React.useRef<HTMLInputElement>();
    const { user: authUser } = useSelector((state: any) => state.user);

    const [isLocallyVerifying, setIsLocallyVerifying] = React.useState(false);

    /* query */
    const {
        data: orgProfile,
        error: orgProfileError,
        isError: isOrgProfileError
    }: any = orgServices.useGetQuery(
        {
            org_id: authUser?.organization_id,
            type: 'id',
            value: authUser?.organization_id
        },
        { skip: !authUser?.organization_id }
    );

    /* mutations */
    const [
        generateTxt,
        {
            isLoading: isOrgGeneratingTxt,
            error: orgGenerateTxtError,
            isError: isOrgGenerateTxtError
        }
    ]: any = orgServices.useGenerateTxTMutation();

    const [
        verify,
        { isLoading: isOrgVerifying, error: orgVerifyError, isError: isOrgVerifyError }
    ]: any = orgServices.useVerifyMutation();

    const [
        update,
        { isLoading: isOrgUpdating, error: orgUpdateError, isError: isOrgUpdateError }
    ]: any = orgServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isOrgGenerateTxtError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: orgGenerateTxtError.data?.message
                })
            );
        }
        if (isOrgVerifyError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: orgVerifyError.data?.message
                })
            );
        }
        if (isOrgProfileError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: orgProfileError.data?.message
                })
            );
        }
        if (isOrgUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: orgUpdateError.data?.message
                })
            );
        }
    }, [
        orgGenerateTxtError,
        isOrgGenerateTxtError,
        orgVerifyError,
        isOrgVerifyError,
        orgProfileError,
        isOrgProfileError,
        orgUpdateError,
        isOrgUpdateError
    ]);

    /* init data */
    const data = React.useMemo(() => {
        const template = {
            domain: '',
            private_key: '',
            signature: ''
        };
        if (orgProfile?.data?.domain) template.domain = orgProfile?.data?.domain;
        if (orgProfile?.data?.txt) template.signature = orgProfile?.data?.txt;

        return template;
    }, [orgProfile]);

    /* functions */
    const onSubmit = async (values: any) => {
        const doc = await vcsd.docFromPrivateKey(values.private_key);

        const result = await verify({
            org_id: authUser.organization_id,
            user_id: authUser.id,
            date: new Date().toString(),
            public_key: doc.publicKey
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );
        }
    };
    const onGenerate = async () => {
        if (!domainInputRef.current?.value) {
            return dispatch(
                notifyActions.open({
                    type: 'error',
                    message: 'domain is required'
                })
            );
        }

        if (!privateKeyInputRef.current?.value) {
            return dispatch(
                notifyActions.open({
                    type: 'error',
                    message: 'private key is required'
                })
            );
        }

        const txt = vcsd.sign({
            data: domainInputRef.current.value,
            privateKey: privateKeyInputRef.current.value
        });

        if (txt) {
            const result = await update({
                org_id: authUser.organization_id,
                user_id: authUser.id,
                txt
            });

            if (result?.data?.status === 'success') {
                dispatch(
                    notifyActions.open({
                        type: 'success',
                        message: result.data?.message
                    })
                );
            }

            if (copyInputRef?.current) copyInputRef.current.value = txt;
        }
    };

    const onLocallyVerify = async () => {
        if (!domainInputRef.current?.value) {
            return dispatch(
                notifyActions.open({
                    type: 'error',
                    message: 'domain is required'
                })
            );
        }

        if (!privateKeyInputRef.current?.value) {
            return dispatch(
                notifyActions.open({
                    type: 'error',
                    message: 'private key is required'
                })
            );
        }

        if (!copyInputRef.current?.value) {
            return dispatch(
                notifyActions.open({
                    type: 'error',
                    message: 'Please generate a TXT signature',
                    format: false
                })
            );
        }

        setIsLocallyVerifying(true);

        try {
            const url = utils.parseDomain(domainInputRef.current?.value as string);

            const doc = await vcsd.docFromPrivateKey(privateKeyInputRef.current?.value as string);

            if (
                vcsd.verify({
                    data: url.domain,
                    signature: copyInputRef.current?.value as string,
                    publicKey: doc.publicKey
                })
            ) {
                dispatch(
                    notifyActions.open({
                        type: 'success',
                        message: 'organization domain verified'
                    })
                );
                setIsLocallyVerifying(false);
                return;
            }

            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: 'failed to verify TXT record'
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: 'failed to verify TXT record'
                })
            );
        } finally {
            setIsLocallyVerifying(false);
        }
    };

    /* elements */
    const CopyButton = () => {
        const [open, setOpen] = React.useState(false);

        /* function */
        const onCopy = () => {
            var copyText: any = copyInputRef.current;
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);

            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, 1500);
        };

        return (
            <Tooltip
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied"
                arrow>
                <IconButton aria-label="copy" onClick={onCopy}>
                    <Icon variant="outlined" className="icon">
                        content_copy
                    </Icon>
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <div className="modal-container verify-modal">
            <Form
                enableReinitialize={true}
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <div className="step">
                        <div className="title-wrap">
                            <h5 className="title">Step 01</h5>
                            <p className="desc">
                                Enter your domain correctly and click Generate Domain Signature
                            </p>
                        </div>

                        <TextField
                            inputRef={domainInputRef}
                            name="domain"
                            placeholder="Enter Domain Name"
                            label="Enter Domain Name"
                            required
                        />
                        <TextField
                            inputRef={privateKeyInputRef}
                            name="private_key"
                            placeholder="Enter Private Key"
                            label="Enter Private Key"
                            required
                        />
                        <div className="input-wrap">
                            <Button
                                type="button"
                                disabled={isOrgVerifying}
                                loading={isOrgGeneratingTxt}
                                variant="contained"
                                onClick={onGenerate}>
                                Generate Domain Signature
                            </Button>
                        </div>

                        <div className="step">
                            <div className="title-wrap auto-gap">
                                <h5 className="title">Step 02</h5>
                                <p className="desc">
                                    <span>Add the below signature as a TXT record to your DNS</span>
                                    <span>|</span>
                                    <a
                                        href="https://support.knowbe4.com/hc/en-us/articles/115015835387-How-to-Add-a-TXT-Record-to-Your-DNS-Records"
                                        target="_blank">
                                        <span className="highlighted">
                                            How to add this to your domain?
                                        </span>
                                    </a>
                                </p>
                                <p className="desc">
                                    Specify <b>“@”</b> as the <b>“name”</b> of the record
                                </p>
                                <p className="desc">
                                    Specify the generated signature as the <b>“value”</b> of the
                                    record
                                </p>
                            </div>
                        </div>

                        <div className="input-wrap">
                            <TextField
                                inputRef={copyInputRef}
                                className="copy-txt"
                                name="signature"
                                placeholder="Signature"
                                label="Signature"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: <CopyButton />
                                }}
                            />
                        </div>

                        <div className="input-wrap">
                            <Button
                                disabled={isOrgVerifying}
                                loading={isLocallyVerifying}
                                variant="contained"
                                onClick={onLocallyVerify}>
                                Verify Domain Locally
                            </Button>
                        </div>
                    </div>

                    <div className="step">
                        <div className="title-wrap auto-gap">
                            <h5 className="title">Step 03</h5>
                            <p className="desc">
                                <span>
                                    You can check whether the TXT record has been set up properly
                                    using
                                    <a href="https://mxtoolbox.com" target="_blank">
                                        <span className="highlighted"> mxtoolbox.com</span>
                                    </a>
                                </span>
                            </p>
                            <p className="desc verify-date">
                                <span>Allow some time and click verify</span>
                                <span>
                                    {orgProfile?.data?.is_verified && (
                                        <span className="sub-item">
                                            Verified on{' '}
                                            {orgProfile?.data?.verify_datetime
                                                ? moment(orgProfile?.data?.verify_datetime).format(
                                                      'DD.MM.YYYY'
                                                  )
                                                : '-'}
                                        </span>
                                    )}
                                </span>
                            </p>
                        </div>

                        {orgProfile?.data?.is_verified && (
                            <h5 className="verified">
                                <Icon className="icon">check_circle</Icon> Verified
                            </h5>
                        )}
                    </div>
                </div>
                <DialogActions className="modal-buttons">
                    <Button
                        disabled={isOrgVerifying || isOrgGeneratingTxt}
                        onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton
                        disabled={isOrgGeneratingTxt}
                        loading={isOrgVerifying}
                        variant="contained">
                        Verify Domain
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
