import React from 'react';
import General from './general';
import Attributes from './attributes';

const Containers = {
    general: General,
    attributes: Attributes
};

interface Props {
    model: any;
    selected: 'attributes' | string;
}

export default function Settings({ model, selected }: Props) {
    const Container = Containers[selected];

    return Container ? <Container model={model} /> : null;
}
