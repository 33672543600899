import React from 'react';
import { useField, useFormikContext } from 'formik';
import { TextFieldProps } from '@mui/material/TextField';
import MuiTextField from '../custom/text-field';

export default function TextField({
    name = 'text-field',
    size = 'small',
    type = 'text',
    variant = 'outlined',
    fullWidth = true,
    onChange,
    ...props
}: TextFieldProps) {
    const [field, meta] = useField(name);
    const { setFieldValue } = useFormikContext();

    const handleChange = (e) => {
        setFieldValue(name, e.target.value);
        if (onChange) onChange(e);
    };

    const config: any = {
        fullWidth: fullWidth,
        type: type,
        variant: variant,
        size: size,
        ...field,
        ...props,
        onChange: handleChange
    };

    if (meta && meta.touched && meta.error) {
        config.error = true;
        config.helperText = meta.error;
    }

    return <MuiTextField {...config} />;
}
