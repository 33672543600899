import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    createActivityTagProps,
    updateActivityTagProps
} from './interfaces/activity-tag.interface';

const activityTagServices = createApi({
    reducerPath: 'activity-tags-service',
    baseQuery: baseQuery,
    tagTypes: ['ActivityTags', 'ActivityTagsOptions'],
    endpoints: (builder) => ({
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/activity-tags/${org_id}/get/${type}/${value}`
            })
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/activity-tags/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'ActivityTags' }, { type: 'ActivityTagsOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/activity-tags/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'ActivityTagsOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateActivityTagProps) => ({
                method: 'PUT',
                url: `/activity-tags/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'ActivityTags' }, { type: 'ActivityTagsOptions' }]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/activity-tags/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'ActivityTags' }, { type: 'ActivityTagsOptions' }]
        })
    })
});

export default activityTagServices;
