import React from 'react';
import {
    Table as MuiTable,
    TableRow as MuiTableRow,
    TableHead as MuiTableHead,
    TableBody as MuiTableBody,
    Paper,
    TableCell
} from '@mui/material';
import TableHead from './head';
import TableBody from './body';
import TablePagination from './pagination';
import { TableVirtuoso } from 'react-virtuoso';
import TableRowLoading from './loading';

interface PropsInterface {
    className?: string;
    rows: any;
    headers: any;
    cells: any;
    options?: any;
    optionsLoading?: boolean;
    hidePagination?: boolean;
    hideHeader?: boolean;
    loading?: boolean;
    fetching?: boolean;
    height?: number;
    pageSize?: number;
    pages?: number;
    page?: number;
    initialPageSize?: number;
    setPage?: any;
    setPageSize?: any;
    rowsPerPageOptions?: any;
    selected?: any;
    setSelected?: any;
    checkBoxSelection?: boolean;
    RenderHeader?: any;
}

export default React.memo(function PageTable({
    className,
    rows = [],
    headers = [],
    cells = [],
    options = {},
    height = 500,
    loading = false,
    fetching = false,
    optionsLoading = false,
    hideHeader = false,
    hidePagination = false,
    pageSize = 10,
    initialPageSize = 10,
    setPageSize,
    page = 0,
    setPage,
    selected = [],
    setSelected,
    checkBoxSelection,
    rowsPerPageOptions,
    RenderHeader
}: PropsInterface) {
    const rowsCount = rows?.length;
    const selectedCount = selected?.length;

    const onPageChange = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const onRowsPerPageChange = (event: any) => {
        let val = event.target.value;
        if (val == 'All') val = options.count || 1000;
        if (setPageSize) {
            setPage(0);
            setPageSize(parseInt(val, 10));
        }
    };

    const onSelectAll = (event: any) => {
        if (event.target.checked) {
            const newSelected = rows.map((i: any) => i?.id);
            if (setSelected) setSelected(newSelected);
            return;
        }

        if (setSelected) setSelected([]);
    };

    const isSelected = (id: any) => {
        if (!id) return false;

        return selected.indexOf(id) !== -1;
    };

    const onSelect = (event: any, id: any) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    return (
        <div className={`${'page-table'} ${className ? className : ''}`}>
            <Paper className="table" style={{ height, width: '100%' }}>
                <TableVirtuoso
                    data={
                        loading
                            ? Array.from({ length: 10 }, (_, i) => i)
                            : rows.length === 0
                            ? [{}]
                            : rows
                    }
                    components={{
                        Table: MuiTable,
                        TableBody: MuiTableBody,
                        TableHead: MuiTableHead,
                        TableRow: MuiTableRow
                    }}
                    fixedHeaderContent={() =>
                        RenderHeader ? (
                            <RenderHeader />
                        ) : (
                            <TableHead
                                headers={headers}
                                checkBoxSelection={checkBoxSelection}
                                selectedCount={selectedCount}
                                rowsCount={rowsCount}
                                onSelectAll={onSelectAll}
                            />
                        )
                    }
                    itemContent={(_index, row) =>
                        loading ? (
                            <TableRowLoading
                                checkBoxSelection={checkBoxSelection}
                                headers={headers}
                            />
                        ) : rows.length === 0 ? (
                            <TableCell colSpan={cells.length + (checkBoxSelection ? 1 : 0)}>
                                No Records.
                            </TableCell>
                        ) : (
                            <TableBody
                                _index={_index}
                                item={row}
                                cells={cells}
                                checkBoxSelection={checkBoxSelection}
                                isSelected={isSelected}
                                onSelect={onSelect}
                            />
                        )
                    }
                    fixedFooterContent={() =>
                        fetching && (
                            <TableRowLoading
                                checkBoxSelection={checkBoxSelection}
                                headers={headers}
                            />
                        )
                    }
                />
            </Paper>
            <div className="pagination">
                {!hidePagination && (
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={options?.count || 0}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                )}
            </div>
        </div>
    );
});
