import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import TextField from '../../../components/form/text-field';
import { DialogActions } from '@mui/material';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { modalTypes } from '../..';
import linkedinProfilesServices from '../../../services/linkedin-profile.services';
import { notifyActions } from '../../../redux/reducers/notify';

const initialValues = {
    name: '',
    url: '',
    code: ''
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Profile name is required'),
    url: Yup.string().url().required('Profile link is required'),
    code: Yup.string().required('LinkedIn organization ID is required')
});

export default function OrgLinkedinProfile() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);
    const { modalProps } = useSelector((state: any) => state.modal);
    const props = modalProps[modalTypes.EDIT_LINKEDIN_PROFILE];

    /* init data */
    const data = React.useMemo(() => {
        const template = {
            name: '',
            url: '',
            code: ''
        };

        if (props?.data?.name) template.name = props?.data?.name;
        if (props?.data?.url) template.url = props?.data?.url;
        if (props?.data?.code) template.code = props?.data?.code;

        return template;
    }, [props]);

    /* mutation */
    const [
        create,
        {
            error: linkedinProfileCreateError,
            isError: isLinkedinProfileCreateError,
            isLoading: isLinkedinProfileCreating
        }
    ]: any = linkedinProfilesServices.useCreateMutation();
    const [
        update,
        {
            error: linkedinProfileUpdateError,
            isError: isLinkedinProfileUpdateError,
            isLoading: isLinkedinProfileUpdating
        }
    ]: any = linkedinProfilesServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isLinkedinProfileCreateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: linkedinProfileCreateError.data?.message
                })
            );
        }
        if (isLinkedinProfileUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: linkedinProfileUpdateError.data?.message
                })
            );
        }
    }, [
        linkedinProfileCreateError,
        isLinkedinProfileCreateError,
        linkedinProfileUpdateError,
        isLinkedinProfileUpdateError
    ]);

    /* functions */
    const onSubmit = async (values: any) => {
        let result: any;

        if (props?.data?.id) {
            result = await update({
                ...values,
                id: props?.data?.id,
                org_id: props?.organization_id ?? authUser.organization_id,
                user_id: authUser.id
            });
        } else {
            result = await create({
                ...values,
                org_id: props?.organization_id ?? authUser.organization_id,
                user_id: authUser.id
            });
        }

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container organization-modal">
            <Form
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <TextField
                        name="name"
                        label="Profile Name"
                        placeholder="Profile Name"
                        required
                    />
                    <TextField
                        name="url"
                        label="Profile Link"
                        placeholder="Profile Link"
                        required
                    />
                    <TextField
                        name="code"
                        label="LinkedIn Organization ID"
                        placeholder="LinkedIn Organization ID"
                        required
                    />
                </div>

                <DialogActions className="modal-buttons">
                    <Button
                        disabled={isLinkedinProfileCreating || isLinkedinProfileUpdating}
                        onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton
                        loading={isLinkedinProfileCreating || isLinkedinProfileUpdating}
                        variant="contained">
                        {props?.button || 'Add Profile'}
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
