import React from 'react';
import _ from 'lodash';
import Button from '../../../components/custom/button';
import Icon from '../../../components/icon';
import { ClickAwayListener, Popper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { editorActions } from '../../../redux/reducers/editor';
import utils from '../../../utils';
import { fabric } from 'fabric';
import editorUtils from '../../../utils/editor';
import { notifyActions } from '../../../redux/reducers/notify';
import rewardElementServices from '../../../services/reward-element.services';
import { elementActions } from '../../../redux/reducers/element';
import { modalActions } from '../../../redux/reducers/modal';
import { modalTypes } from '../../../modals';
import elementTemplateServices from '../../../services/element-templates.services';
import { editorOptionsActions } from '../../../redux/reducers/editor/options';
import IconButton from '../../../components/custom/icon-button';
import SearchSelect from '../../../components/custom/search-select';
import { useLocation } from 'react-router-dom';
import rewardPostServices from '../../../services/reward-post.services';
import rewardElementUtils from '../../../utils/rewards/element';

interface Props {
    canvas: any;
}

export default function TopBar({ canvas }: Props) {
    const dispatch = useDispatch();
    const { state: routerState } = useLocation();

    const element = useSelector((state: any) => state.element);
    const {
        element: element_id,
        is_template,
        type: element_type
    } = useSelector((state: any) => state.editor);

    const [zoomLevel, setZoomLevel] = React.useState(10);
    const [menu, setMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    /* mutation */
    const [
        updateElement,
        { error: elementUpdateError, isError: isUpdateElementError, isLoading: isUpdatingElement }
    ]: any = rewardElementServices.useUpdateMutation();
    const [
        updatePost,
        { error: postUpdateError, isError: isUpdatePostError, isLoading: isUpdatingPost }
    ]: any = rewardPostServices.useUpdateMutation();

    const [
        updateTemplate,
        {
            error: elementUpdateTemplateError,
            isError: isUpdateElementTemplateError,
            isLoading: isUpdatingTemplate
        }
    ]: any = elementTemplateServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isUpdateElementError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementUpdateError?.data?.message
                })
            );
        }
        if (isUpdateElementTemplateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementUpdateTemplateError?.data?.message
                })
            );
        }
        if (isUpdatePostError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: postUpdateError?.data?.message
                })
            );
        }
    }, [
        elementUpdateError,
        isUpdateElementError,
        elementUpdateTemplateError,
        isUpdateElementTemplateError,
        postUpdateError,
        isUpdatePostError
    ]);

    /* functions */
    const onMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
        setMenu(!menu);
    };

    const getZoomLevels = () => {
        const levels = [100, 70, 50, 25, 10];

        return levels.map((i) => {
            return { label: `${i}%`, value: i };
        });
    };

    const onZoomLevel = (event: any, value: number) => {
        if (canvas) {
            const center = canvas.getCenter();
            const centerPoint = new fabric.Point(center.left, center.top);

            canvas.zoomToPoint(centerPoint, value * 0.01);
            canvas.requestRenderAll();
            setZoomLevel(value);
        }
    };

    const onUndo = () => {
        canvas.undo();
    };
    const onRedo = () => {
        canvas.redo();
    };
    const onDelete = () => {
        dispatch(editorOptionsActions.set({ options: { delete_selected: true } }));
    };

    const onExport = () => {
        const json = editorUtils.toJson(canvas);
        let exportName = 'reward-element-design';

        if (json) {
            let dataStr =
                'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json, null, 2));
            let downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', dataStr);
            downloadAnchorNode.setAttribute('download', exportName + '.json');
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        }

        setMenu(!menu);
    };

    const onImport = (e: any) => {
        let files = e.target.files;
        const reader: any = new FileReader();

        if (files[0]) {
            reader.readAsText(files[0]);

            reader.onload = () => {
                try {
                    if (utils.isJson(reader.result)) {
                        const json = JSON.parse(reader.result);
                        canvas.loadFromJSON(json);
                    }
                } catch (error) {
                    console.log({ error });
                }
            };
        }

        setMenu(!menu);
    };

    const onExit = () => {
        const valid = rewardElementUtils.validate({ element });
        if (valid?.error) {
            dispatch(notifyActions.open(valid.error));
            return;
        }

        dispatch(elementActions.reset());
        dispatch(editorOptionsActions.reset());
        dispatch(editorActions.close());
    };

    const onSave = async (close?: boolean) => {
        let result: any;

        if (element_type === 'ELEMENT') {
            result = await onSaveElement();
        }
        if (element_type === 'POST') {
            result = await onSavePost();
        }

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            if (close) {
                dispatch(elementActions.reset());
                dispatch(editorOptionsActions.reset());
                dispatch(editorActions.close());
            }
        }

        setMenu(false);
    };

    const onSaveElement = async () => {
        let result: any;
        const { name, description, attributes, type, other_attributes } = element;

        const valid = rewardElementUtils.validate({ element });
        if (valid?.error) {
            dispatch(notifyActions.open(valid.error));
            return;
        }

        if (is_template) {
            result = await updateTemplate({
                id: element_id,
                name: name,
                description: description,
                custom_attributes: attributes,
                other_attributes: other_attributes,
                reward: editorUtils.toJson(canvas)
            });
        } else {
            result = await updateElement({
                id: element_id,
                name: name,
                description: description,
                custom_attributes: attributes,
                other_attributes: other_attributes,
                reward: editorUtils.toJson(canvas)
            });
        }

        return result;
    };

    const onSavePost = async () => {
        const { name, description } = element;

        const required_attributes = {
            name: name
        };

        if (required_attributes) {
            for (let key in required_attributes) {
                if (!required_attributes[key]) {
                    dispatch(
                        notifyActions.open({
                            type: 'warning',
                            message: `${_.startCase(key)} is required`
                        })
                    );
                    return;
                }
            }
        }

        let result = await updatePost({
            id: element_id,
            name: name,
            description: description,
            post: editorUtils.toJson(canvas)
        });

        return result;
    };

    const onSaveAsTemplate = () => {
        dispatch(
            modalActions.show({
                type: modalTypes.SAVE_TEMPLATE,
                props: {
                    type: element?.type,
                    description: element?.description,
                    custom_attributes: element?.attributes,
                    reward: editorUtils.toJson(canvas)
                }
            })
        );

        setMenu(false);
    };

    return (
        <div className="top-bar">
            <div className="section">
                <ClickAwayListener onClickAway={() => setMenu(false)}>
                    <div className="items">
                        <IconButton
                            disabled={isUpdatingElement || isUpdatingPost || isUpdatingTemplate}
                            tooltip={{ title: 'More', placement: 'bottom' }}
                            onClick={onMenu}>
                            <Icon>more_horiz</Icon>
                        </IconButton>

                        <SearchSelect
                            className="header"
                            search={false}
                            value={zoomLevel}
                            onSelect={onZoomLevel}
                            name="zoom levels"
                            data={getZoomLevels()}
                        />

                        <IconButton
                            tooltip={{ title: 'Undo', placement: 'bottom' }}
                            onClick={() => onUndo()}>
                            <Icon className="icon w-500">undo</Icon>
                        </IconButton>
                        <IconButton
                            tooltip={{ title: 'Redo', placement: 'bottom' }}
                            onClick={() => onRedo()}>
                            <Icon className="icon w-500">redo</Icon>
                        </IconButton>
                        <IconButton
                            disabled={!!routerState?.read_only}
                            tooltip={{ title: 'Delete ( Shift + Del )', placement: 'bottom' }}
                            onClick={() => onDelete()}>
                            <Icon className="icon w-500">delete</Icon>
                        </IconButton>

                        <Popper
                            className="popper g-sm"
                            open={menu}
                            anchorEl={anchorEl}
                            placement="bottom-end">
                            <div onClick={onExport} className="item w-400 fs-sm cl-default">
                                <span className="title">Export to a JSON</span>
                            </div>
                            {!routerState?.read_only && (
                                <label
                                    htmlFor="import-json"
                                    className="item w-400 fs-sm cl-default">
                                    <input
                                        id="import-json"
                                        type="file"
                                        hidden
                                        accept="application/JSON"
                                        onChange={onImport}
                                    />
                                    <span className="title">Import from a JSON</span>
                                </label>
                            )}

                            <div className="divider"></div>
                            {!routerState?.read_only && (
                                <div
                                    onClick={() => onSave(false)}
                                    className="item w-400 fs-sm cl-default">
                                    <span className="title">Save</span>
                                </div>
                            )}
                            <div onClick={onSaveAsTemplate} className="item w-400 fs-sm cl-default">
                                <span className="title">Save as a template</span>
                            </div>

                            <div className="divider"></div>
                            <div onClick={onExit} className="item w-400 fs-sm cl-default">
                                <span className="title">Exit</span>
                            </div>
                        </Popper>
                    </div>
                </ClickAwayListener>
            </div>
            <div className="section buttons">
                <Button
                    disabled={isUpdatingElement || isUpdatingPost || isUpdatingTemplate}
                    onClick={onExit}
                    variant="text"
                    className="cancel">
                    Cancel
                </Button>
                <div className="divider"></div>
                <Button
                    disabled={!!routerState?.read_only}
                    loading={isUpdatingElement || isUpdatingPost || isUpdatingTemplate}
                    onClick={() => onSave(true)}
                    variant="text"
                    className="save">
                    Save
                </Button>
            </div>
        </div>
    );
}
