import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';

const rewardIssuanceServices = createApi({
    reducerPath: 'reward-issuance-service',
    baseQuery: baseQuery,
    tagTypes: ['RewardIssuances', 'RewardIssuance', 'RewardIssuanceOptions'],
    endpoints: (builder) => ({
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/reward-issuances/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'RewardIssuance' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/reward-issuances/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'RewardIssuances' }, { type: 'RewardIssuanceOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/reward-issuances/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'RewardIssuanceOptions' }]
        }),
        delete: builder.mutation({
            query: ({ org_id, user_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/reward-issuances/${org_id}/delete/${user_id}`,
                body: props
            }),
            invalidatesTags: [
                { type: 'RewardIssuances' },
                { type: 'RewardIssuanceOptions' },
                { type: 'RewardIssuance' }
            ]
        })
    })
});

export default rewardIssuanceServices;
