import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Popper, ClickAwayListener } from '@mui/material';
import Button from '../components/custom/button';
import Icon from '../components/icon';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../redux/reducers/user';
import { modalTypes } from '../modals';
import { modalActions } from '../redux/reducers/modal';
import IconButton from '../components/custom/icon-button';
import { logoutActions } from '../redux/reducers/logout';
import Skeleton from '../components/custom/skeleton';
import { notifyActions } from '../redux/reducers/notify';
import orgServices from '../services/org.services';
import userServices from '../services/user.services';

interface RouteInterface {
    name: string;
    icon: any;
    route: string;
    paths: any;
    menu?: object;
}

export default function Navbar() {
    const navigator = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);

    const [selected, setSelected] = React.useState('');
    const [menu, setMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openProfileMenu, setOpenProfileMenu] = React.useState(false);

    /* query */
    const {
        data: organization,
        error: organizationError,
        isError: isOrganizationError,
        isLoading: isOrganizationLoading
    }: any = orgServices.useGetQuery(
        {
            org_id: authUser?.organization_id,
            type: 'id',
            value: authUser?.organization_id
        },
        { skip: !authUser?.organization_id || authUser?.role === 'SUPER_ADMIN' }
    );

    const {
        data: user,
        error: userProfileError,
        isError: isUserProfileError,
        isLoading: isUserProfileLoading
    }: any = userServices.useGetQuery(
        {
            org_id: authUser?.role === 'SUPER_ADMIN' ? '0' : authUser?.organization_id,
            id: authUser?.id
        },
        { skip: !authUser?.id }
    );

    /* error handling */
    React.useEffect(() => {
        if (isUserProfileError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: userProfileError.data?.message
                })
            );
        }
        if (isOrganizationError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: organizationError.data?.message
                })
            );
        }
    }, [userProfileError, isUserProfileError, organizationError, isOrganizationError]);

    /* routes */
    const routes = React.useMemo(() => {
        const Routes1 = [
            {
                name: 'Organizations',
                icon: 'business',
                route: '/home',
                paths: ['/home', '/organizations/edit']
            }
        ];

        const Routes2 = [
            { name: 'Dashboard', icon: 'home', route: '/home', paths: ['/home'] },
            {
                name: 'Activities',
                icon: 'list_alt',
                route: '/activities',
                paths: ['/activities', '/activities/edit']
            },
            { name: 'Elements', icon: 'interests', route: '/elements', paths: ['/elements'] },
            // { name: 'Receivers', icon: 'download', route: '/receivers', paths: ['/receivers'] },
            // { name: 'Issued', icon: 'upload', route: '/issued', paths: ['/issued'] },
            {
                name: 'Settings',
                icon: 'settings',
                route: '/settings',
                paths: ['/settings'],
                menu: [
                    {
                        name: 'Profile',
                        icon: 'person',
                        route: '/settings/profile',
                        paths: ['/settings/profile', '/settings/profile/edit']
                    },
                    {
                        name: 'Users',
                        icon: 'group',
                        route: '/settings/users',
                        paths: ['/settings/users']
                    },
                    {
                        name: 'Rewards',
                        icon: 'stars',
                        route: '/settings/rewards',
                        paths: ['/settings/rewards']
                    }
                ]
            }
        ];

        if (authUser?.role === 'SUPER_ADMIN') {
            return Routes1;
        } else {
            return Routes2;
        }
    }, [authUser?.role]);

    React.useEffect(() => {
        const pathname = location.pathname;

        if (pathname) setSelected(pathname === '/' ? '/home' : pathname);
    }, [location]);

    /* functions */
    const onMenuItemSelect = (route: string) => {
        setSelected(route);
        navigator(formatPath(route));
        setMenu(false);
    };

    const onProfileMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenProfileMenu(!openProfileMenu);
    };

    const onLogout = () => {
        setOpenProfileMenu(!openProfileMenu);
        dispatch(logoutActions.open());

        setTimeout(() => {
            dispatch(userActions.remove());
        }, 200);
    };

    const formatPath = (pathname: string) => {
        return pathname === '/home' ? '/' : pathname;
    };

    const renderUserName = () => {
        let name = '-';

        if (!user?.data?.first_name && !user?.data?.last_name && user?.data?.username)
            return user.data.username;

        if (user?.data?.first_name) name = user.data.first_name;
        if (user?.data?.last_name) name += ' ' + user.data.last_name;

        return name;
    };

    return (
        <div id="navbar">
            <div className="mobile-navbar">
                <IconButton onClick={() => setMenu(!menu)}>
                    <Icon className="icon">menu</Icon>
                </IconButton>
            </div>

            <div className="side-bar" data-active={menu}>
                <div className="logo-section">
                    <Skeleton height={50} loading={isOrganizationLoading}>
                        <img
                            src={
                                authUser?.role === 'SUPER_ADMIN'
                                    ? '/images/logos/logo.svg'
                                    : organization?.data?.logo || '/images/logos/sample-logo.svg'
                            }
                            alt="Logo"
                            className="logo"
                        />
                    </Skeleton>

                    <Skeleton height={20} loading={isOrganizationLoading}>
                        <span className="title">
                            {organization?.data?.name || 'Micro Rewards'}{' '}
                            {organization?.data?.is_verified && (
                                <Icon className="icon">check_circle</Icon>
                            )}
                        </span>
                    </Skeleton>
                </div>

                <div className="menu-items">
                    {routes.map((route: RouteInterface) => {
                        return (
                            <React.Fragment key={route.route}>
                                <div
                                    className="item"
                                    data-active={route.paths.includes(selected)}
                                    onClick={() => onMenuItemSelect(formatPath(route.route))}>
                                    <Icon className="icon">{route.icon}</Icon>
                                    <span className="title">{route.name}</span>
                                </div>

                                <div className="sub-menu">
                                    {Array.isArray(route.menu) &&
                                        route.menu.map((sub) => {
                                            return (
                                                <div
                                                    key={sub.route}
                                                    className="item"
                                                    data-active={sub.paths.includes(selected)}
                                                    onClick={() => onMenuItemSelect(sub.route)}>
                                                    <Icon className="icon">{sub.icon}</Icon>
                                                    <span className="title">{sub.name}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>

                <div className="bottom-section">
                    <Popper
                        className="popper"
                        open={openProfileMenu}
                        anchorEl={anchorEl}
                        placement="right-end">
                        <div
                            className="item"
                            onClick={() =>
                                dispatch(
                                    modalActions.show({
                                        type: modalTypes.PROFILE
                                    })
                                )
                            }>
                            <Icon className="icon">person</Icon>
                            <span className="title">Profile</span>
                        </div>
                        <div className="item" onClick={onLogout}>
                            <Icon className="icon">logout</Icon>
                            <span className="title">Logout</span>
                        </div>
                    </Popper>

                    <ClickAwayListener onClickAway={() => setOpenProfileMenu(false)}>
                        <div>
                            <Button
                                variant="text"
                                className="profile-section"
                                onClick={onProfileMenu}>
                                <div className="section">
                                    <Avatar src="/broken-image.jpg" className="avatar" />
                                </div>
                                <div className="section profile">
                                    <div className="details">
                                        <Skeleton height={20} loading={isUserProfileLoading}>
                                            <span className="name">{renderUserName()}</span>
                                        </Skeleton>
                                        <span className="desc">Premium member</span>
                                    </div>
                                    <Icon className="nav-icon">navigate_next</Icon>
                                </div>
                            </Button>
                        </div>
                    </ClickAwayListener>

                    <div className="divider"></div>

                    <div className="org-section">
                        <img src="/images/logos/logo-grey.svg" alt="Logo" className="logo" />
                        <span className="text">
                            Powered by <b>Micro Rewards</b>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
