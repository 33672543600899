import React from 'react';
import Button from '../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { modalTypes } from '../..';
import { notifyActions } from '../../../redux/reducers/notify';
import receiverGroupServices from '../../../services/receiver-group.services';
import SubmitButton from '../../../components/form/submit-button';
import Form from '../../../components/form';
import TextField from '../../../components/form/text-field';
import * as Yup from 'yup';
import groupServices from '../../../services/group.services';

const initialValues = {
    name: ''
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Group name is required')
});

export default function CreateGroup() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);

    /* mutation */
    const [
        createReceiverGroup,
        { error: createReceiverGroupError, isError: isReceiverGroupError, isLoading: isLoading }
    ]: any = groupServices.useCreateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isReceiverGroupError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: createReceiverGroupError.data?.message
                })
            );
        }
    }, [createReceiverGroupError, isReceiverGroupError]);

    const onSubmit = async (values: any) => {
        const result = await createReceiverGroup({
            name: values.name,
            org_id: authUser.organization_id
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());

            dispatch(
                modalActions.show({
                    type: modalTypes.ADD_RECEIVERS_GROUP
                })
            );
        }
    };

    return (
        <div className="modal-container">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <TextField name="name" label="Group Name" placeholder="Group Name" required />
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isLoading} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isLoading} variant="contained">
                        Create Group
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
