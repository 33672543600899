import React from 'react';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalTypes } from '../..';
import Button from '../../../components/custom/button';
import { modalActions } from '../../../redux/reducers/modal';

export default function InterruptPrompt() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const props = modalProps[modalTypes.INTERRUPT_PROMPT];

    return (
        <div className="modal-container">
            <div className="modal-content auto-padding">
                {props.message ||
                    'There are processors running at the moment. Are you sure you want to leave ?'}
            </div>
            <DialogActions className="modal-buttons">
                <Button onClick={() => dispatch(modalActions.hide())}>No</Button>
                <Button className="bg-delete" variant="contained">
                    Yes
                </Button>
            </DialogActions>
        </div>
    );
}
