import React from 'react';
import { Outlet } from 'react-router-dom';

interface Props {
    role?: string;
    allow?: string[];
    all?: boolean;
    children?: any;
}

const ProtectedRoute = ({ role = '', allow = [], all = false, children }: Props) => {
    if (!allow.includes(role) && !all) return null;

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
