import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    createRewardElementProps,
    generateRewardElementProps,
    updateRewardElementProps
} from './interfaces/reward-element.interface';

const rewardElementServices = createApi({
    reducerPath: 'reward-element-service',
    baseQuery: baseQuery,
    tagTypes: ['RewardElement', 'RewardElements', 'RewardElementOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ org_id, ...props }: createRewardElementProps) => ({
                method: 'POST',
                url: `/reward-elements/${org_id}/create`,
                body: props
            }),
            invalidatesTags: [{ type: 'RewardElements' }, { type: 'RewardElementOptions' }]
        }),
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/reward-elements/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'RewardElement' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/reward-elements/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'RewardElements' }, { type: 'RewardElementOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/reward-elements/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'RewardElementOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateRewardElementProps) => ({
                method: 'PATCH',
                url: `/reward-elements/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [
                { type: 'RewardElements' },
                { type: 'RewardElementOptions' },
                { type: 'RewardElement' }
            ]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/reward-elements/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [
                { type: 'RewardElements' },
                { type: 'RewardElementOptions' },
                { type: 'RewardElement' }
            ]
        }),
        generate: builder.mutation({
            query: ({ org_id, ...props }: generateRewardElementProps) => ({
                method: 'POST',
                url: `/reward-elements/${org_id}/generate`,
                body: props
            })
        })
    })
});

export default rewardElementServices;
