import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { SelectProps as MuiSelectProps } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { v4 as uuid } from 'uuid';
import ReactSelect from 'react-select';
import { isErrored } from 'stream';

interface OptionProps {
    label: string;
    value: any;
}

export interface SelectProps extends MuiSelectProps {
    data: OptionProps[] | undefined;
    value?: any;
    placeholder?: any;
    helperText?: string;
    onSelect?: any;
    error?: boolean;
    shrink?: boolean | undefined;
    loading?: boolean;
    required?: boolean;
    search?: boolean;
    updateValue?: boolean;
    formControlProps?: FormControlProps;
}

export default function SearchSelect({
    data,
    label,
    id,
    className,
    placeholder = '',
    value,
    children,
    onSelect,
    error,
    helperText,
    formControlProps,
    loading = false,
    required = false,
    updateValue = true,
    search = true,
    shrink = undefined,
    ...props
}: SelectProps) {
    const [Id] = React.useState(id ?? uuid());
    const [selected, setSelected]: any = React.useState(value || '');

    React.useEffect(() => {
        if (value !== null || value !== undefined) {
            const record = data?.find((i: any) => i.value === value);
            if (record) setSelected(record);
        }
    }, [data, value]);

    const handleChange = (data: any) => {
        if (updateValue && selected !== data) setSelected(data);
        if (onSelect && selected !== data) onSelect(data, data?.value);
    };

    return (
        <FormControl
            data-error={error}
            error={error}
            required={required}
            size="small"
            className={`search-select ${className ? className : ''}`}
            {...formControlProps}>
            <InputLabel
                className={`search-select-label ${shrink && 'shrink'}`}
                shrink={shrink}
                id={Id}>
                {label}
            </InputLabel>
            <ReactSelect
                menuIsOpen={props.readOnly ? false : undefined}
                value={selected}
                className="react-select-container"
                classNamePrefix="react-select"
                options={data}
                onChange={handleChange}
                isSearchable={search}
                isLoading={loading}
                isDisabled={loading || props.disabled}
                placeholder={placeholder}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
