import React from 'react';
import Button from '../../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../../redux/reducers/modal';
import { modalTypes } from '../../..';
import { notifyActions } from '../../../../redux/reducers/notify';
import activityServices from '../../../../services/activity.services';
import { useNavigate } from 'react-router-dom';

export default function CompleteActivity() {
    const dispatch = useDispatch();
    const navigator = useNavigate();

    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.COMPLETE_ACTIVITY];

    /* mutation */
    const [
        update,
        {
            error: activityUpdateError,
            isError: isActivityUpdateError,
            isLoading: isActivityUpdating
        }
    ]: any = activityServices.useUpdateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isActivityUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityUpdateError.data?.message
                })
            );
        }
    }, [activityUpdateError, isActivityUpdateError]);

    const onSubmit = async () => {
        const result: any = await update({
            id: props?.id,
            state: 'COMPLETED',
            org_id: authUser.organization_id
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());

            navigator('/activities', { state: null });
        }
    };

    return (
        <div className="modal-container">
            <div className="modal-content">
                <p>
                    <b>
                        Once the activity is marked as complete you cannot change or edit anything
                        within the activity.
                    </b>
                </p>
                Are you sure you want mark this activity as completed?
            </div>

            <DialogActions className="modal-buttons">
                <Button disabled={isActivityUpdating} onClick={() => dispatch(modalActions.hide())}>
                    No
                </Button>
                <Button
                    onClick={onSubmit}
                    loading={isActivityUpdating}
                    className="bg-delete"
                    variant="contained">
                    Yes
                </Button>
            </DialogActions>
        </div>
    );
}
