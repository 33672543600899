interface Types {
    SUPER_ADMIN: string;
    ORG_ADMIN: string;
    ORG_USER: string;
    [key: string]: any;
}

interface States {
    INVITED: string;
    ACTIVE: string;
    DISABLED: string;
    [key: string]: any;
}

const userUtils = {
    types: <Types>{
        SUPER_ADMIN: 'Super Admin',
        ORG_ADMIN: 'Admin',
        ORG_USER: 'User'
    },
    states: <States>{
        INVITED: 'Invited',
        ACTIVE: 'Active',
        DISABLED: 'Disabled'
    }
};

export default userUtils;
