import { Skeleton, TableCell } from '@mui/material';
import React from 'react';

export default function TableRowLoading({ checkBoxSelection, headers }) {
    return (
        <React.Fragment>
            {checkBoxSelection && (
                <TableCell className="icon-button-cell" align="center" padding="checkbox">
                    <div className="table-checkbox-wrap">
                        <Skeleton width={20} height={20}></Skeleton>
                    </div>
                </TableCell>
            )}
            {headers.map(({ props }: any, i: number) => {
                return (
                    <TableCell key={i}>
                        {props?.className?.includes('icon-button-cell') ? (
                            <div className="table-icon-wrap">
                                <Skeleton variant="circular" width={20} height={20}></Skeleton>
                            </div>
                        ) : (
                            <Skeleton height={20}></Skeleton>
                        )}
                    </TableCell>
                );
            })}
        </React.Fragment>
    );
}
