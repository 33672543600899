import React from 'react';
import TextField from '../../../../components/custom/text-field';
import TextSettings from '../../../components/text-settings';
import Common from './common';

interface Props {
    canvas?: any;
}

export default function Text({ canvas }: Props) {
    const [data, setData] = React.useState({
        opacity: 100,
        fill: '#000000',
        textAlign: 'left',
        text: ''
    });

    React.useEffect(() => {
        if (canvas) {
            const objects = canvas.getActiveObjects();
            let temp: any = {};

            /* get common data from all objects */
            for (const obj of objects) {
                obj['image'] = obj.src ? obj.getSrc() : '';

                for (const key in obj) {
                    if (Object.hasOwnProperty.call(obj, key)) {
                        const element = obj[key];

                        if (temp[key] && temp[key] !== element) {
                            delete temp[key];
                        }

                        if (!temp[key]) {
                            temp[key] = element;
                        }
                    }
                }
            }

            setData(temp);
        }
    }, [canvas]);

    const update = ({ type, value }: { type: string; value: any }) => {
        const objects = canvas.getActiveObjects();

        for (const obj of objects) {
            if (type === 'image') {
                obj.setSrc(value, () => {
                    canvas.requestRenderAll();
                });
            } else {
                obj.set({ [type]: value });
                setData({ ...data, [type]: value });
                canvas.requestRenderAll();
            }
        }
    };

    return (
        <div className="editor-settings">
            <Common canvas={canvas} />

            <TextField
                multiline
                minRows={4}
                label="Text"
                placeholder="Text"
                value={data?.text}
                onChange={(e) => update({ type: 'text', value: e.target.value })}
            />

            <TextSettings data={data} setData={(data: any) => update(data)} />
        </div>
    );
}
