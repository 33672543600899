import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import Checkbox from '../custom/checkbox';

interface Props {
    checkBoxSelection?: boolean;
    selectedCount?: number;
    rowsCount?: number;
    onSelectAll?: any;
    headers: any[];
}

export default function TableHead({
    checkBoxSelection,
    selectedCount = 0,
    rowsCount = 0,
    onSelectAll,
    headers = []
}: Props) {
    const typeCheck = (value: any) => {
        return typeof value === 'string' || typeof value === 'number';
    };

    return (
        <TableRow className="head">
            {checkBoxSelection && (
                <TableCell className="icon-button-cell" align="center" padding="checkbox">
                    <div className="table-checkbox-wrap">
                        <Checkbox
                            color="primary"
                            indeterminate={selectedCount > 0 && selectedCount < rowsCount}
                            checked={rowsCount > 0 && selectedCount === rowsCount}
                            onChange={onSelectAll}
                            inputProps={{
                                'aria-label': 'select all desserts'
                            }}
                        />
                    </div>
                </TableCell>
            )}
            {headers.map(({ render: Render, props = {} }: any, i: number) => {
                if (!props.align) props.align = 'left';

                return (
                    <TableCell key={i} {...props}>
                        {typeCheck(Render) ? <span className="text">{Render}</span> : <Render />}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}
