import { DialogActions } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Button from '../../../components/custom/button';
import Form from '../../../components/form';
import SubmitButton from '../../../components/form/submit-button';
import TextField from '../../../components/form/text-field';
import { modalActions } from '../../../redux/reducers/modal';
import orgServices from '../../../services/org.services';
import { notifyActions } from '../../../redux/reducers/notify';

const initialValues = {
    password: '',
    key: ''
};

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    key: Yup.string().required('Key is required')
});

export default function SavePrivateKey() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);

    const [
        update,
        { isLoading: isOrgUpdating, error: orgUpdateError, isError: isOrgUpdateError }
    ]: any = orgServices.useUpdateKeyMutation();

    /* error handling */
    React.useEffect(() => {
        if (isOrgUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: orgUpdateError.data?.message
                })
            );
        }
    }, [orgUpdateError, isOrgUpdateError]);

    const onSubmit = async (values: any) => {
        const result = await update({
            org_id: authUser.organization_id,
            password: values.password,
            private_key: values.key
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <TextField
                        type="password"
                        autoComplete="off"
                        name="password"
                        placeholder="Password"
                        label="Enter your password"
                        required
                    />
                    <TextField
                        type="password"
                        autoComplete="off"
                        name="key"
                        placeholder="Key"
                        label="Enter your organization private key"
                        required
                    />
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isOrgUpdating} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isOrgUpdating} variant="contained">
                        Save
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
