import React, { Suspense } from 'react';
import Icon from '../../../components/icon';
import { Environment, OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { useDispatch, useSelector } from 'react-redux';
import { editorOptionsActions } from '../../../redux/reducers/editor/options';
import rewardElementServices from '../../../services/reward-element.services';
import { notifyActions } from '../../../redux/reducers/notify';
import { elementActions } from '../../../redux/reducers/element';
import editorUtils from '../../../utils/editor';

export function Camera({ settings, cameraRef }) {
    const {
        camera,
        gl: { domElement }
    } = useThree();

    React.useEffect(() => {
        camera.position.set(
            settings?.cameraPositionX,
            settings?.cameraPositionY,
            settings?.cameraPositionZ
        );
        cameraRef.current = camera;
    }, [settings, camera]);

    return <OrbitControls ref={cameraRef} target={[0, 0, 0]} args={[camera, domElement]} />;
}

export default function Workspace({ model, setModel, cameraRef }) {
    const dispatch = useDispatch();
    const { element: element_id } = useSelector((state: any) => state.editor);
    const { user: authUser } = useSelector((state: any) => state.user);
    const { settings } = useSelector((state: any) => state.editor_options);
    const data = settings?.general;

    const [scene, setScene]: any = React.useState(null);
    const [cameraPosition, setCameraPosition]: any = React.useState({
        cameraPositionX: 0,
        cameraPositionY: 5,
        cameraPositionZ: 30
    });

    /* query */
    const {
        data: element,
        error: elementError,
        isError: isElementError,
        isLoading: isElementLoading
    }: any = rewardElementServices.useGetQuery(
        {
            org_id: authUser?.organization_id,
            type: 'id',
            value: element_id
        },
        {
            skip: !element_id || !authUser?.organization_id
        }
    );

    /* error handling */
    React.useEffect(() => {
        if (isElementError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementError.data?.message
                })
            );
        }
    }, [elementError, isElementError]);

    /* load */
    React.useEffect(() => {
        let settings = {
            general: {
                name: element?.data?.name || 'Element',
                description: element?.data?.description || '',
                opacity: 1,
                scale: 5,
                positionX: 0,
                positionY: 0,
                positionZ: 0,
                cameraPositionX: 0,
                cameraPositionY: 5,
                cameraPositionZ: 30,
                rotationX: 0,
                rotationY: 0,
                rotationZ: 0,
                background: '#ffffff'
            }
        };

        if (model) {
            getModelScene(model);
        }

        if (element) {
            const element_data = {
                name: 'Element',
                type: '',
                description: '',
                attributes: [],
                other_attributes: {}
            };

            if (element?.data?.name) element_data.name = element?.data?.name;
            if (element?.data?.type) element_data.type = element?.data?.type;
            if (element?.data?.description) element_data.description = element?.data?.description;
            if (element?.data?.custom_attributes)
                element_data.attributes = element?.data?.custom_attributes;
            if (element?.data?.other_attributes)
                element_data.other_attributes = element?.data?.other_attributes;

            /* update element state */
            dispatch(elementActions.set(element_data));

            /* update element details */
            settings = {
                general: {
                    name: element?.data?.name || 'Element',
                    description: element?.data?.description || '',
                    opacity: element?.data?.reward?.opacity || 1,
                    scale: element?.data?.reward?.scale || 5,
                    positionX: element?.data?.reward?.positionX || 0,
                    positionY: element?.data?.reward?.positionY || 0,
                    positionZ: element?.data?.reward?.positionZ || 0,
                    cameraPositionX: element?.data?.reward?.cameraPositionX || 0,
                    cameraPositionY: element?.data?.reward?.cameraPositionY || 5,
                    cameraPositionZ: element?.data?.reward?.cameraPositionZ || 30,
                    rotationX: element?.data?.reward?.rotationX || 0,
                    rotationY: element?.data?.reward?.rotationY || 0,
                    rotationZ: element?.data?.reward?.rotationZ || 0,
                    background: element?.data?.reward?.background || '#ffffff'
                }
            };

            if (element?.data?.reward?.model) setModel(element.data.reward.model);
        }

        setCameraPosition({
            cameraPositionX: settings?.general?.cameraPositionX,
            cameraPositionY: settings?.general?.cameraPositionY,
            cameraPositionZ: settings?.general?.cameraPositionZ
        });

        dispatch(
            editorOptionsActions.set({
                selected: 'general',
                settings: settings
            })
        );
    }, [cameraRef, element, model]);

    const onDrop = (e) => {
        e.preventDefault();

        if (e.dataTransfer.items) {
            const item = e.dataTransfer.items[0];

            if (item.kind === 'file') {
                const file = item.getAsFile();
                onFile(file);
            }
        } else {
            onFile(e.dataTransfer.files[0]);
        }
    };

    const onUpload = (e) => {
        onFile(e.target.files[0]);
    };

    const onFile = (file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            if (reader.result) {
                setModel(reader.result);
            }
        };
        reader.onerror = function (error) {
            console.log('Model loading error: ', error);
        };
    };

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const getModelScene = (m) => {
        editorUtils.toGltf(m, (gltf) => {
            setScene(gltf.scene);
        });
    };

    return (
        <>
            {!model ? (
                <label className="drop-zone" onDrop={onDrop} onDragOver={onDragOver}>
                    <div className="message">
                        <Icon className="icon">upload_file</Icon>

                        <p className="text">Drag and drop a 3d model file here (.glb file)</p>
                    </div>
                    <input type="file" hidden accept=".glb" onChange={onUpload} />
                </label>
            ) : (
                <div className="threejs-model">
                    <Canvas>
                        <ambientLight intensity={0.3} />

                        <Suspense fallback={null}>
                            {scene && (
                                <primitive
                                    object={scene}
                                    scale={data?.scale || 5}
                                    position={[data?.positionX, data?.positionY, data?.positionZ]}
                                    rotation={[data?.rotationX, data?.rotationY, data?.rotationZ]}
                                    center
                                />
                            )}

                            <Environment files="/models/hdr.hdr" />
                        </Suspense>

                        <Camera settings={cameraPosition} cameraRef={cameraRef} />

                        <axesHelper args={[30]} />
                        <gridHelper scale={30} />
                    </Canvas>
                </div>
            )}
        </>
    );
}
