import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    createActivityBatchProps,
    updateActivityBatchProps,
    removeActivityBatchUserProps,
    getActivityBatchProps,
    getUserBatchesProps
} from './interfaces/activity-batch.interface';

const activityBatchServices = createApi({
    reducerPath: 'activity-batch-service',
    baseQuery: baseQuery,
    tagTypes: ['ActivityBatch', 'ActivityBatches'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ org_id, ...props }: createActivityBatchProps) => ({
                method: 'POST',
                url: `/activity-batches/${org_id}/create`,
                body: props
            }),
            invalidatesTags: [{ type: 'ActivityBatches' }]
        }),
        getUserBatches: builder.query({
            query: ({ org_id, ...props }: getUserBatchesProps) => ({
                method: 'GET',
                url: `/activity-batches/${org_id}/user/batches`,
                params: props
            }),
            providesTags: [{ type: 'ActivityBatches' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateActivityBatchProps) => ({
                method: 'PUT',
                url: `/activity-batches/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'ActivityBatches' }, { type: 'ActivityBatch' }]
        }),
        getBatches: builder.query({
            query: ({ org_id, activity_id }: getActivityBatchProps) => ({
                method: 'GET',
                url: `/activity-batches/${org_id}/batches/${activity_id}`
            }),
            providesTags: [{ type: 'ActivityBatches' }, { type: 'ActivityBatch' }]
        }),
        removeUser: builder.mutation({
            query: ({ org_id, ...props }: removeActivityBatchUserProps) => ({
                method: 'DELETE',
                url: `/activity-batches/${org_id}/delete/user`,
                body: props
            }),
            invalidatesTags: [{ type: 'ActivityBatches' }, { type: 'ActivityBatch' }]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/activity-batches/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'ActivityBatches' }, { type: 'ActivityBatch' }]
        })
    })
});

export default activityBatchServices;
