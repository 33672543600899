import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';

export interface CheckboxProps extends MuiCheckboxProps {
    label?: string;
    error?: boolean;
    helperText?: string;
    formControlLabelProps?: FormControlLabelProps;
}

export default function Checkbox({
    size = 'small',
    name,
    label,
    error = false,
    helperText,
    className,
    formControlLabelProps,
    ...props
}: CheckboxProps) {
    return (
        <FormControl error={error} component="fieldset" variant="standard">
            <FormControlLabel
                className="checkbox-label"
                label={label}
                control={
                    <MuiCheckbox
                        size={size}
                        className={`checkbox ${className ? className : ''}`}
                        {...props}
                    />
                }
                {...formControlLabelProps}
            />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}
