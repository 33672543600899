import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import { addGroupProps, createGroupProps, updateGroupProps } from './interfaces/group.interface';

const groupServices = createApi({
    reducerPath: 'groups-service',
    baseQuery: baseQuery,
    tagTypes: ['Groups', 'GroupsOptions'],
    endpoints: (builder) => ({
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/groups/${org_id}/get/${type}/${value}`
            })
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/groups/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'Groups' }, { type: 'GroupsOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/groups/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'GroupsOptions' }]
        }),
        create: builder.mutation({
            query: ({ org_id, ...props }: createGroupProps) => ({
                method: 'POST',
                url: `/groups/${org_id}/create`,
                body: props
            }),
            invalidatesTags: [{ type: 'Groups' }, { type: 'GroupsOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateGroupProps) => ({
                method: 'PATCH',
                url: `/groups/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'Groups' }, { type: 'GroupsOptions' }]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/groups/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'Groups' }, { type: 'GroupsOptions' }]
        })
    })
});

export default groupServices;
