import React from 'react';
import Button from '../../../components/custom/button';
import { DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../redux/reducers/modal';
import { modalTypes } from '../..';
import { notifyActions } from '../../../redux/reducers/notify';
import activityServices from '../../../services/activity.services';
import activityTagServices from '../../../services/activity-tags.services';
import SearchSelect from '../../../components/custom/search-select';
import { activityActions } from '../../../redux/reducers/activity';

export default function SelectOtherInfoTag() {
    const dispatch = useDispatch();

    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const [selectedTag, setSelectedTag] = React.useState('');
    const props = modalProps[modalTypes.OTHER_INFO_TAG];

    /* mutation */
    const [
        update,
        {
            error: activityUpdateError,
            isError: isActivityUpdateError,
            isLoading: isActivityUpdating
        }
    ]: any = activityServices.useUpdateMutation();

    /* query */
    const {
        data: activityTags,
        error: activityTagsError,
        isError: isActivityTagsError,
        isLoading: isLoading
    }: any = activityTagServices.useGetAllQuery(
        {
            org_id: authUser.organization_id,
            page: 0,
            all: true,
            find: [{ column: 'activity_id', value: props?.activity, like: false }]
        },
        { skip: !authUser?.organization_id || !props?.activity }
    );

    /* error handling */
    React.useEffect(() => {
        if (isActivityUpdateError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityUpdateError.data?.message
                })
            );
        }
        if (isActivityTagsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: activityTagsError.data?.message
                })
            );
        }
    }, [activityUpdateError, isActivityUpdateError, activityTagsError, isActivityTagsError]);

    React.useEffect(() => {
        if (props.selected_tag) {
            let data: any = activityTags?.data?.find((i: any) =>
                props.selected_tag.includes(i.tag)
            );

            if (data?.tag) setSelectedTag(`{{ ${data.tag} }}`);
        }
    }, [activityTags, props]);

    /* data handling */
    const tagsData: any = React.useMemo(() => {
        let data: any = [];

        if (Array.isArray(activityTags?.data)) {
            data = activityTags?.data.map((i) => {
                return {
                    label: i.name,
                    value: `{{ ${i.tag} }}`
                };
            });
        }

        return data;
    }, [activityTags]);

    const onSubmit = async () => {
        const result: any = await update({
            id: props?.activity,
            org_id: authUser.organization_id,
            other_info_tag: selectedTag
        });

        if (result?.data?.status === 'success') {
            dispatch(
                activityActions.update({
                    other_info_tag: selectedTag
                })
            );

            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    const onSelectTag = (event: any, value: string) => {
        setSelectedTag(value);
    };

    return (
        <div className="modal-container select-other-info-tag-modal">
            <div className="modal-content">
                <SearchSelect
                    className="header"
                    value={selectedTag}
                    onSelect={onSelectTag}
                    data={tagsData}
                />
            </div>

            <DialogActions className="modal-buttons">
                <Button disabled={isActivityUpdating} onClick={() => dispatch(modalActions.hide())}>
                    Cancel
                </Button>
                <Button onClick={onSubmit} loading={isActivityUpdating} variant="contained">
                    Save Details
                </Button>
            </DialogActions>
        </div>
    );
}
