import shajs from 'sha.js';
import { Buffer } from 'buffer';
import Ed25519 from './ed25519';
import { Ed25519VerificationKey2018 } from '@transmute/ed25519-signature-2018';
import base58 from 'base-58';

export default {
    sign: ({ data, privateKey }: any) => {
        const ed = new Ed25519();
        const privateKeyBuffer = Buffer.from(privateKey, 'hex');

        const dataStringHash: string = new shajs.sha256().update(data).digest('hex');
        const dataStringHashBuffer = Buffer.from(dataStringHash, 'hex');

        const signature = ed.sign(dataStringHashBuffer, privateKeyBuffer).toHex();

        return signature;
    },
    verify: ({ data, signature, publicKey }: any) => {
        try {
            const ed = new Ed25519();

            const vcHash: string = new shajs.sha256().update(data).digest('hex');
            const vcHashBuffer = Buffer.from(vcHash, 'hex');

            const result = ed.verify(signature, vcHashBuffer, publicKey);

            return result;
        } catch (error) {
            return false;
        }
    },
    docFromPrivateKey: async (privateKey: string) => {
        const verificationKey: any = await getVerificationKey({ seed: privateKey });
        const publicKey = Buffer.from(base58.decode(verificationKey.publicKeyBase58)).toString(
            'hex'
        );
        const did = verificationKey.id.split('#')[0];

        return {
            privateKey,
            publicKey: publicKey,
            DID: did
        };
    }
};

async function getVerificationKey({
    seed,
    includePrivateKey = false,
    returnKey = false
}: {
    seed: string;
    includePrivateKey?: boolean;
    returnKey?: boolean;
}) {
    const key = await Ed25519VerificationKey2018.generate({
        secureRandom: () => {
            return Buffer.from(seed, 'hex');
        }
    });

    if (returnKey) return key;

    let jwk = await key.export({
        privateKey: includePrivateKey,
        type: 'Ed25519VerificationKey2018'
    });

    return jwk;
}
